import { Divider, Grid, Link, Paper } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { privacyNoticeStyles } from "./PrivacyNoticeStyles";

export default function PrivacyNotice() {
    const classes = privacyNoticeStyles();
    const baseOriginUri = window.origin;

    return (
        <Paper elevation={0} className={classes.paper}>
            <LynxTypography variant="h1">Lynx FacTOR Privacy and Data Usage Notice</LynxTypography>
            <Divider className={classes.divider} />
            <Grid container spacing={5}>
                <Grid item className={classes.justifyText}>
                    <LynxTypography>
                        Carrier Corporation, together with its controlled affiliates (
                        <LynxTypography component="span" variant="body-sbold">
                            &quot;Carrier&quot;
                        </LynxTypography>
                        ,
                        <LynxTypography component="span" variant="body-sbold">
                            &quot;we&quot;
                        </LynxTypography>
                        , or
                        <LynxTypography component="span" variant="body-sbold">
                            &nbsp;&quot;our&quot;
                        </LynxTypography>
                        ), are committed to protecting the privacy and data of Lynx FactOR Users.
                    </LynxTypography>
                    <LynxTypography className={classes.paragraphPadding}>
                        Carrier has implemented technical, administrative, and physical measures to safeguard any
                        Personal Information that we may collect. This Notice applies to the Lynx FacTOR Platform only,
                        and Carrier reserves the right to update and modify the Notice from time to time. Other Carrier
                        services, platforms, and websites will have separate privacy and/or data usage notices.
                        Modifications to this Notice will be posted online and your continued use of the Site and the
                        related Services will be deemed to constitute acceptance of the changes.
                    </LynxTypography>
                    <LynxTypography className={classes.paragraphPadding}>
                        The following terms used in this Notice have the following meanings:
                    </LynxTypography>
                    <LynxTypography>
                        <ol className={classes.lowerAlphaList}>
                            <li>
                                <LynxTypography>
                                    &quot;
                                    <LynxTypography component="span" variant="body-sbold">
                                        Apps&nbsp;
                                    </LynxTypography>
                                    &quot; means mobile applications that are part of or associated with the Platform.
                                </LynxTypography>
                            </li>
                            <li>
                                <LynxTypography>
                                    <LynxTypography component="span" variant="body-sbold">
                                        &quot;Company&quot;,
                                    </LynxTypography>
                                    <LynxTypography component="span" variant="body-sbold">
                                        &quot;You&quot;,
                                    </LynxTypography>
                                    &nbsp;or&nbsp;
                                    <LynxTypography component="span" variant="body-sbold">
                                        &quot;Your&quot;&nbsp;
                                    </LynxTypography>
                                    means or refers to a person or entity that has purchased or otherwise acquired or
                                    that is using some or all of the Platform Components, as well any employees, agents,
                                    or other persons or entities acting by, through, for, or in concert with Company.
                                </LynxTypography>
                            </li>
                            <li>
                                <LynxTypography>
                                    &quot;
                                    <LynxTypography component="span" variant="body-sbold">
                                        Company Information
                                    </LynxTypography>
                                    &quot; means all the data and information collected, received, and/or stored by
                                    Carrier during Company’s use of the Platform or any Platform Component.
                                </LynxTypography>
                            </li>
                            <li>
                                <LynxTypography>
                                    &quot;
                                    <LynxTypography component="span" variant="body-sbold">
                                        Data Privacy Laws
                                    </LynxTypography>
                                    &quot; means applicable laws and regulations relating to Personal Information
                                    protection of any country, state, or municipality with jurisdiction to regulate the
                                    activity covered by this Notice.
                                </LynxTypography>
                            </li>
                            <li>
                                <LynxTypography>
                                    &quot;
                                    <LynxTypography component="span" variant="body-sbold">
                                        Information
                                    </LynxTypography>
                                    &quot; means Personal Information as well as Company Information.
                                </LynxTypography>
                            </li>
                            <li>
                                <LynxTypography>
                                    &quot;
                                    <LynxTypography component="span" variant="body-sbold">
                                        Modified User Information
                                    </LynxTypography>
                                    &quot; means Company Information from the Platform that Company combines or connects
                                    with Personal Information that is publicly available or that Company has in its
                                    possession, including but not limited to identifiers for individuals.
                                </LynxTypography>
                            </li>
                            <li>
                                <LynxTypography>
                                    &quot;
                                    <LynxTypography component="span" variant="body-sbold">
                                        Notice
                                    </LynxTypography>
                                    &quot; means this Lynx FacTOR Privacy and Data Usage Notice.
                                </LynxTypography>
                            </li>
                            <li>
                                <LynxTypography>
                                    &quot;
                                    <LynxTypography component="span" variant="body-sbold">
                                        Personal Information
                                    </LynxTypography>
                                    &quot; means information and data exchanged in connection with this Notice that is
                                    related to any identified or identifiable natural person or, to the extent of a
                                    conflict with applicable law, which is subject to any of the Data Privacy Laws.
                                </LynxTypography>
                            </li>
                            <li>
                                <LynxTypography>
                                    &quot;
                                    <LynxTypography component="span" variant="body-sbold">
                                        Platform
                                    </LynxTypography>
                                    &quot; means the web-based software application that Company can access using
                                    individual credentials at&nbsp;
                                    <a href={baseOriginUri} target="_blank" rel="noreferrer">
                                        {baseOriginUri}
                                    </a>
                                    . The Platform is used for the evaluation of pharmaceutical product quality after a
                                    temperature excursion has occurred.
                                </LynxTypography>
                            </li>
                            <li>
                                <LynxTypography>
                                    &quot;
                                    <LynxTypography component="span" variant="body-sbold">
                                        Platform Components
                                    </LynxTypography>
                                    &quot; means any individual component or group of components of the Platform,
                                    including but not limited to the Site and the Apps.
                                </LynxTypography>
                            </li>
                            <li>
                                <LynxTypography>
                                    &quot;
                                    <LynxTypography component="span" variant="body-sbold">
                                        Service Providers
                                    </LynxTypography>
                                    &quot; means third parties that Carrier has retained to perform services on its
                                    behalf, including but not limited to services related to the operation of the
                                    Platform, as well as authorized Carrier dealers or service centers.
                                </LynxTypography>
                            </li>
                            <li>
                                <LynxTypography>
                                    &quot;
                                    <LynxTypography component="span" variant="body-sbold">
                                        Site
                                    </LynxTypography>
                                    &quot; means the Platform website or sites that Company utilizes to access its
                                    Company Information, including but not limited to the Lynx FacTOR website.
                                </LynxTypography>
                            </li>
                            <li>
                                <LynxTypography>
                                    &quot;
                                    <LynxTypography component="span" variant="body-sbold">
                                        User
                                    </LynxTypography>
                                    &quot; or&nbsp;&quot;
                                    <LynxTypography component="span" variant="body-sbold">
                                        Authorized User
                                    </LynxTypography>
                                    &quot; means Company’s employees, consultants, contractors, lessees, and agents who
                                    are authorized by Company to access and use the Platform under the rights granted
                                    pursuant to the Lynx FacTOR Terms of Use.
                                </LynxTypography>
                            </li>
                        </ol>
                    </LynxTypography>
                    <LynxTypography variant="body-sbold-italic" className={classes.paragraphPadding}>
                        What Personal Information may Carrier collect? How does Carrier collect such Information?
                    </LynxTypography>
                    <LynxTypography>
                        To use the Platform, You must create an account and register one or more Users. To create an
                        account, Carrier will require provision of the following Personal Information.
                    </LynxTypography>
                    <LynxTypography>
                        <ol className={classes.circleList}>
                            <li>First name</li>
                            <li>Last name</li>
                            <li>Primary email address</li>
                            <li>Password</li>
                            <li>Primary telephone number</li>
                        </ol>
                    </LynxTypography>
                    <LynxTypography className={classes.paragraphPadding}>
                        Carrier may also collect Information through your use of the Platform, such as by the use of
                        cookies, IP address, log files, and location data. Please see the &nbsp;
                        <Link href="https://www.carrier.com/carrier/en/worldwide/legal/cookie-policy/" target="_blank">
                            Carrier Cookies Policy
                        </Link>
                        &nbsp; for more information.
                    </LynxTypography>
                    <LynxTypography variant="body-sbold-italic" className={classes.paragraphPadding}>
                        Why does Carrier collect your Information?
                    </LynxTypography>
                    <LynxTypography>
                        Except where used to fulfill a legal obligation, our use of Your Personal Information will be
                        only for legitimate business interests as set out below. Personal Information collected may be
                        used to/for:
                    </LynxTypography>
                    <LynxTypography>
                        <ol className={classes.discList}>
                            <li>
                                Provide requested information, products, items, support, or services and related
                                information;
                            </li>
                            <li>Improve the Platform and other products and services;</li>
                            <li>
                                Advertise and communicate with You about products, services, promotions, and events
                                relating to Carrier;
                            </li>
                            <li>
                                Develop new offerings, and improve the quality, performance, operation, reliability,
                                maintainability, safety, and security of existing products, services, websites and
                                mobile applications (including the Platform, Site, and Apps);
                            </li>
                            <li>Operate the Platform, Site, and Apps;</li>
                            <li>
                                Create, compile, and/or use datasets and/or statistics for the purposes of benchmarking,
                                development of best practices, and product improvement;
                            </li>
                            <li>Research, evaluation of use, and troubleshooting purposes;</li>
                            <li>Verify Your identity to ensure security for one of the other purposes listed;</li>
                            <li>Protect against fraud or investigate suspected or actual illegal activity;</li>
                            <li>
                                Conduct investigations to ensure compliance with, and comply with, legal and contractual
                                obligations;
                            </li>
                            <li>Screen against sanctions and anti-terrorism lists as required by law;</li>
                            <li>Invoice; and</li>
                            <li>
                                Respond to a legitimate legal request from law enforcement authorities or other
                                government regulators.
                            </li>
                        </ol>
                    </LynxTypography>
                    <LynxTypography variant="body-sbold-italic" className={classes.paragraphPadding}>
                        Where is Information stored?
                    </LynxTypography>
                    <LynxTypography>
                        The Information will be stored by the Platform or other Carrier servers or by Service Providers
                        with whom Carrier works to transmit, process, or store the Information.
                    </LynxTypography>
                    <LynxTypography className={classes.paragraphPadding}>
                        Because Carrier is a global company with locations in many different countries, we may transfer
                        Your Information from one legal entity to another or from one country to another within the
                        Carrier group of companies in order to accomplish the purposes listed above. These countries
                        include, at a minimum, the United States, the member states of the European Union, the United
                        Kingdom, Canada, and other countries, including some in Asia. We will transfer Your Personal
                        Information consistent with applicable legal requirements and only to the extent necessary for
                        the purposes set out above.
                    </LynxTypography>
                    <LynxTypography className={classes.paragraphPadding}>
                        Carrier relies on available legal mechanisms to enable the legal transfer of Personal
                        Information across borders. To the extent that Carrier relies on the standard contractual
                        clauses (also called the model clauses) or binding corporate rules to authorize transfer,
                        Carrier will comply with those requirements, including where there may be a conflict between
                        those requirements and this Notice.
                    </LynxTypography>
                    <LynxTypography variant="body-sbold-italic" className={classes.paragraphPadding}>
                        Does Carrier share the Information it collects?
                    </LynxTypography>
                    <LynxTypography>
                        Carrier may share Your Information with our affiliated companies, such as companies in Carrier’s
                        group of companies, and/or companies in which Carrier has control through either direct or
                        indirect ownership, for the purposes set out above.
                    </LynxTypography>
                    <LynxTypography className={classes.paragraphPadding}>
                        Carrier will not sell or otherwise transfer or share Your Information outside of the companies
                        identified above, except to:
                        <ol className={classes.discList}>
                            <li>
                                Service Providers - Carrier will only share Your Personal Information with Service
                                Providers whom Carrier has contractually restricted from using or disclosing that
                                Personal Information except as necessary to perform services on our behalf or to comply
                                with legal requirements;
                            </li>
                            <li>
                                Comply with legal obligations, such as in response to a legitimate legal request from
                                law enforcement authorities, courts, or other government regulators or authorities,
                                among other things;
                            </li>
                            <li>Investigate suspected or actual illegal activity;</li>
                            <li>Prevent physical harm or financial loss; or</li>
                            <li>
                                Support the sale or transfer of all or a portion of our business or assets (including
                                through bankruptcy).
                            </li>
                        </ol>
                    </LynxTypography>
                    <LynxTypography variant="body-sbold-italic" className={classes.paragraphPadding}>
                        How can You correct, change, or delete Your Personal information held by Carrier?
                    </LynxTypography>
                    <LynxTypography>
                        You may request to access, update, correct, change, or delete Your Personal Information at any
                        time. Carrier will use reasonable efforts to update and/or remove Personal Information in a
                        timely manner. To protect Users’ privacy and security, Carrier will take steps to verify a
                        User’s identity before making any requested change. To access, change, or delete Your Personal
                        Information, please send an email to&nbsp;
                        <Link href="mailto:privacy@carrier.com">privacy@carrier.com</Link>. Some portions of the Site or
                        Apps may allow You to make changes directly on the Site or App without need to further contact
                        Carrier.
                    </LynxTypography>
                    <LynxTypography className={classes.paragraphPadding}>
                        Please note that while we will assist You in protecting Your Personal Information, it is Your
                        responsibility to protect Your passwords and other Platform (including the Site) access
                        credentials from others.
                    </LynxTypography>
                    <LynxTypography variant="body-sbold-italic" className={classes.paragraphPadding}>
                        How does Carrier secure Personal Information?
                    </LynxTypography>
                    <LynxTypography>
                        Carrier is committed to ensuring the security and integrity of Your Information. Carrier has
                        adopted reasonable physical, electronic, and managerial procedures to safeguard Your
                        Information. However, due to the nature of Internet and mobile communications, Carrier cannot
                        guarantee or warrant that Your transmissions to or from us are secure.
                    </LynxTypography>
                    <LynxTypography variant="body-sbold-italic" className={classes.paragraphPadding}>
                        How long does Carrier keep Your Personal Information?
                    </LynxTypography>
                    <LynxTypography>
                        The Personal Information You provide to Carrier is only kept for as long as it is reasonably
                        necessary for the purposes for which it was collected, taking into account Carrier’s need to
                        comply with legal and contractual obligations, resolve customer service issues, and provide new
                        or improved services to Users. This means that we may retain Your Personal Information after You
                        have stopped using the Platform.
                    </LynxTypography>
                    <LynxTypography variant="body-sbold-italic" className={classes.paragraphPadding}>
                        What additional information should specific Site users know?
                    </LynxTypography>
                    <LynxTypography className={classes.paragraphPadding}>
                        <LynxTypography component="span" variant="body-sbold">
                            Parents, Guardians, and Children:
                        </LynxTypography>
                        &nbsp; The Site and Apps are intended for visitors who are at least 18 years of age, or the age
                        of majority in their jurisdiction of residence. Carrier does not knowingly solicit Information
                        from, or market products or services to, children. If You do not meet the age requirements set
                        out above, please do not enter Your Personal Information on the Site or Apps.
                    </LynxTypography>
                    <LynxTypography component="span" variant="body-sbold" className={classes.paragraphPadding}>
                        Users from California:
                    </LynxTypography>
                    <LynxTypography>
                        If You are a resident of California, effective January 1, 2020, You have certain rights under
                        the California Consumer Privacy Act of 2018 (&quot;
                        <LynxTypography component="span" variant="body-sbold">
                            CCPA
                        </LynxTypography>
                        &quot;), including the right to:
                    </LynxTypography>
                    <LynxTypography>
                        <ol className={classes.lowerAlphaList}>
                            <li>
                                know what categories of personal information about You we have collected and used,
                                including how the personal information was collected and the purpose for the collection
                                and use;
                            </li>
                            <li>
                                know what categories of personal information are being sold or disclosed to third
                                parties and, if sold or disclosed, the categories of third parties receiving it;
                            </li>
                            <li>access specific personal information collected about You; and</li>
                            <li>request that Your personal information be deleted.</li>
                        </ol>
                    </LynxTypography>
                    <LynxTypography className={classes.paragraphPadding}>
                        Requests to exercise rights under the CCPA are subject to verification by Carrier and are also
                        subject to certain restrictions provided by the CCPA. We will not discriminate against
                        California residents for exercising any of their applicable CCPA rights. While California
                        residents have the right to opt out of the sale of their personal information under the CCPA,
                        Carrier does not sell personal information to third parties. If our practices change, we will
                        update this Notice and take any necessary action to comply with applicable law.
                    </LynxTypography>
                    <LynxTypography className={classes.paragraphPadding}>
                        California residents who wish to exercise their CCPA rights can contact Carrier at&nbsp;
                        <Link href="mailto:privacy@carrier.com">privacy@carrier.com</Link>. You can also contact us at
                        1-833-617-0050. When contacting us to exercise Your rights, please put &quot;California Privacy
                        Rights&quot; in the subject and body of Your request and describe the nature of your request.
                    </LynxTypography>
                    <LynxTypography className={classes.paragraphPadding}>
                        Annually, California residents may request and obtain information that Carrier shared with other
                        businesses for their own direct marketing use within the prior calendar year (as defined by
                        California’s &quot;Shine the Light&quot; Law). If applicable, this information would include a
                        list of the categories of personal information that was shared and the names and addresses of
                        all third parties with which Carrier shared this information in the immediately preceding
                        calendar year. To obtain this information, please send an email message to&nbsp;
                        <Link href="mailto:privacy@carrier.com">privacy@carrier.com</Link> with &quot;California Shine
                        the Light Privacy Request&quot; in the subject line as well as in the body of Your message.
                    </LynxTypography>
                    <LynxTypography className={classes.paragraphPadding}>
                        <LynxTypography component="span" variant="body-sbold">
                            Companies and Users from the EU and other countries with Data Privacy Laws:
                        </LynxTypography>
                        &nbsp; You have the right to lodge a complaint with Your national or state data protection
                        authority, which may also be known as a supervisory authority. You also have the right to: (i)
                        request access to and correction or - under certain circumstances - erasure of Your Personal
                        Information; (ii) seek restrictions on or object to the processing of certain Personal
                        Information; and (iii) seek data portability under certain circumstances. To contact Carrier
                        about a request to access, correct, erase, or object to Your Personal Information, or to seek
                        restrictions or portability, please use the contact methods indicated at the end of this Notice.
                    </LynxTypography>
                    <LynxTypography variant="body-sbold-italic" className={classes.paragraphPadding}>
                        How can You contact Carrier?
                    </LynxTypography>
                    <LynxTypography>
                        If You have any comments or questions about this Notice or the Platform, wish to obtain
                        additional information on the manner in which we or our Service Providers treat Your
                        Information, or wish to execute Your rights related to Your Personal Information, please send an
                        email to <Link href="mailto:privacy@carrier.com">privacy@carrier.com</Link>.
                    </LynxTypography>
                </Grid>
                <Grid item>
                    <LynxTypography className={classes.paragraphPadding}>
                        Updated by Carrier, August 2024.
                    </LynxTypography>
                    <LynxTypography className={classes.paragraphPadding}>
                        Copyright © Carrier Corporation 2024. All rights reserved.
                    </LynxTypography>
                </Grid>
            </Grid>
        </Paper>
    );
}
