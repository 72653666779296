import { LynxCheckBox } from "components/LynxComponents/LynxCheckBox/LynxCheckBox";
import { LynxCheckBoxProps } from "components/LynxComponents/LynxCheckBox/LynxCheckBoxProps";
import { useField } from "formik";

interface LynxCheckBoxFormProps extends Omit<LynxCheckBoxProps, "name" | "value"> {
    name: string;
}

const LynxCheckBoxForm = (props: LynxCheckBoxFormProps) => {
    const [field] = useField({ name: props.name, type: "checkbox" });
    const { checked, name, onChange, onBlur, ...rest } = { ...props };
    const { checked: formikChecked, name: formikName, onChange: formikOnChange, onBlur: formikOnBlur } = { ...field };

    return (
        <LynxCheckBox
            checked={checked ?? formikChecked}
            name={formikName}
            onChange={onChange ?? formikOnChange}
            onBlur={onBlur ?? formikOnBlur}
            {...rest}
        />
    );
};

export default LynxCheckBoxForm;
