import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const statusBoxStyles = makeStyles((theme: Theme) =>
    createStyles({
        statusPadding: {
            padding: "0.5rem 1rem",
            borderRadius: "0.25rem",
        },
        event_new: {
            color: theme.colors.dataVisualization.sky,
            backgroundColor: theme.colors.dataVisualization.sky100,
        },
        event_pendingReview: {
            color: theme.colors.warning.warning600,
            backgroundColor: theme.colors.warning.warning100,
        },
        event_pendingApproval: {
            color: theme.colors.dataVisualization.plum,
            backgroundColor: theme.colors.dataVisualization.plum100,
        },
        event_completed: {
            color: theme.colors.success.success600,
            backgroundColor: theme.colors.success.success100,
        },
        sf_draft: {
            color: theme.colors.dataVisualization.sky,
            backgroundColor: theme.colors.dataVisualization.sky100,
        },
        sf_pendingApproval: {
            color: theme.colors.warning.warning600,
            backgroundColor: theme.colors.warning.warning100,
        },
        sf_effective: {
            color: theme.colors.success.success600,
            backgroundColor: theme.colors.success.success100,
        },
        sf_deactivated: {
            color: theme.colors.critical.critical600,
            backgroundColor: theme.colors.critical.critical100,
        },
        statusBox: {
            whiteSpace: "nowrap",
            fontSize: "0.875rem",
            lineHeight: "1rem",
            fontFamily: "Gibson-Semibold",
            textTransform: "uppercase",
            letterSpacing: "0.07rem",
            display: "inline-block",
        },
    })
);
