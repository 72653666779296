import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const eventReviewStyles = makeStyles((theme: Theme) =>
    createStyles({
        radioGroup: {
            display: "inline-block",
            marginTop: "0.2rem",
            marginLeft: "-0.4rem",
        },

        customTooltip: {
            backgroundColor: `${theme.colors.neutrals.neutral600}`,
            color: theme.palette.common.white,
        },

        customArrow: {
            "&:before": {
                backgroundColor: `${theme.colors.neutrals.neutral600}`,
            },
        },
    })
);
