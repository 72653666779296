import { Grid, Paper } from "@material-ui/core";
import {
    Autocomplete,
    AutocompleteGetTagProps,
    AutocompleteInputChangeReason,
    AutocompleteRenderOptionState,
    createFilterOptions,
    Value,
} from "@material-ui/lab";
import clsx from "clsx";
import { SearchLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/SearchLoadingIndicator";
import useDebounce from "hooks/useDebounce";
import { LynxIcon } from "icons/LynxIcon";
import { ChangeEvent, useEffect, useState } from "react";
import { LynxButton } from "../LynxButton/LynxButton";
import { LynxCheckBox } from "../LynxCheckBox/LynxCheckBox";
import { LynxInput } from "../LynxInput/LynxInput";
import LynxTypography from "../LynxTypography/LynxTypography";
import { LynxSelectWithSearchProps } from "./LynxSelectWithSearchProps";
import { lynxSelectWithSearchStyles } from "./LynxSelectWithSearchStyles";

export const LynxSelectWithSearch = <
    T,
    Multiple extends boolean,
    DisableClearable extends boolean,
    FreeSolo extends boolean
>(
    props: LynxSelectWithSearchProps<T, Multiple, DisableClearable, FreeSolo>
) => {
    const {
        loading,
        search,
        clearFunc,
        isSearchWithAutocomplete,
        disablePopupIconInteractions,
        label,
        placeholder,
        getOptionId,
        inputProps,
        className,
        clearInputOnSelect,
        triggerSearchOnClear = false,
        classes: propsClasses,
        ...propsRest
    } = {
        ...props,
    };
    const classes = lynxSelectWithSearchStyles();
    const approximatelyMaximumSymbolsCount = 30;
    const isInputWithTags = propsRest.multiple && propsRest.withTags;

    const [searchValue, setSearchValue] = useState("");
    const debouncedValue = useDebounce<string>(searchValue, 800);

    const handleSearch = (_event: ChangeEvent<{}>, inputValue: string, reason: AutocompleteInputChangeReason) => {
        if (clearInputOnSelect && reason === "reset") {
            setSearchValue("");
            return;
        }
        // TODO: revisit, this logic may not work for all use cases
        // disable changing input value if something is selected
        if (
            props.withTags ||
            reason === "clear" ||
            reason === "reset" ||
            (reason === "input" && inputValue === "") ||
            !props.value ||
            (props.value instanceof Array && props.value.length === 0)
        ) {
            const trimmed = inputValue.trimLeft().replace(/\s{2,}/g, " ") as string;
            setSearchValue(trimmed);
        }

        if (triggerSearchOnClear && inputValue === "" && search !== undefined) {
            search("");
        }
    };

    useEffect(() => {
        // TODO: revisit, this logic may not work for all use cases
        // do not send an extra request after value is selected
        if (!props.value && !loading && debouncedValue && search !== undefined) {
            search(searchValue);
        }
    }, [debouncedValue]);

    const optionLabel = (x: T) => (props.getOptionLabel ? props.getOptionLabel(x) : x);

    // TODO: extract to a separate component, LynxSelectWithSearch should accept renderOption prop and use that
    const renderOptionMultiSelect = (option: T, state: AutocompleteRenderOptionState) => {
        return (
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <LynxTypography variant={state.selected ? "body-medium" : "body"}>
                        {optionLabel(option)}
                    </LynxTypography>
                </Grid>

                <Grid item>
                    <LynxCheckBox checked={state.selected} />
                </Grid>
            </Grid>
        );
    };

    // TODO: extract to a separate component, LynxSelectWithSearch should accept renderOption prop and use that
    const renderOptionSingleSelect = (option: T, _state: AutocompleteRenderOptionState) => {
        return (
            <>
                <LynxTypography>{optionLabel(option)}</LynxTypography>
                <LynxButton variant="tertiary">Select</LynxButton>
            </>
        );
    };

    const LoadingIndicator = ({ className, ...rest }: any) => {
        return (
            <Paper className={clsx(classes.loadingIndicatorContainer, className)} {...rest}>
                <SearchLoadingIndicator />
            </Paper>
        );
    };

    const renderOption = (option: T, state: AutocompleteRenderOptionState) => {
        switch (true) {
            case props.renderOption !== undefined:
                return props.renderOption!(option, state);
            case propsRest.multiple:
                return renderOptionMultiSelect(option, state);
            default:
                return renderOptionSingleSelect(option, state);
        }
    };

    // custom method for getLimitTagsText={(optionsCount:number) => `Selected (${optionsCount}`}
    const renderTagsForInput = (options: T[], _getTagProps: AutocompleteGetTagProps) => {
        const displayValues = options.map((x) => optionLabel(x)).join(", ");

        return (
            <div>
                {props.withTags
                    ? ""
                    : displayValues.length <= approximatelyMaximumSymbolsCount
                    ? displayValues
                    : `Selected (${options.length})`}
            </div>
        );
    };

    // TODO: extract to a separate component
    const renderChips = () => {
        const valueCasted = props.value as T[];

        return (
            <Grid container alignItems="center">
                {valueCasted.map((x) => {
                    return (
                        <Grid item className={classes.chips} key={getOptionId!(x)}>
                            <LynxTypography variant={"body-medium"} className={classes.chipsDisplayName}>
                                {optionLabel(x)}
                                <LynxButton
                                    variant="icon"
                                    className={classes.removeIcon}
                                    disabled={loading || props.disabled}
                                    onClick={(e) =>
                                        props.onChange(
                                            e,
                                            valueCasted.filter((y) => getOptionId!(y) !== getOptionId!(x)) as Value<
                                                T,
                                                Multiple,
                                                DisableClearable,
                                                FreeSolo
                                            >,
                                            "remove-option"
                                        )
                                    }
                                >
                                    <LynxIcon name="crossSmall" />
                                </LynxButton>
                            </LynxTypography>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    const PopupIcon = (
        <LynxIcon
            name={isSearchWithAutocomplete ? "search" : "angleSmallDown"}
            className={clsx(props.disabled && classes.iconColor)}
        />
    );
    const CloseIcon = isSearchWithAutocomplete ? (
        <LynxIcon name="crossSmall" className={classes.iconColor} />
    ) : undefined;

    return (
        <>
            <Autocomplete
                renderOption={renderOption}
                renderTags={renderTagsForInput}
                popupIcon={PopupIcon}
                filterOptions={isSearchWithAutocomplete ? (x) => x : createFilterOptions()}
                closeIcon={CloseIcon}
                classes={{
                    ...propsClasses,
                    listbox: clsx(classes.listBox, propsClasses?.listbox),
                    option: clsx(classes.option, propsClasses?.option),
                    popper: clsx(classes.popper, propsClasses?.popper),
                }}
                className={clsx(classes.autocomplete, className, {
                    [classes.preventIconTransform]: isSearchWithAutocomplete,
                    [classes.clearIcon]: isSearchWithAutocomplete,
                    [classes.displayNone]: props.freeSolo,
                    [classes.disablePopupIconInteractions]: disablePopupIconInteractions,
                    [classes.disabled]: props.disabled,
                })}
                PaperComponent={loading ? LoadingIndicator : Paper}
                inputValue={searchValue}
                onInputChange={handleSearch}
                {...propsRest}
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                        <LynxInput
                            {...inputProps}
                            assistiveText={inputProps?.assistiveText || "Error"}
                            assistiveTextClassName={clsx(
                                inputProps?.assistiveTextClassName,
                                !inputProps?.error && classes.assistiveTextHidden
                            )}
                            inputClassName={classes.input}
                            // selected values
                            startAdornment={params.InputProps?.startAdornment}
                            // popupIcon/closeIcon
                            endAdornment={params.InputProps?.endAdornment}
                            label={label}
                            placeholder={placeholder}
                            {...params}
                        />
                    </div>
                )}
            />
            {isInputWithTags && renderChips()}
        </>
    );
};
