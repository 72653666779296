import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import VerticalLine from "components/ReusableComponents/VerticalLine";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import LynxCheckBoxForm from "components/ReusableForms/LynxCheckBoxForm";
import LynxDurationInputForm from "components/ReusableForms/LynxDurationInputForm";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import { FieldArray } from "formik";
import { LynxIcon } from "icons/LynxIcon";
import { commonConstants, promptTooltipMessages } from "lynxConstants";
import { unlimited } from "lynxConstants/commonConstants";
import { observer } from "mobx-react";
import { ChangeEvent, useEffect, useState } from "react";
import { useStore } from "store/StoreConfigs";
import { formikModels, useLynxFormikContext } from "validation";
import { stabilityFormStyles } from "../StabilityFormStyles";
import { RegionSelectOption } from "./Props/RegionFieldsAreaProps";
import { TemperatureRangeFieldsAreaProps } from "./Props/TemperatureRangeFieldsAreaProps";
import RangeFieldsArea from "./RangeFieldsArea";
import RegionFieldsArea from "./RegionFieldsArea";

const getBlankRangeRegion = (): formikModels.TemperatureRangeRegionFormikModel => ({
    regionId: "",
    medicalInfo: "",
    flows: [
        {
            name: "",
            steps: "",
            plannedDeduction: "",
            remainingStabilityBudget: "",
            references: "",
            metadata: {
                id: "",
                key: crypto.randomUUID(),
                isReferenced: false,
            },
        },
    ],
    metadata: {
        id: "",
        key: crypto.randomUUID(),
    },
});

const renderDurationInput = (name: string, label: string, withUnlimitedBudget: boolean) =>
    withUnlimitedBudget ? (
        <LynxInputForm
            name={name}
            label={label}
            disabled
            endAdornment={<LynxIcon name="duration" />}
            placeholder={unlimited}
        />
    ) : (
        <LynxDurationInputForm name={name} label={label} />
    );

const TemperatureRangeFieldsArea = observer((props: TemperatureRangeFieldsAreaProps) => {
    const { rangeHelpers, rangeIndex } = { ...props };
    const formik = useLynxFormikContext<formikModels.StabilityFormFormikModel>();
    const { customerDataStore } = useStore();
    const classes = stabilityFormStyles();
    const [regions, setRegions] = useState<RegionSelectOption[]>([]);

    const range = formik.values.temperatureRanges[rangeIndex];
    const rangeRegions = range.rangeRegions;
    const atLeastOneFlowReferenced = rangeRegions.some((x) => x.flows.some((y) => y.metadata.isReferenced));
    const rangeReferenced = range.metadata.isReferenced;
    const tsbInputName = `temperatureRanges.${rangeIndex}.totalStabilityBudget`;

    const defineSelectedRegions = () => {
        const regionsWithEmptyState = [
            { id: commonConstants.allCountriesAndRegions, name: commonConstants.allCountriesAndRegions },
            ...customerDataStore.regions,
        ];

        return regionsWithEmptyState.map((x) => {
            const region = rangeRegions.find((y) => x.id === y.regionId);

            if (region) {
                return { region: x, disabled: true };
            }

            return { region: x, disabled: false };
        });
    };

    const disableSelectedRegion = (
        e: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
        previousRegionId: string
    ) => {
        const regionId = e.target.value as string;
        const oldValue = regions.find((x) => x.region.id === previousRegionId);

        const newRegionOptions = regions.map((x) => {
            if (x.region.id === regionId) {
                return { ...x, disabled: true };
            }

            if (x.region.id === oldValue?.region.id) {
                return { ...x, disabled: false };
            }

            return x;
        });

        setRegions(newRegionOptions);
    };

    const enableRegionOption = (regionId: string) => {
        const newRegionOptions = regions.map((x) => {
            if (x.region.id === regionId) {
                return { ...x, disabled: false };
            }

            return x;
        });

        setRegions(newRegionOptions);
    };

    const setTemperatureRangeValues = () => {
        const rangeRegions: formikModels.TemperatureRangeRegionFormikModel[] =
            formik.values.temperatureRanges[rangeIndex].rangeRegions.length <= 0 ? [getBlankRangeRegion()] : [];

        formik.setFieldValue(`temperatureRanges.${rangeIndex}.medicalInfo`, "");
        formik.setFieldValue(`temperatureRanges.${rangeIndex}.remainingStabilityBudget`, "");
        formik.setFieldValue(`temperatureRanges.${rangeIndex}.rangeRegions`, rangeRegions);
        setRegions(defineSelectedRegions());
    };

    const showTooltipMessageForDeleteBtn = () => {
        if (rangeReferenced) {
            return promptTooltipMessages.stabilityForm.rangeCannotBeDeletedBecauseReferenced;
        }

        if (atLeastOneFlowReferenced) {
            return promptTooltipMessages.stabilityForm.flowCannotBeDeletedBecauseReferenced;
        }

        return promptTooltipMessages.empty;
    };

    const showTooltipMessageForFlowBtn = () => {
        if (range.withUnlimitedBudget) {
            return promptTooltipMessages.stabilityForm.flowsNotAllowedForUnlimitedRange;
        }

        if (atLeastOneFlowReferenced) {
            return promptTooltipMessages.stabilityForm.flowCannotBeDeletedBecauseReferenced;
        }

        return promptTooltipMessages.empty;
    };

    useEffect(() => {
        setRegions(defineSelectedRegions());
    }, [formik.initialValues]);

    useEffect(() => {
        if (range.withUnlimitedBudget) {
            formik.setValueOrEmptyStr(tsbInputName, "");
            formik.setValueOrEmptyStr(`temperatureRanges.${rangeIndex}.medicalInfo`, "");
            formik.setValueOrEmptyStr(`temperatureRanges.${rangeIndex}.remainingStabilityBudget`, "");
            formik.setValueOrEmptyArr(`temperatureRanges.${rangeIndex}.rangeRegions`, []);
        }
    }, [range.withUnlimitedBudget]);

    return (
        <Grid container className={classes.rangeSection} item>
            <Grid container item justifyContent="space-between" className={classes.marginBottomLarge}>
                <Grid item xs={10}>
                    <div style={{ display: "flex" }}>
                        <div>
                            <LynxTypography variant="body-s" color="neutral400">
                                {<LabelWithRequiredSymbol label="Temperature Range" />}
                            </LynxTypography>
                            <RangeFieldsArea<formikModels.StabilityFormFormikModel>
                                fieldNamesPrefix={`temperatureRanges.${rangeIndex}`}
                            />
                        </div>
                        <LynxCheckBoxForm
                            name={`temperatureRanges.${rangeIndex}.withUnlimitedBudget`}
                            label="Unlimited Stability Budget"
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    xs={2}
                    justifyContent="flex-end"
                    className={clsx(classes.heightFitContent, classes.displayFlex)}
                >
                    <PromptTooltip placement="top" title={showTooltipMessageForFlowBtn()}>
                        <LynxButton
                            size="medium"
                            variant="tertiary"
                            onClick={() => setTemperatureRangeValues()}
                            disabled={atLeastOneFlowReferenced || range.withUnlimitedBudget}
                            leftIcon={<LynxIcon name={rangeRegions.length <= 0 ? "plusSmall" : "minusSmall"} />}
                        >
                            {rangeRegions.length <= 0 ? "Add" : "Remove"} Flow(s)
                        </LynxButton>
                    </PromptTooltip>
                    <VerticalLine />
                    <PromptTooltip placement="top" title={showTooltipMessageForDeleteBtn()}>
                        <LynxButton
                            size="medium"
                            variant="icon"
                            disabled={
                                formik.values.temperatureRanges.length <= 1 ||
                                atLeastOneFlowReferenced ||
                                rangeReferenced
                            }
                            onClick={() => {
                                if (formik.values.temperatureRanges.length <= 1) {
                                    return;
                                }
                                rangeHelpers.remove(rangeIndex);
                            }}
                        >
                            <LynxIcon name="trash" />
                        </LynxButton>
                    </PromptTooltip>
                </Grid>
            </Grid>
            {rangeRegions.length <= 0 ? (
                <Grid container item className={classes.inputsGapMedium}>
                    <Grid item xs={2}>
                        {renderDurationInput(tsbInputName, "Total Stability Budget", range.withUnlimitedBudget)}
                    </Grid>
                    <Grid item xs={2}>
                        {renderDurationInput(
                            `temperatureRanges.${rangeIndex}.medicalInfo`,
                            "Medical Info",
                            range.withUnlimitedBudget
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        {renderDurationInput(
                            `temperatureRanges.${rangeIndex}.remainingStabilityBudget`,
                            "Remaining Stability Budget",
                            range.withUnlimitedBudget
                        )}
                    </Grid>
                    <Grid item xs>
                        <LynxInputForm
                            multiline
                            name={`temperatureRanges.${rangeIndex}.references`}
                            placeholder="Enter References"
                            label="References"
                        />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid
                        container
                        item
                        className={clsx(
                            classes.inputsGapMedium,
                            classes.dividerBottom,
                            classes.marginBottomSmall,
                            classes.paddingBottomSmall
                        )}
                    >
                        <Grid item lg={2} md={3} sm={3}>
                            <LynxDurationInputForm name={tsbInputName} label="Total Stability Budget" />
                        </Grid>
                        <Grid item xs={6}>
                            <LynxInputForm
                                multiline
                                name={`temperatureRanges.${rangeIndex}.references`}
                                placeholder="Enter References"
                                label="References"
                            />
                        </Grid>
                    </Grid>

                    <LynxTypography
                        variant="h3"
                        color="neutral400"
                        className={clsx(classes.marginTopMedium, classes.marginBottomMedium)}
                    >
                        Countries & regions {`(${rangeRegions.length})`}
                    </LynxTypography>

                    <FieldArray
                        name={`temperatureRanges.${rangeIndex}.rangeRegions`}
                        render={(regionHelpers) => (
                            <Grid container item>
                                {rangeRegions.map((rangeRegion, regionIndex) => (
                                    <RegionFieldsArea
                                        key={rangeRegion.metadata.key}
                                        rangeIndex={rangeIndex}
                                        regionIndex={regionIndex}
                                        regionHelpers={regionHelpers}
                                        regions={regions}
                                        disableSelectedRegion={disableSelectedRegion}
                                        enableRegionOption={enableRegionOption}
                                    />
                                ))}

                                <Grid item className={classes.marginBottomMedium}>
                                    <LynxButton
                                        variant="secondary"
                                        onClick={() => {
                                            const rangeRegion: formikModels.TemperatureRangeRegionFormikModel =
                                                getBlankRangeRegion();
                                            regionHelpers.push(rangeRegion);
                                        }}
                                        leftIcon={<LynxIcon name="plusSmall" />}
                                    >
                                        Add Region
                                    </LynxButton>
                                </Grid>
                            </Grid>
                        )}
                    />
                </>
            )}
        </Grid>
    );
});

export default TemperatureRangeFieldsArea;
