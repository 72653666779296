import { GetStabilityFormChangesHistoryRequest } from "api/models/stabilityForms/stabilityFormsApi";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { LynxBackButton } from "components/ReusableComponents/LynxBackButton/LynxBackButton";
import { createPermissionRequest, getPermissionKey } from "helpers/permissionHelpers";
import { isGuidValid } from "helpers/typeValidationHelpers";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus, PermissionRequest, ResourceType } from "models/userManagement/userManagementModels";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { stabilityFormStyles } from "../StabilityFormStyles";
import { FormSection } from "./SummarySections/FormSection";
import HistorySection from "./SummarySections/HistorySection";
import ProductSection from "./SummarySections/ProductSection";
import RangesSection from "./SummarySections/RangesSection";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";

const getStabilityFormSpecificPermissionsRequests = (userId: string, stabilityFormId: string): PermissionRequest[] => {
    const result: PermissionRequest[] = [
        createPermissionRequest(
            userId,
            `${actions.customer.tor.stabilityForms.modify}::stabilityForm`,
            ResourceType.StabilityForm,
            stabilityFormId,
            true
        ),
        createPermissionRequest(
            userId,
            actions.customer.tor.stabilityForms.sendForApproval,
            ResourceType.StabilityForm,
            stabilityFormId,
            true
        ),
        createPermissionRequest(
            userId,
            actions.customer.tor.stabilityForms.sendBack,
            ResourceType.StabilityForm,
            stabilityFormId,
            true
        ),
        createPermissionRequest(
            userId,
            actions.customer.tor.stabilityForms.approve,
            ResourceType.StabilityForm,
            stabilityFormId,
            true
        ),
        createPermissionRequest(
            userId,
            `${actions.customer.tor.stabilityForms.modify}::version`,
            ResourceType.StabilityForm,
            stabilityFormId,
            true
        ),
    ];

    return result;
};

export const StabilityFormSummary = observer(() => {
    const classes = stabilityFormStyles();
    const navigate = useNavigate();
    const { stabilityFormId } = useParams();
    const { stabilityFormStore, identityStore, permissionsStore } = useStore();

    const key = getPermissionKey(actions.customer.tor.stabilityForms.viewDetails, identityStore.currentCustomer.id);
    const viewDetailsPermissionStatus = permissionsStore.getPermissionStatus(key);

    const waitingForPermissionCheck =
        viewDetailsPermissionStatus === undefined || viewDetailsPermissionStatus === MarvelPermissionStatus.Loading;

    const accessDenied = identityStore.isSystemSpace || viewDetailsPermissionStatus === MarvelPermissionStatus.Deny;
    const isValid = isGuidValid(stabilityFormId);

    const permissionRequest = getStabilityFormSpecificPermissionsRequests(
        identityStore.currentUser.id,
        stabilityFormId || ""
    );

    useEffect(() => {
        if (!isValid) {
            return navigate(routes.pageNotFound);
        }

        if (accessDenied) {
            navigate(identityStore.startPageAvailable);
        }
    }, [accessDenied, stabilityFormId]);

    useEffect(() => {
        if (accessDenied || waitingForPermissionCheck) {
            return;
        }
        if (stabilityFormId && isValid) {
            stabilityFormStore.getStabilityFormDetails(identityStore.currentCustomer.id, stabilityFormId);
        }
    }, [viewDetailsPermissionStatus, stabilityFormId]);

    useEffect(() => {
        if (accessDenied || !isValid) {
            return;
        }

        permissionsStore.bulkEvaluate(permissionRequest);
    }, [
        stabilityFormStore.stabilityFormDetails?.ownerEmail,
        stabilityFormStore.stabilityFormDetails?.approverEmail,
        stabilityFormStore.stabilityFormDetails?.status,
    ]);

    useEffect(() => {
        if (stabilityFormId && isValid) {
            const request: GetStabilityFormChangesHistoryRequest = {
                customerId: identityStore.currentCustomer.id,
                stabilityFormId: stabilityFormId,
                pageNumber: stabilityFormStore.currentHistoryPage,
                pageSize: stabilityFormStore.pageHistorySize,
            };
            stabilityFormStore.loadStabilityFormHistoryChanges(request);
        }
    }, [stabilityFormStore.currentHistoryPage, stabilityFormStore.pageHistorySize]);

    // cleanup
    useEffect(() => {
        return () => {
            stabilityFormStore.resetStabilityFormData();
        };
    }, []);

    const loading =
        stabilityFormStore.progressFlags.loadingStabilityForm ||
        stabilityFormStore.progressFlags.loadingStabilityFormHistoryChanges ||
        !stabilityFormStore.stabilityFormDetails;

    return (
        <GeneralErrorComponentWrapper>
            <main className={classes.root}>
                <Helmet>
                    <title>{`${stabilityFormStore.stabilityFormDetails?.productName ?? ""} Stability Form`}</title>
                </Helmet>

                {loading && <FullScreenLoadingIndicator />}
                {!loading && (
                    <>
                        <div className={classes.buttonsAboveHeaderContainer}>
                            <LynxBackButton path={routes.stabilityForms} title="Stability Forms List" />
                            <div>
                                <LynxButton
                                    variant="tertiary"
                                    onClick={() =>
                                        navigate(`/stabilityForms/${stabilityFormStore.stabilityFormDetails!.id}/audit`)
                                    }
                                    leftIcon={<LynxIcon name="history" />}
                                    disabled={!stabilityFormStore.stabilityFormDetails}
                                >
                                    Audit Trail
                                </LynxButton>
                                <LynxButton
                                    variant="tertiary"
                                    size="medium"
                                    onClick={() =>
                                        stabilityFormStore.generatePdf(
                                            identityStore.currentCustomer.id,
                                            stabilityFormStore.stabilityFormDetails!.id
                                        )
                                    }
                                    loading={stabilityFormStore.progressFlags.exportingPdf}
                                    disabled={
                                        stabilityFormStore.progressFlags.exportingPdf ||
                                        !stabilityFormStore.stabilityFormDetails
                                    }
                                    leftIcon={<LynxIcon name="print" />}
                                >
                                    Print
                                </LynxButton>
                            </div>
                        </div>

                        <FormSection />
                        <ProductSection />
                        <RangesSection />
                        {stabilityFormStore.stabilityFormChangesHistory.length > 0 && <HistorySection />}
                    </>
                )}
            </main>
        </GeneralErrorComponentWrapper>
    );
});
