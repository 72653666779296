import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const excursionReviewStyles = makeStyles((theme: Theme) =>
    createStyles({
        textArea: {
            border: `1px solid ${theme.colors.neutrals.neutral300}`,
            borderRadius: theme.borderRadius,
            marginTop: "0.1rem",
            padding: "0.25rem 0.75rem 0.1rem 0.75rem",
        },

        radioGroup: {
            display: "inline-block",
            marginTop: "0.2rem",
            marginLeft: "-0.4rem",
        },

        asterisk: {
            color: theme.colors.critical.critical500,
        },

        tableHeaderCell: {
            padding: "0rem 2rem 0.25rem 2rem",
            borderBottom: "none",

            "&:first-child": {
                paddingLeft: 0,
            },

            "&:last-child": {
                paddingRight: 0,
            },
        },

        tableRowCell: {
            padding: "0.5rem 2rem",
            borderBottom: "none",

            "&:first-child": {
                paddingLeft: 0,
            },

            "&:last-child": {
                paddingRight: 0,
            },
        },

        commentsContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem",
            paddingBottom: "0.5rem",
        },

        notSafeToUse: {
            color: theme.colors.critical.critical600,
        },

        safeToUse: {
            color: theme.colors.success.success600,
        },

        tablePadding: {
            paddingTop: "0.5rem",
        },
    })
);
