export interface CommentFormModal {
    comment: string;
}

export interface CommentBaseModel {
    comment: string | null;
}

export enum CommentType {
    General = "General",
    Assessment = "Assessment",
    TemperatureGraph = "TemperatureGraph",
    EditEventBody = "EditEventBody",
    CreateEvent = "CreateEvent",
    EditEvent = "EditEvent",
    AssignEvent = "AssignEvent",
    SendEventBackForReview = "SendEventBackForReview",
}

export interface CommentModel extends CommentBaseModel {
    id: string;
    userEmail: string;
    userFirstName?: string;
    userLastName?: string;
    createdAt: Date;
    createdSection: CommentType;
    payload?: CommentPayload;
}

export interface AssignUserModel {
    id: string;
    displayName: string;
    content?: string;
}

export interface CommentPayload {
    id: string;
    firstName: string;
    lastName: string;
    notShowEmptyStateIfNoContent?: boolean;
}

export interface LinkItem {
    text: string;
    url: string;
}

export interface SidebarPermissions {
    excursionEvaluation: boolean;
    stabilityDataManagement: boolean;
    batchLookup: boolean;
    reporting: boolean;
    userManagement: boolean;
}

export interface JustificationFormModal {
    justification: string;
}

export enum EventParameter {
    DisplayId = "DisplayId",
    DeliveryId = "DeliveryId",
    ProductName = "ProductName",
    BatchId = "BatchId",
    SiteId = "SiteId",
    SiteName = "SiteName",
}

export enum ProductParameter {
    ProductName = "ProductName",
    FormNumber = "FormNumber",
}

export enum UserParameter {
    Id = "Id",
    FullName = "FullName",
    Status = "Status",
    GroupName = "GroupName",
}

export enum GroupParameter {
    GroupName = "GroupName",
    Role = "Role",
    Status = "Status",
}

export type SearchParameter =
    | EventSearchParameter
    | ProductSearchParameter
    | UserSearchParameter
    | GroupSearchParameter;

export interface EventSearchParameter {
    type: "Event";
    parameter: EventParameter[];
}

export interface ProductSearchParameter {
    type: "StabilityForm";
    parameter: ProductParameter[];
}

export interface UserSearchParameter {
    type: "User";
    parameter: UserParameter[];
}

export interface GroupSearchParameter {
    type: "Group";
    parameter: GroupParameter[];
}
