import { FormControl, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { CompleteAssessmentReviewModel } from "api/models/events/eventsApi";
import clsx from "clsx";
import { LynxAssessmentCard } from "components/LynxComponents/LynxAssessmentCard/LynxAssessmentCard";
import { BatchHoursReduced } from "components/LynxComponents/LynxAssessmentCard/LynxAssessmentCardProps";
import { LynxRadio } from "components/LynxComponents/LynxRadio/LynxRadio";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import { Form, Formik } from "formik";
import { areBatchHoursReduced } from "helpers/areBatchHoursReduced";
import { getPermissionKey } from "helpers/permissionHelpers";
import { commonConstants, productDecisionsText, promptTooltipMessages } from "lynxConstants";
import { observer } from "mobx-react";
import { AssessmentDecision, AssessmentStatus, EventStatus, YesNoType } from "models/thorEvents/eventModels";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useState } from "react";
import { useStore } from "store/StoreConfigs";
import { assessmentsReviewCommentsValidationSchema } from "validation";
import { AssessmentDetailsSection } from "../AssessmentDetailsSection";
import { ExcursionReviewProps } from "./ExcursionReviewProps";
import { excursionReviewStyles } from "./ExcursionReviewStyles";

export const ExcursionReview = observer(({ assessment }: ExcursionReviewProps) => {
    const { thorEventViewStore, identityStore, permissionsStore } = useStore();
    const classes = excursionReviewStyles();

    const [canProductBeUsed, setCanProductBeUsed] = useState<AssessmentDecision | null>(assessment.reviewerDecision);

    const hoursReducedStatus = areBatchHoursReduced(
        assessment.reviewerDecision!,
        assessment.isWithFirstEconomicCustomer
    );
    const eventReviewPermissionKey = getPermissionKey(
        actions.customer.tor.events.review,
        thorEventViewStore.eventDetails.id
    );

    const eventReviewPermission = permissionsStore.getPermissionStatus(eventReviewPermissionKey);
    const isUserAssignedToEvent = thorEventViewStore.eventDetails.reviewerUserId === identityStore.currentUser.id;
    const completeAssessmentInitialValue: CompleteAssessmentReviewModel = {
        assessmentId: assessment.id,
        comments: assessment.comments ?? "",
        reviewerDecision: assessment.reviewerDecision,
    };

    const isPermissionAllowed = eventReviewPermission === MarvelPermissionStatus.Allow;

    const reviewDisabled = thorEventViewStore.progressFlags.settingCompleteReview || !isUserAssignedToEvent;

    const getTooltipMessage = () => {
        switch (true) {
            case !isUserAssignedToEvent:
                return promptTooltipMessages.event.assignToYourself;
            case !isPermissionAllowed:
                return promptTooltipMessages.accessDenied;
            default:
                return promptTooltipMessages.empty;
        }
    };

    const getInfoBadgeText = () => {
        const hoursReduced = hoursReducedStatus === BatchHoursReduced.Yes;

        switch (true) {
            case !hoursReduced && assessment.reviewerDecision === AssessmentDecision.ExistingImpactOnProductQuality:
                return "Batch remaining hours will NOT be reduced based on this excursion, because the product has to be discarded.";
            case !hoursReduced && assessment.isWithFirstEconomicCustomer:
                return "Batch remaining hours WILL NOT be reduced based on this excursion, because the product is with the customer.";
            case hoursReduced:
                return "Batch remaining hours WILL be reduced based on this excursion, because the product is NOT with the customer.";
            default:
                return "";
        }
    };

    const getCardTableHeadersForReviewer = ["Product Decision", "With Customer?"];

    const getCorrectDecisionType = (decision: string) => {
        const type =
            decision === YesNoType.Yes
                ? AssessmentDecision.NoImpactOnProductQuality
                : decision === YesNoType.No
                ? AssessmentDecision.ExistingImpactOnProductQuality
                : null;
        setCanProductBeUsed(type);
    };

    const setAssessmentReview = (values: CompleteAssessmentReviewModel) => {
        const model: CompleteAssessmentReviewModel = {
            assessmentId: assessment.id,
            comments: values.comments,
            reviewerDecision: canProductBeUsed,
        };
        thorEventViewStore.setAssessmentReview(model);
    };

    return (
        <div>
            {assessment.status === AssessmentStatus.PendingReview && (
                <AssessmentDetailsSection title="Excursion Review">
                    <Formik
                        initialValues={completeAssessmentInitialValue}
                        validateOnBlur
                        validateOnChange
                        validationSchema={assessmentsReviewCommentsValidationSchema}
                        onSubmit={setAssessmentReview}
                    >
                        {({ handleSubmit }) => (
                            <Form>
                                <PromptTooltip title={getTooltipMessage()} placement="top">
                                    <div>
                                        <div>
                                            <LynxInputForm
                                                className={clsx(classes.textArea)}
                                                placeholder="Enter Comments..."
                                                label="Comments"
                                                onBlur={(e: any) => handleSubmit(e)}
                                                multiline
                                                rows={6}
                                                name={"comments"}
                                                disabled={reviewDisabled}
                                            />
                                        </div>
                                        <div>
                                            <LabelWithRequiredSymbol label="Can Product be used?" />
                                        </div>
                                        <FormControl component="fieldset" disabled={reviewDisabled}>
                                            <RadioGroup
                                                className={classes.radioGroup}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    getCorrectDecisionType(e.target.value);
                                                    handleSubmit();
                                                }}
                                                value={canProductBeUsed}
                                            >
                                                <LynxRadio
                                                    value={YesNoType.Yes}
                                                    checked={
                                                        canProductBeUsed === AssessmentDecision.NoImpactOnProductQuality
                                                    }
                                                    label="Yes, product CAN be used"
                                                />
                                                <LynxRadio
                                                    value={YesNoType.No}
                                                    checked={
                                                        canProductBeUsed ===
                                                        AssessmentDecision.ExistingImpactOnProductQuality
                                                    }
                                                    label="No, product CANNOT be used"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </PromptTooltip>
                            </Form>
                        )}
                    </Formik>
                </AssessmentDetailsSection>
            )}

            {assessment.reviewerDecision !== null &&
                thorEventViewStore.eventDetails.status !== EventStatus.PendingReview && (
                    <AssessmentDetailsSection title="Excursion Review">
                        <LynxAssessmentCard
                            email={assessment.reviewerUserId}
                            firstName={assessment.reviewerFirstName}
                            lastName={assessment.reviewerLastName}
                            action={"has completed the review"}
                            actionDate={assessment.reviewedDate}
                            infoBadgeText={getInfoBadgeText()}
                        >
                            <div>
                                <div className={classes.commentsContainer}>
                                    <LynxTypography variant="body-s" color="neutral400">
                                        Comments
                                    </LynxTypography>
                                    <LynxTypography>
                                        {!assessment.comments ? commonConstants.emptyValue : assessment.comments}
                                    </LynxTypography>
                                </div>
                                <Table className={classes.tablePadding}>
                                    <TableHead>
                                        <TableRow>
                                            {getCardTableHeadersForReviewer.map((header) => (
                                                <TableCell key={header} className={classes.tableHeaderCell}>
                                                    <LynxTypography color="neutral400" variant="body-s">
                                                        {header}
                                                    </LynxTypography>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.tableRowCell}>
                                                {assessment.reviewerDecision ===
                                                AssessmentDecision.NoImpactOnProductQuality ? (
                                                    <LynxTypography className={classes.safeToUse}>
                                                        {productDecisionsText.productCanBeUsed}
                                                    </LynxTypography>
                                                ) : (
                                                    <LynxTypography className={classes.notSafeToUse}>
                                                        {productDecisionsText.productCannotBeUsed}
                                                    </LynxTypography>
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.tableRowCell}>
                                                <LynxTypography>
                                                    {assessment.isWithFirstEconomicCustomer ? "Yes" : "No"}
                                                </LynxTypography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </LynxAssessmentCard>
                    </AssessmentDetailsSection>
                )}
        </div>
    );
});
