export const validationConstants = {
    minAllowedTemperature: -273,
    maxAllowedTemperature: 1000,
    maxAllowedDosage: 10_000_000_000,
} as const;

export const validationErrorMessages = {
    loginFailed: "Incorrect email or password",
    general: {
        required: "Required",
    },
    string: {
        max: (max: number) => `Max ${max} characters allowed`,
        min: (min: number) => `Min ${min} characters allowed`,
        matches: "Invalid value",
        email: "Not a valid email address format",
        onlyZerosNotAllowed: "Cannot contain only zeros",
    },
    number: {
        min: (min: number) => `Must be greater than or equal to ${min}`,
        max: (max: number) => `Must be less than or equal to ${max}`,
        moreThan: (more: number) => `Must be greater than ${more}`,
        lessThan: (less: number) => `Must be less than ${less}`,
        integer: "Must be an integer",
        maxDurationLimit: "Must be less than or equal to 100 000 hours",
        typeError: "Must be a number",
    },
    date: {
        typeError: "Must be a valid date and time",
    },
    passwordReset: {
        atLeastOneLowercase: "Must contain at least one lowercase letter",
        atLeastOneUppercase: "Must contain at least one uppercase letter",
        atLeastOneNumeric: "Must contain at least one numeric",
        mustMatch: "Passwords must match",
    },
    event: {
        atLeastOneBatch: "You should add at least one batch",
        duplicateBatches: "Duplicate batches",
        atLeastOneDevice: "You should add at least one device",
    },
    stabilityForm: {
        atLeastOneRepresentation: "You should specify at least one dose form and dosage",
        duplicateDosages: "Duplicate Dose Form/Dosage combinations",
        atLeastOneTemperatureRange: "You should specify at least one temperature range",
        duplicateRanges: "Duplicate ranges",
        duplicateRegionsPerRange: "Duplicate regions per one range",
        eitherAllCountriesOrSpecific:
            "Both all countries and regions and any specific regions cannot be defined per one range",
    },
    user: {
        lockedOut: "Account is locked for 15 minutes due to failed attempts. Try again later.",
    },
} as const;
