import { LocalStorageStringKey } from "models/shared/LocalStorageKeyEnums";
import { PaginationArea } from "models/shared/Page";

const getPageSizeKey = (area: PaginationArea) => `${area}PageSize`;

const convertToBoolean = (str: string | null) => {
    if (str === null) return null;

    switch (str.toLowerCase()) {
        case "true":
            return true;
        case "false":
            return false;
        default:
            throw new Error("Invalid boolean string");
    }
};

class LocalStorageService {
    setString = (key: LocalStorageStringKey, value: string) => localStorage.setItem(key, value);
    getString = (key: LocalStorageStringKey) => localStorage.getItem(key);
    removeString = (key: LocalStorageStringKey) => localStorage.removeItem(key);

    setPageSize = (area: PaginationArea, size: number) => localStorage.setItem(getPageSizeKey(area), size.toString());

    getPageSize = (area: PaginationArea) => {
        const pageSize = localStorage.getItem(getPageSizeKey(area));
        return pageSize ? parseInt(pageSize) : null;
    };
}

export const localStorageService = new LocalStorageService();
