import { isNumber } from "lodash";
import { commonConstants } from "lynxConstants";
import { RangeModel } from "models/thorEvents/eventModels";
import { formikModels, formikNumberValid, toFormikString } from "validation";

export const formatTemperatureRange = (
    lowerLimit: number | string | null | undefined,
    upperLimit: number | string | null | undefined,
    lowerInclusive: boolean = true,
    upperInclusive: boolean = true,
    displayExclusiveSigns: boolean = false
) => {
    const lowerExists = formikNumberValid(lowerLimit);
    const upperExists = formikNumberValid(upperLimit);

    let lowerLimitStr = "";
    let upperLimitStr = "";

    if (lowerExists) {
        if (!upperExists) {
            lowerLimitStr = !lowerInclusive ? `> ${lowerLimit}` : `>= ${lowerLimit}`;
        } else {
            lowerLimitStr = displayExclusiveSigns && !lowerInclusive ? `> ${lowerLimit}` : `${lowerLimit}`;
        }
    }

    if (upperExists) {
        if (!lowerExists) {
            upperLimitStr = !upperInclusive ? `< ${upperLimit}` : `<= ${upperLimit}`;
        } else {
            upperLimitStr = displayExclusiveSigns && !upperInclusive ? `< ${upperLimit}` : `${upperLimit}`;
        }
    }

    switch (true) {
        case !!lowerLimitStr && !!upperLimitStr:
            return `${lowerLimitStr}°C to ${upperLimitStr}°C`;
        case !!lowerLimitStr && !upperLimitStr:
            return `${lowerLimitStr}°C`;
        case !lowerLimitStr && !!upperLimitStr:
            return `${upperLimitStr}°C`;
        default:
            return commonConstants.emptyValue;
    }
};

export const formatRange = (
    range: RangeModel | formikModels.RangeFormikModel,
    displayExclusiveSigns: boolean = false
) =>
    formatTemperatureRange(
        range.lowerLimit,
        range.upperLimit,
        range.lowerInclusive,
        range.upperInclusive,
        displayExclusiveSigns
    );

export const formatTemperature = (temperature?: number | string | null) => {
    const isExists = isNumber(temperature);

    if (isExists) {
        return `${temperature}°C`;
    }

    return commonConstants.emptyValue;
};

export const rangesEqual = (x: RangeModel, y: RangeModel) => {
    const lowerEqual =
        (x.lowerLimit === null && y.lowerLimit === null) ||
        (x.lowerLimit === y.lowerLimit && x.lowerInclusive === y.lowerInclusive);
    const upperEqual =
        (x.upperLimit === null && y.upperLimit === null) ||
        (x.upperLimit === y.upperLimit && x.upperInclusive === y.upperInclusive);

    return lowerEqual && upperEqual;
};

export const formikRangesEqual = (x: formikModels.RangeFormikModel, y: formikModels.RangeFormikModel) => {
    const lowerEqual =
        (x.lowerLimit === null && y.lowerLimit === null) ||
        (x.lowerLimit === y.lowerLimit && x.lowerInclusive === y.lowerInclusive);
    const upperEqual =
        (x.upperLimit === null && y.upperLimit === null) ||
        (x.upperLimit === y.upperLimit && x.upperInclusive === y.upperInclusive);

    return lowerEqual && upperEqual;
};

export const stringifyFormikRange = (value: RangeModel | formikModels.RangeFormikModel) =>
    JSON.stringify({
        lowerLimit: toFormikString(value.lowerLimit),
        lowerInclusive: value.lowerInclusive,
        upperLimit: toFormikString(value.upperLimit),
        upperInclusive: value.upperInclusive,
    });

export const jsonParseFormikRange = (value: string): formikModels.RangeFormikModel => {
    const parsed = JSON.parse(value);

    if (!parsed) {
        throw new Error("Value is falsy");
    }

    if ("lowerLimit" in parsed && "upperLimit" in parsed && "lowerInclusive" in parsed && "upperLimit" in parsed) {
        if (
            typeof parsed.lowerLimit === "string" &&
            typeof parsed.upperLimit === "string" &&
            typeof parsed.lowerInclusive === "boolean" &&
            typeof parsed.upperInclusive === "boolean"
        ) {
            return parsed;
        }
    }

    throw new Error("Value is falsy");
};
