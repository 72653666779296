import { FormikErrors, useFormikContext } from "formik";
import { useEffect } from "react";

interface SetApiValidationErrorsProps {
    storeErrors: FormikErrors<unknown> | null;
}

export const SetApiValidationErrors = (props: SetApiValidationErrorsProps): null => {
    const formik = useFormikContext();

    useEffect(() => {
        if (props.storeErrors) {
            formik.setErrors(props.storeErrors);
        }
    }, [props.storeErrors]);

    return null;
};
