import { observer } from "mobx-react";
import { LynxAssessmentCardProps } from "./LynxAssessmentCardProps";
import { LynxAssessmentCardStyles } from "./LynxAssessmentCardStyles";
import { LynxAvatarWithTooltip } from "components/ReusableComponents/LynxAvatar/LynxAvatarWithTooltip";
import LynxTypography from "../LynxTypography/LynxTypography";
import { useStore } from "store/StoreConfigs";
import { LynxInfoBadge } from "../LynxInfoBadge";
import { convertDateToSelectedTimezone } from "helpers/timezoneHelper";
import { Span } from "../LynxTypography/Span";
import { commonConstants } from "lynxConstants";

export const LynxAssessmentCard = observer((props: LynxAssessmentCardProps) => {
    const { email, firstName, lastName, action, actionDate, infoBadgeText, children } = props;

    const classes = LynxAssessmentCardStyles();
    const { thorEventViewStore } = useStore();

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.userInfoSection}>
                    <LynxAvatarWithTooltip email={email} firstName={firstName} lastName={lastName} />
                    <LynxTypography variant="body-medium">
                        {firstName}&nbsp;{lastName}&nbsp;
                        <Span color="neutral400">{action}</Span>
                    </LynxTypography>
                </div>
                <LynxTypography variant="body-s" color="neutral300">
                    {convertDateToSelectedTimezone(actionDate, thorEventViewStore.eventDetails.timezone).format(
                        commonConstants.fullDateTimeFormat
                    )}
                </LynxTypography>
            </div>
            <div>{children}</div>
            {infoBadgeText && <LynxInfoBadge text={infoBadgeText} />}
        </div>
    );
});
