import { trimLeadingZeros } from "helpers/trimLeadingZeros";
import {
    parseFormikBoolean,
    parseFormikDate,
    parseFormikNumber,
    parseFormikString,
    validationErrorMessages,
} from "validation";
import * as yup from "yup";
import { AnyObject } from "yup/lib/types";

export type YupSchemaType<T> = [T] extends [string | null]
    ? yup.StringSchema<T>
    : [T] extends [number | null]
    ? yup.NumberSchema<T>
    : [T] extends [Date | null]
    ? yup.DateSchema<T>
    : [T] extends [boolean]
    ? yup.BooleanSchema<T>
    : [T] extends [Array<infer K> | null]
    ? yup.ArraySchema<YupSchemaType<K>>
    : [T] extends [object]
    ? yup.ObjectSchema<{ [Key in keyof T]: YupSchemaType<T[Key]> }>
    : never;

export type YupSchema<FormikModel> = {
    [Key in keyof FormikModel]: YupSchemaType<FormikModel[Key]>;
};

export const lynxNumber = () =>
    yup
        .number()
        .transform((_, originalValue) => parseFormikNumber(originalValue))
        .typeError(validationErrorMessages.number.typeError) as yup.NumberSchema<number, AnyObject, number>;

export const lynxBoolean = () =>
    yup.boolean().transform((_, originalValue) => parseFormikBoolean(originalValue)) as yup.BooleanSchema<
        boolean,
        AnyObject,
        boolean
    >;

export const lynxString = () =>
    yup.string().transform((_, originalValue) => parseFormikString(originalValue)) as yup.StringSchema<
        string,
        AnyObject,
        string
    >;

export const lynxDate = () =>
    yup
        .date()
        .transform((_, originalValue) => parseFormikDate(originalValue))
        .typeError(validationErrorMessages.date.typeError) as yup.DateSchema<Date, AnyObject, Date>;

export const lynxArray = () => yup.array() as yup.ArraySchema<any, any, any, any>;

// Extend Yup schemas
yup.addMethod(yup.string, "onlyZerosNotAllowed", function () {
    return this.test({
        name: "onlyZerosNotAllowed",
        message: validationErrorMessages.string.onlyZerosNotAllowed,
        test: (value, ctx) => trimLeadingZeros(value) !== "",
    });
});

yup.addMethod(yup.number, "maxDurationLimit", function () {
    return this.test({
        name: "maxDurationLimit",
        message: validationErrorMessages.number.maxDurationLimit,
        test: (value, ctx) => (value ? value / 60 <= 100_000 : true),
    });
});
