import clsx from "clsx";
import { LynxModal } from "components/ReusableComponents/LynxModal/LynxModal";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { observer } from "mobx-react";
import { JustificationFormModal } from "models/commonModels";
import { useRef } from "react";
import { justificationValidationSchema } from "validation";
import { LynxJustificationModalProps } from "./LynxJustificationModalProps";
import { lynxJustificationModalStyles } from "./LynxJustificationModalStyles";

export const LynxJustificationModal = observer((props: LynxJustificationModalProps) => {
    const { value, onConfirm, handleValueChange } = props;
    const classes = lynxJustificationModalStyles();
    const formRef = useRef<FormikProps<JustificationFormModal>>(null);
    const justificationInitialValue: JustificationFormModal = {
        justification: value ?? "",
    };

    const handleSubmit = (values: JustificationFormModal, formikHelpers: FormikHelpers<JustificationFormModal>) => {
        if (handleValueChange !== undefined) {
            handleValueChange(values.justification);
            formikHelpers.setFieldError("justification", undefined);
        }

        onConfirm();
    };

    return (
        <Formik
            initialValues={justificationInitialValue}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={justificationValidationSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
        >
            <Form id={"lynxjustification"}>
                <LynxModal
                    {...props}
                    onConfirm={() => formRef.current?.submitForm()}
                    header="Justification"
                    primaryButtonTitle="Confirm"
                    secondaryButtonTitle="Cancel"
                >
                    <LynxInputForm
                        className={clsx(classes.textArea)}
                        placeholder="Leave your justification comment here..."
                        multiline
                        minRows={8}
                        name="justification"
                    />
                </LynxModal>
            </Form>
        </Formik>
    );
});

export default LynxJustificationModal;
