import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const authModalStyles = makeStyles((theme: Theme) =>
    createStyles({
        formErrorYes: {
            display: "block",
        },

        formErrorNo: {
            display: "none",
        },

        form: {
            width: "100%",
        },

        input: {
            width: "60%",
        },

        iconButton: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },

        forgotPasswordLink: {
            width: "fit-content",
            marginTop: "1rem",
        },

        infoCard: {
            maxWidth: "25rem",
            display: "flex",
            gap: "0.5rem",
            padding: "0.25rem 0.5rem",
            margin: "0.5rem 0rem",
            borderRadius: theme.borderRadius,
            backgroundColor: theme.colors.info.info100,

            "& > svg": {
                flexShrink: 0,
                fill: theme.colors.info.info600,
            },
        },
    })
);
