import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const lynxModalStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },

        divider: {
            backgroundColor: theme.colors.primary.blue100,
            marginTop: "0.2rem",
            marginBottom: "1.5rem",
        },

        paper: {
            boxShadow: theme.mainShadow,
            borderRadius: theme.borderRadius,
            padding: "1.5rem 1rem",
        },

        buttonsContainer: {
            paddingTop: "1rem",
            alignItems: "baseline",
        },

        secondaryButton: {
            margin: "0 0.5rem",
        },

        title: {
            marginBottom: "1rem",
        },

        closeButtonContainer: {
            alignSelf: "flex-start",
        },

        headerPadding: {
            padding: "0 1rem",
        },
    })
);
