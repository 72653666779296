import { ApiValidationError } from "api/models/sharedModels/ApiValidationError";
import { FormikErrors } from "formik";
import _, { isNumber } from "lodash";
import { TrueFalseType } from "models/thorEvents/eventModels";

const isValidSKUs = (value: string) => {
    return /^([0-9]+)$/.test(value);
};

const generateFormikErrorsFromApi = <T>(errors: ApiValidationError[]): FormikErrors<T> => {
    const errorsWithCamelCaseProperties = _.unzip(
        errors.map((x) => [
            _.join(
                _.split(x.propertyName, ".").map((y) => _.lowerFirst(y)),
                "."
            ),
            x.errorMessage,
        ])
    );

    return _.zipObjectDeep(errorsWithCamelCaseProperties[0], errorsWithCamelCaseProperties[1]);
};

const getFieldErrorNames = (formikErrors: FormikErrors<unknown>) => {
    const transformObjectToDotNotation = (obj: any, prefix = "", result: string[] = []) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key];

            if (!value) return;

            const nextKey = prefix ? `${prefix}.${key}` : key;

            if (typeof value === "object") {
                transformObjectToDotNotation(value, nextKey, result);
            } else {
                result.push(nextKey);
            }
        });

        return result;
    };

    return transformObjectToDotNotation(formikErrors);
};

export const parseFormikNumber = (value: string | null | undefined | number): number | null => {
    if (value == null) {
        return null;
    }

    if (typeof value === "number") {
        return value;
    }

    let trimmed = value.trim();

    if (trimmed === "") {
        return null;
    }

    // for such string input values we assume it as invalid numbers
    if (trimmed === "Infinity" || trimmed === "-Infinity") {
        trimmed = "invalid";
    }

    // returns either valid number or NaN
    return Number(trimmed);
};

export const parseFormikString = (value: string | null | undefined): string | null => {
    if (value == null) {
        return null;
    }

    return value.trim() === "" ? null : value.trim();
};

export const parseFormikBoolean = (value: boolean | TrueFalseType | null | undefined): boolean | null => {
    if (value == null) {
        return null;
    }

    if (typeof value === "boolean") {
        return value;
    }

    return value === TrueFalseType.True;
};

export const parseFormikDate = (value: string | null | undefined): Date | null => {
    if (value == null) {
        return null;
    }

    return new Date(value);
};

export const parseFormikArray = (value: Array<any> | null | undefined): Array<any> | null => {
    if (value == null) {
        return null;
    }

    return value.length === 0 ? null : value;
};

export const toFormikString = (value: number | Date | null | string | undefined): string => {
    if (value == null) {
        return "";
    }

    if (typeof value === "number" && (!isFinite(value) || isNaN(value))) {
        return "";
    }

    return value.toString().trim();
};

export const toFormikArray = (value: Array<any> | null | undefined) => {
    if (value == null) {
        return [];
    }

    return _.cloneDeep(value);
};

export const toFormikBoolean = (value: boolean): TrueFalseType => {
    return value ? TrueFalseType.True : TrueFalseType.False;
};

export const formikNumberValid = (value: string | number | undefined | null): boolean => {
    if (typeof value === "string") {
        value = parseFormikNumber(value);
    }

    return value != null && isNumber(value) && isFinite(value) && !isNaN(value);
};

export const validationHelpers = {
    isValidSKUs,
    formikNumberValid,
    generateFormikErrorsFromApi,
    getFieldErrorNames,
};
