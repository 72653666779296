import { filterButtonStyles } from "./LynxFilterStyles";
import { Menu, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxIcon } from "icons/LynxIcon";
import { LynxCheckBox } from "components/LynxComponents/LynxCheckBox/LynxCheckBox";
import { LynxSearch } from "components/LynxComponents/LynxSearch";
import { useStore } from "store/StoreConfigs";
import { LynxAvatar } from "../LynxAvatar/LynxAvatar";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import clsx from "clsx";
import useDebounce from "hooks/useDebounce";
import { ListCustomerUsersRequest } from "api/models/userManagement/userManagementApi";

export interface LynxFilterOption {
    value: string;
    displayName: string;
}

export interface LynxFilterProps {
    label: string;
    options: LynxFilterOption[];
    optionsLoading: boolean;
    selectedOptions: LynxFilterOption[];
    onOptionsChange: (value: LynxFilterOption[]) => void;
    isAssignedUsersFilter?: boolean;
}

export const LynxFilter = (props: LynxFilterProps) => {
    const { label, options, selectedOptions, onOptionsChange, isAssignedUsersFilter = false, optionsLoading } = props;
    const classes = filterButtonStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { userStore, identityStore } = useStore();
    const [searchValue, setSearchValue] = useState("");
    const debouncedValue = useDebounce<string>(searchValue, 800);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isSelected = (option: LynxFilterOption) => {
        return selectedOptions.find((x) => x.value === option.value) !== undefined;
    };

    const handleOptionClick = (option: LynxFilterOption) => {
        const result = isSelected(option)
            ? selectedOptions.filter((x) => x.value !== option.value)
            : [...selectedOptions, option];

        onOptionsChange(result);
    };

    const loadCustomerUsersRequest = async () => {
        if (!optionsLoading) {
            const request: ListCustomerUsersRequest = {
                pageNumber: 1,
                pageSize: 5,
                customerId: identityStore.currentCustomer.id,
                searchValue: searchValue.trim(),
                searchParameter: ["FullName"],
            };

            await userStore.loadCustomerUsers(request);
        }
    };

    useEffect(() => {
        if (isAssignedUsersFilter) {
            loadCustomerUsersRequest();
        }
    }, [debouncedValue]);

    const loadingIndicatorClass = clsx(
        classes.loadingIndicator,
        isAssignedUsersFilter && classes.userSearchLoadingIndicator
    );

    return (
        <div>
            <LynxButton
                className={clsx(classes.button, !!anchorEl && classes.buttonFilterOpened)}
                variant="tertiary"
                onClick={handleButtonClick}
                rightIcon={<LynxIcon name={anchorEl ? "angleSmallUp" : "angleSmallDown"} />}
            >
                <LynxTypography variant="body-medium" color="neutral600">
                    {label}
                </LynxTypography>
            </LynxButton>
            <Menu
                classes={{ list: classes.list }}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                keepMounted
                PaperProps={{ style: { overflow: "visible" } }}
            >
                {isAssignedUsersFilter && (
                    <div className={classes.userSearchInputContainer}>
                        <LynxSearch
                            placeholder="Search"
                            request={loadCustomerUsersRequest}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                        />
                    </div>
                )}

                {optionsLoading && (
                    <div className={classes.loadingIndicatorContainer}>
                        <LoadingIndicator className={loadingIndicatorClass} />
                    </div>
                )}

                {!optionsLoading && options.length === 0 && (
                    <div className={classes.loadingIndicatorContainer}>
                        <div className={loadingIndicatorClass}>
                            <LynxTypography variant="body-xs">
                                {isAssignedUsersFilter ? "No results found" : "No filters"}
                            </LynxTypography>
                        </div>
                    </div>
                )}

                {!optionsLoading &&
                    options.length > 0 &&
                    options.map((option) => (
                        <MenuItem
                            className={classes.listItem}
                            key={option.value}
                            onClick={() => handleOptionClick(option)}
                        >
                            <div className={classes.searchResultRightSide}>
                                {isAssignedUsersFilter && <LynxAvatar firstName={option.displayName} />}
                                <div>
                                    <LynxTypography variant={isSelected(option) ? "body-medium" : "body"}>
                                        {option.displayName}
                                    </LynxTypography>
                                    {isAssignedUsersFilter && (
                                        <LynxTypography variant="body-xs">{option.value}</LynxTypography>
                                    )}
                                </div>
                            </div>
                            <LynxCheckBox labelClassName={classes.checkbox} checked={isSelected(option)} />
                        </MenuItem>
                    ))}
            </Menu>
        </div>
    );
};
