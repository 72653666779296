import { Modal, Grid, InputLabel, MenuItem, ButtonBase } from "@material-ui/core";
import clsx from "clsx";
import LoadingIndicator from "components/ReusableComponents/LoadingIndicator/LoadingIndicator";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useStore } from "store/StoreConfigs";
import { PermissionSection } from "../PermissionSection/PermissionSection";
import { UserItem } from "../UserList/UserListProps";
import { formStyles } from "./FormStyles";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { UserList } from "../UserList/UserList";
import { LynxSelect } from "components/LynxComponents/LynxSelect/LynxSelect";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import { LynxCheckBox } from "components/LynxComponents/LynxCheckBox/LynxCheckBox";
import { ErrorList } from "../ErrorList/ErrorList";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { isGuidValid } from "helpers/typeValidationHelpers";
import routes from "routes";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";

export const EditGroupForm = observer(() => {
    const classes = formStyles();
    const { identityStore, groupStore, permissionsStore, customerDataStore, commonStore } = useStore();
    const navigate = useNavigate();

    const { groupId } = useParams();
    const isGroupIdValid = isGuidValid(groupId);

    const [modalOpen, setModalOpen] = useState(false);
    const [error, setError] = useState("");

    const editPermission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.groups.manage)
        : permissionsStore.getPermissionResult(actions.customer.groups.manage, identityStore.currentCustomer.id);

    const accessDenied = editPermission.status !== MarvelPermissionStatus.Allow;

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        groupStore.clearErrors();
        groupStore.editGroupModel[e.target.name as string] = e.target.value;
    };

    const handleSelectChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        groupStore.roleTouched = true;
        groupStore.clearErrors();
        groupStore.editGroupModel[e.target.name as string] = e.target.value;
    };

    const handleUserSelectionChange = (newSelection: UserItem[]) => {
        groupStore.clearErrors();
        groupStore.editGroupModel.users = newSelection;
    };

    const handleRegionsChange = (e: any) => {
        groupStore.clearErrors();
        setError("");
        groupStore.editGroupModel.regions = e.target.value as string[];
        groupStore.editGroupModel.allCountriesAndRegions = false;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (
            !identityStore.isSystemSpace &&
            !groupStore.editGroupModel.allCountriesAndRegions &&
            groupStore.editGroupModel.regions.length === 0
        ) {
            setError("Please select at least one item from the list");
        } else {
            groupStore.editGroup(() => setModalOpen(true));
        }
    };

    const handleIsNotifyChange = (e: any, checked: boolean) => {
        groupStore.clearErrors();
        groupStore.editGroupModel.isNotify = checked;
    };

    const handleIsAllCountryChange = (e: any, checked: boolean) => {
        groupStore.clearErrors();

        if (checked) {
            groupStore.editGroupModel.regions = customerDataStore.regions.map((region) => region.id);
        } else {
            groupStore.editGroupModel.regions = [];
        }
        groupStore.editGroupModel.allCountriesAndRegions = checked;
    };

    useEffect(() => {
        if (!groupId || !isGroupIdValid) {
            navigate("..");
            return;
        }

        groupStore.loadPermissionsForSelection();
        groupStore.loadRolesForSelection();
        groupStore.loadUsersForSelection();

        if (!identityStore.isSystemSpace && customerDataStore.regions.length === 0) {
            customerDataStore.loadRegions({ customerId: identityStore.currentCustomer.id });
        }
    }, []);

    useEffect(() => {
        if (!isGroupIdValid) {
            return navigate(routes.pageNotFound);
        }

        if (!groupId) {
            navigate("..");
            return;
        }

        if (!groupStore.progressFlags.loadingGroupDetails) {
            groupStore.loadGroupDetails(groupId!);
        }

        return () => {
            groupStore.clearFormState();
        };
    }, [groupId]);

    useEffect(() => {
        if (groupStore.errorLoadingGroup) {
            navigate("..");
            groupStore.errorLoadingGroup = false;
        }
    }, [groupStore.errorLoadingGroup]);

    useEffect(() => {
        if (
            groupStore.rolesForSelection.length === 0 ||
            groupStore.permissionsForSelection.length === 0 ||
            groupStore.selectedGroup.id === ""
        ) {
            return;
        }

        groupStore.populateEditModel();
        if (
            !identityStore.isSystemSpace &&
            groupStore.editGroupModel.regions.length === customerDataStore.regions.length
        ) {
            groupStore.editGroupModel.allCountriesAndRegions = true;
        }
    }, [
        groupStore.selectedGroup,
        groupStore.permissionsForSelection,
        groupStore.rolesForSelection,
        customerDataStore.regions,
    ]);

    useEffect(() => {
        if (groupStore.roleTouched) {
            groupStore.populatePermissions(groupStore.editGroupModel, false);
        }
    }, [groupStore.editGroupModel.roleId]);

    const getListOfSections = () => {
        // remove duplicates
        return groupStore.editGroupModel.permissions
            .map((x) => x.section)
            .filter((x, i, self) => self.indexOf(x) === i);
    };

    return (
        <GeneralErrorComponentWrapper>
            <main className={clsx(classes.formContainer, classes.tabContainer)}>
                <div className={classes.formContainer}>
                    <Modal open={modalOpen} className={classes.modal}>
                        <div className={classes.modalContent}>
                            <LynxTypography>
                                Group &apos;{groupStore.editGroupModel.name}&apos; was edited successfully!
                            </LynxTypography>
                            <br />
                            <div className={classes.buttonsContainer}>
                                <LynxButton className={classes.formButton} onClick={() => setModalOpen(false)}>
                                    Continue editing
                                </LynxButton>
                                <LynxButton
                                    variant="tertiary"
                                    className={classes.formButton}
                                    onClick={() => navigate("..")}
                                >
                                    Back to group list
                                </LynxButton>
                            </div>
                        </div>
                    </Modal>

                    <form className={classes.form} onSubmit={handleSubmit}>
                        <Grid container>
                            <Grid item md={6} className={classes.leftSide}>
                                <LynxTypography className={classes.title} variant="h2">
                                    Edit User Group
                                </LynxTypography>
                                <LynxInput
                                    size="large"
                                    name="name"
                                    value={groupStore.editGroupModel.name}
                                    label="Group Name"
                                    onChange={handleInputChange}
                                    disabled={
                                        accessDenied ||
                                        groupStore.progressFlags.loadingGroupDetails ||
                                        groupStore.selectedGroup.readonlyName
                                    }
                                    formControlClassName={clsx(classes.fullWidthInput, classes.inputMargin)}
                                />

                                <div className={classes.inputMargin}>
                                    <LynxTypography className={classes.formInputLabel}>Users</LynxTypography>
                                    {groupStore.progressFlags.loadingUsersForSelection ||
                                    groupStore.progressFlags.loadingGroupDetails ? (
                                        <LoadingIndicator className={classes.loadingUsersIndicator} />
                                    ) : (
                                        <UserList
                                            users={groupStore.usersForSelection}
                                            selectedUsers={groupStore.editGroupModel.users}
                                            onSelectionChange={handleUserSelectionChange}
                                            disabled={accessDenied}
                                        />
                                    )}
                                </div>
                                <ErrorList errors={groupStore.formErrors} className={classes.inputMargin} />
                                <div className={classes.buttonsContainer}>
                                    <LynxButton
                                        className={classes.formButton}
                                        disabled={
                                            accessDenied ||
                                            groupStore.progressFlags.loadingGroupDetails ||
                                            groupStore.progressFlags.loadingRolesForSelection ||
                                            groupStore.progressFlags.loadingPermissionsForSelection ||
                                            groupStore.progressFlags.loadingUsersForSelection ||
                                            groupStore.progressFlags.editGroupRequest
                                        }
                                        loading={groupStore.progressFlags.editGroupRequest}
                                        type="submit"
                                    >
                                        Submit
                                    </LynxButton>
                                    <LynxButton
                                        variant="tertiary"
                                        className={classes.formButton}
                                        onClick={() => navigate("..")}
                                    >
                                        Cancel
                                    </LynxButton>
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <div style={{ padding: "20px 25px" }}>
                                    <div className={classes.permissionsHeader}>
                                        <LynxTypography variant="h2">Permissions</LynxTypography>
                                        <ButtonBase
                                            className={classes.resetChangesButton}
                                            onClick={() => groupStore.resetToRoleDefaults()}
                                            disabled={accessDenied || groupStore.selectedGroup.readonlyPermissions}
                                        >
                                            Reset to role defaults
                                        </ButtonBase>
                                    </div>

                                    <InputLabel id="edit-group-role-label" className={classes.formInputLabel}>
                                        Role
                                    </InputLabel>
                                    <LynxSelect
                                        className={clsx(classes.fullWidthInput, classes.roleDropdown)}
                                        labelId="edit-group-role-label"
                                        value={groupStore.editGroupModel.roleId}
                                        name="roleId"
                                        disabled={
                                            accessDenied ||
                                            groupStore.progressFlags.loadingGroupDetails ||
                                            groupStore.progressFlags.loadingRolesForSelection ||
                                            groupStore.selectedGroup.readonlyPermissions
                                        }
                                        loading={
                                            groupStore.progressFlags.loadingGroupDetails ||
                                            groupStore.progressFlags.loadingRolesForSelection
                                        }
                                        onChange={handleSelectChange}
                                        renderValue={(value) =>
                                            value === "" ? (
                                                <i>None</i>
                                            ) : (
                                                groupStore.rolesForSelection.find((x) => x.id === value)?.name
                                            )
                                        }
                                        displayEmpty
                                    >
                                        <MenuItem value={""}>
                                            <i>None</i>
                                        </MenuItem>
                                        {groupStore.rolesForSelection.map((x) => (
                                            <MenuItem key={x.id} value={x.id}>
                                                {x.name}
                                            </MenuItem>
                                        ))}
                                    </LynxSelect>

                                    <div>
                                        {groupStore.progressFlags.loadingPermissionsForSelection ||
                                        groupStore.progressFlags.loadingRolesForSelection ||
                                        groupStore.progressFlags.loadingGroupDetails ? (
                                            <LoadingIndicator style={{ height: "150px" }} />
                                        ) : (
                                            getListOfSections().map((x) => (
                                                <PermissionSection
                                                    operationType="edit"
                                                    sectionName={x}
                                                    key={x}
                                                    className={classes.permissionSection}
                                                    disabled={
                                                        groupStore.selectedGroup.readonlyPermissions || accessDenied
                                                    }
                                                />
                                            ))
                                        )}
                                    </div>

                                    {!identityStore.isSystemSpace && (
                                        <>
                                            <div className={classes.notificationsHeader}>
                                                <LynxTypography variant="h2">Notifications</LynxTypography>
                                            </div>

                                            <div style={{ paddingBottom: "20px" }}>
                                                <LynxCheckBox
                                                    className={classes.checkBoxLabel}
                                                    checked={groupStore.editGroupModel.isNotify}
                                                    onChange={handleIsNotifyChange}
                                                    label={
                                                        <span>
                                                            Receive email when new Event is created
                                                            <span className={classes.optionalFont}></span>
                                                        </span>
                                                    }
                                                />
                                            </div>

                                            <div style={{ paddingBottom: "20px" }}>
                                                <LynxCheckBox
                                                    className={classes.checkBoxLabel}
                                                    checked={groupStore.editGroupModel.allCountriesAndRegions}
                                                    onChange={handleIsAllCountryChange}
                                                    label={
                                                        <span>
                                                            All Countries and Regions
                                                            <span className={classes.optionalFont}></span>
                                                        </span>
                                                    }
                                                />
                                            </div>

                                            <LynxTypography>Country and Region</LynxTypography>
                                            <LynxSelect
                                                multiple
                                                value={groupStore.editGroupModel.regions}
                                                onChange={handleRegionsChange}
                                                className={clsx(classes.fullWidthInput, classes.roleDropdown)}
                                                disabled={groupStore.editGroupModel.allCountriesAndRegions}
                                                loading={customerDataStore.progressFlags.loadRegions}
                                                displayEmpty
                                                renderValue={() =>
                                                    groupStore.editGroupModel.regions.length === 0 ||
                                                    groupStore.editGroupModel.allCountriesAndRegions ? (
                                                        <i>Choose Country and Region</i>
                                                    ) : (
                                                        customerDataStore.regions
                                                            .filter((region) =>
                                                                groupStore.editGroupModel.regions.includes(region.id)
                                                            )
                                                            .map((region) => region.name)
                                                            .join(",")
                                                    )
                                                }
                                            >
                                                {customerDataStore.regions.map((region) => (
                                                    <MenuItem value={region.id} key={region.id}>
                                                        {region.name}
                                                    </MenuItem>
                                                ))}
                                            </LynxSelect>
                                            {error && <div style={{ color: "red", padding: "0px" }}>{error}</div>}
                                        </>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </main>
        </GeneralErrorComponentWrapper>
    );
});
