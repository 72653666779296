import React from "react";
import { CopyrightProps } from "./CopyrightProps";
import { copyrightStyles } from "./CopyrightStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import routes from "routes";

export function Copyright({ className }: CopyrightProps) {
    const classes = copyrightStyles();

    return (
        <div className={className}>
            <LynxTypography variant="body-s" className={classes.firstLine} color="neutral600">
                By Continuing You Agree To Our&nbsp;
                <LynxRedirectLink variant="body-s" to={routes.termsOfUse} target="_blank" typographyComponent="span">
                    Terms Of Use
                </LynxRedirectLink>
                &nbsp; and &nbsp;
                <LynxRedirectLink variant="body-s" to={routes.privacyNotice} target="_blank" typographyComponent="span">
                    Privacy Notice
                </LynxRedirectLink>
            </LynxTypography>

            <LynxTypography variant="body-xs" color="neutral400">
                &copy;{new Date().getFullYear()} All Rights Reserved. Carrier&reg;
            </LynxTypography>
        </div>
    );
}
