import { useOktaAuth } from "@okta/okta-react";
import { ApiValidationError } from "api/models/sharedModels/ApiValidationError";
import { AuthModel } from "api/models/sharedModels/AuthModel";
import { LynxJustificationModal } from "components/LynxComponents/LynxJustificationModal/LynxJustificationModal";
import { AuthModal } from "components/ReusableComponents/AuthModal/AuthModal";
import { useFormikContext } from "formik";
import { observer } from "mobx-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { formikModels, validationHelpers } from "validation";

interface CreateEventAuthModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    eventFormikModel: formikModels.EventFormikModel;
}
export const CreateEventAuthModal = observer((props: CreateEventAuthModalProps) => {
    const navigate = useNavigate();
    const formik = useFormikContext<formikModels.EventFormikModel>();
    const { oktaAuth } = useOktaAuth();
    const { eventId } = useParams();

    const { thorEventViewStore, identityStore } = useStore();
    const { isModalOpen, setIsModalOpen, eventFormikModel } = { ...props };

    const [justification, setJustification] = useState("");
    const [isShowReAuthenticate, setIsShowReAuthenticate] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [numberOfRetries, setNumberOfRetries] = useState(2);

    const handleResponse = async (statusCode: number, errors: ApiValidationError[]) => {
        switch (statusCode) {
            case 200:
                setNumberOfRetries(2);
                navigate(routes.eventDetails.to(thorEventViewStore.eventDetails.id));
                break;
            case 401:
                setNumberOfRetries(numberOfRetries - 1);
                setErrorMessage(numberOfRetries + " login attempt remaining, please try again or contact an admin");
                if (numberOfRetries <= 0) {
                    setNumberOfRetries(2);
                    await oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}${routes.login}` });
                }
                break;
            case 400:
                thorEventViewStore.setEventFormikErrors(
                    validationHelpers.generateFormikErrorsFromApi<formikModels.EventFormikModel>(errors)
                );
                setIsShowReAuthenticate(false);
                formik.setSubmitting(false);
                break;
            default:
                setErrorMessage("Something went wrong. Please, try again");
                break;
        }
    };

    const submit = async (authModel: AuthModel) => {
        if (eventId) {
            await thorEventViewStore.updateEvent(
                identityStore.currentCustomer.id,
                authModel,
                justification,
                eventFormikModel,
                eventId,
                handleResponse
            );
        } else {
            await thorEventViewStore.createManualEvent(
                identityStore.currentCustomer.id,
                authModel,
                justification,
                eventFormikModel,
                handleResponse
            );
        }
    };

    return (
        <>
            <LynxJustificationModal
                open={isModalOpen}
                title={"Confirm your actions by providing comments."}
                onClose={() => {
                    setIsModalOpen(false);
                    formik.setSubmitting(false);
                }}
                onConfirm={() => {
                    setIsModalOpen(false);
                    setIsShowReAuthenticate(true);
                }}
                value={justification}
                handleValueChange={setJustification}
            />
            <AuthModal
                isOpen={isShowReAuthenticate}
                onCancel={() => {
                    setIsShowReAuthenticate(false);
                    formik.setSubmitting(false);
                }}
                onClose={() => {
                    setIsShowReAuthenticate(false);
                    formik.setSubmitting(false);
                }}
                onSubmit={(authModel: AuthModel) => {
                    submit(authModel);
                }}
                errorMessage={errorMessage}
                loading={thorEventViewStore.progressFlags.createEvent}
                disabled={thorEventViewStore.progressFlags.createEvent}
                onBack={() => {
                    setIsShowReAuthenticate(false);
                    setIsModalOpen(true);
                    formik.setSubmitting(false);
                }}
            />
            &nbsp;
        </>
    );
});
