import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const userManagementStyles = makeStyles((theme: Theme) =>
    createStyles({
        addButton: {
            width: "auto",
            float: "right",
        },

        exportButton: {
            marginRight: "0.5rem",
            width: "auto",
            float: "right",
        },

        table: {
            borderSpacing: "0 20px",
            borderCollapse: "separate",

            "& th, & td": {
                borderBottom: "none",
            },
        },

        headerCell: {
            fontSize: "16px",
            paddingBottom: "0",
        },

        tableRow: {
            boxShadow: theme.mainShadow,
            backgroundColor: "white",
        },

        groupRoleName: {
            color: theme.colors.primary.blue400,
            fontWeight: 500,
        },

        bolderText: {
            fontWeight: 500,
        },

        actionPopper: {
            zIndex: 1,
            marginTop: "3px",
            border: `1px solid ${theme.colors.primary.blue100}`,
            borderRadius: "0px 0px 8px 8px",
        },

        actionPaper: {
            borderRadius: "0px 0px 8px 8px",
        },
        actionButtonDivider: {
            marginLeft: "2px",
        },
        icon: {
            transform: "rotate(180deg)",
        },

        drawer: {
            "& > .MuiPaper-root": {
                width: "620px",
                padding: "50px 0",
            },
        },

        customerDropdown: {
            width: "300px",
        },

        customerDropdownLabel: {
            color: theme.colors.neutrals.neutral500,
            fontSize: "14px",
            lineHeight: "120%",
            marginBottom: "0.25rem",

            "&.Mui-focused": {
                color: theme.colors.neutrals.neutral500,
            },
        },

        loadingIndicator: {
            paddingTop: "25px",
            height: "200px",
        },

        tableText: {
            fontSize: "16px",
            lineHeight: "16px",
        },

        userName: {
            fontSize: "16px",
            lineHeight: "16px",
            color: theme.colors.neutrals.neutral600,
        },

        container: {
            marginBottom: "1.25rem",
        },
        buttonsContainer: {
            width: "auto",
            minWidth: "20rem",
            gap: "0.5rem",
            margin: "0",
        },
        tabContainer: {
            padding: "30px 30px",
        },

        actionButton: {
            width: "auto",
            float: "right",
            marginRight: "0.5rem",
        },
    })
);
