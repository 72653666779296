import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const LynxAssessmentCardStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            border: `1px solid ${theme.colors.primary.blue100}`,
            padding: "0.5rem 1rem 1rem 1rem",
            borderRadius: theme.borderRadius,
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
        },

        header: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "0.5rem",
            borderBottom: `1px solid ${theme.colors.primary.blue100}`,
        },

        userInfoSection: {
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
        },
    })
);
