import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const lynxSearchStyles = makeStyles((theme: Theme) =>
    createStyles({
        union: {
            cursor: "pointer",
        },
        searchInput: {
            backgroundColor: "white",
            minWidth: "23.4rem",
        },

        searchIcon: {
            fill: theme.colors.neutrals.neutral400,
            marginLeft: "0.75rem",
        },

        select: {
            width: "13rem",
            height: "2.5rem",

            "&.Mui-focused": {
                border: `1px solid ${theme.colors.primary.blue400}`,

                "& > .MuiSelect-select": {
                    borderRadius: "0.25rem",
                    backgroundColor: "unset",
                },
            },
        },

        crossIcon: {
            width: "1.2rem",
            height: "1.2rem",
            fill: theme.colors.neutrals.neutral300,
            padding: "0.2rem",
            borderRadius: "0.75rem",
            backgroundColor: theme.colors.neutrals.neutral200,
        },
    })
);
