import { observer } from "mobx-react";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import InfoSubheader from "components/ReusableComponents/InfoSubheader";
import { LynxAvatarWithTooltip } from "components/ReusableComponents/LynxAvatar/LynxAvatarWithTooltip";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { commonConstants, promptTooltipMessages } from "lynxConstants";
import { getPermissionKey } from "helpers/permissionHelpers";
import { StabilityFormStatus } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { actions } from "models/userManagement/actions";
import { AssigneeStabilityFormUserType, MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { LynxIcon } from "icons/LynxIcon";
import { useStore } from "store/StoreConfigs";
import { Box, Grid } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { AssignUserModal } from "components/ReusableComponents/AssignUserModal/AssignUserModal";
import { stabilityFormStyles } from "components/StabilityForm/StabilityFormStyles";
import { StabilityFormAllDataApiModel } from "api/models/stabilityForms/stabilityFormsApi";
import { LynxButtonProps } from "components/LynxComponents/LynxButton/LynxButtonProps";

interface AssignedUser {
    email: string;
    firstName: string | null;
    lastName: string | null;
    role: string;
}

export const FormAssignSection = observer(() => {
    const classes = stabilityFormStyles();
    const { identityStore, permissionsStore, stabilityFormStore } = useStore();

    const stabilityForm = stabilityFormStore.stabilityFormDetails!;

    const assignPermissionKey = getPermissionKey(
        actions.customer.tor.stabilityForms.assign,
        identityStore.currentCustomer.id
    );
    const hasAssignPermission = permissionsStore.hasPermission(assignPermissionKey);
    const isAssignPermissionLoading =
        permissionsStore.getPermissionStatus(assignPermissionKey) === MarvelPermissionStatus.Loading;
    const tooltipMessage = hasAssignPermission ? promptTooltipMessages.empty : promptTooltipMessages.accessDenied;

    const assignUserToStabilityForm = (userId: string, comment: string) => {
        stabilityFormStore.assignUserToStabilityForm({
            userId,
            stabilityFormId: stabilityForm.id,
            customerId: identityStore.currentCustomer.id,
            comment,
            assignUserType:
                stabilityForm.status === StabilityFormStatus.Draft
                    ? AssigneeStabilityFormUserType.Owner
                    : AssigneeStabilityFormUserType.Approver,
        });
    };

    const getButtons = (stabilityForm: StabilityFormAllDataApiModel) => {
        const buttons: LynxButtonProps[] = [];
        const isDraft = stabilityForm.status === StabilityFormStatus.Draft;
        const isPendingApproval = stabilityForm.status === StabilityFormStatus.PendingApproval;
        const isOwner = stabilityForm.ownerEmail === identityStore.currentUser.id;
        const isApprover = stabilityForm.approverEmail === identityStore.currentUser.id;

        if (isDraft || isPendingApproval) {
            const children =
                (isDraft && !stabilityForm.ownerEmail) || (isPendingApproval && !stabilityForm.approverEmail)
                    ? "Assign"
                    : "Re-Assign";
            buttons.push({
                children,
                variant: children === "Re-Assign" ? "secondary" : "primary",
                onClick: () => stabilityFormStore.setAssignMenuOpen(true),
                disabled: !hasAssignPermission,
                loading: isAssignPermissionLoading || stabilityFormStore.progressFlags.assignUserToStabilityForm,
            });
        }

        if ((isDraft && !isOwner) || (isPendingApproval && !isApprover)) {
            const children =
                (isDraft && !stabilityForm.ownerEmail) || (isPendingApproval && !stabilityForm.approverEmail)
                    ? "Assign to me"
                    : "Re-Assign to me";
            buttons.push({
                children,
                variant: "secondary",
                onClick: () => assignUserToStabilityForm(identityStore.currentUser.id, ""),
                disabled: !hasAssignPermission,
                loading: isAssignPermissionLoading || stabilityFormStore.progressFlags.assignUserToStabilityForm,
            });
        }

        return buttons;
    };

    const users: AssignedUser[] = [
        stabilityForm.ownerEmail && {
            email: stabilityForm.ownerEmail,
            firstName: stabilityForm.ownerFirstName,
            lastName: stabilityForm.ownerLastName,
            role: "Owner",
        },
        stabilityForm.approverEmail && {
            email: stabilityForm.approverEmail,
            firstName: stabilityForm.approverFirstName,
            lastName: stabilityForm.approverLastName,
            role: "Approver",
        },
    ].filter((user): user is AssignedUser => !!user);

    return (
        <>
            <AssignUserModal
                loading={stabilityFormStore.progressFlags.assignUserToStabilityForm}
                isModalOpen={stabilityFormStore.isAssignMenuOpen}
                setIsModalOpen={stabilityFormStore.setAssignMenuOpen}
                modalWindowHeader="Assign Form"
                additionalText="Comments will be included in the email sent to the assignee."
                assignFunc={assignUserToStabilityForm}
            />
            <Grid item container direction="column">
                <Grid item>
                    <InfoSubheader text="assigned to" icon={<LynxIcon name="users" />} />
                </Grid>
                {users.map((user, index) => (
                    <Box key={index} display="flex" gridGap="0.625rem" alignItems="center" className={classes.userInfo}>
                        {user.email !== commonConstants.system && (
                            <LynxAvatarWithTooltip
                                email={user.email}
                                firstName={user.firstName}
                                lastName={user.lastName}
                            />
                        )}
                        <Box display="flex" flexDirection="column">
                            <LynxTypography variant="body-medium" color="neutral600">
                                {user.email === commonConstants.system
                                    ? commonConstants.systemPascalCase
                                    : `${user.firstName} ${user.lastName}`}
                            </LynxTypography>
                            <LynxTypography variant="body-s" color="neutral400">
                                {user.role}
                            </LynxTypography>
                        </Box>
                    </Box>
                ))}
                <Grid item>
                    <Box display="flex" gridGap="0.5rem">
                        {getButtons(stabilityForm).map((button, index) => (
                            <PromptTooltip key={index} placement="top" title={tooltipMessage}>
                                <LynxButton {...button} />
                            </PromptTooltip>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
});
