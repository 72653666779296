import { Menu, MenuItem } from "@material-ui/core";
import { LynxIcon } from "icons/LynxIcon";
import React from "react";
import { LynxButton } from "../LynxButton/LynxButton";
import { LynxButtonProps } from "../LynxButton/LynxButtonProps";
import LynxTypography from "../LynxTypography/LynxTypography";
import { lynxMenuStyles } from "./LynxMenuStyles";

export interface LynxMenuProps {
    buttonProps?: LynxButtonProps;
    menuItems: { title: string; onClick?: () => void; disabled?: boolean }[];
}

export default function LynxMenu({ buttonProps, menuItems }: LynxMenuProps) {
    const classes = lynxMenuStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuWidth = `${anchorEl?.clientWidth}px`;

    return (
        <div>
            {/* use buttonProps "children" to set title */}
            <LynxButton
                onClick={handleClick}
                {...buttonProps}
                rightIcon={<LynxIcon name={anchorEl ? "angleSmallUp" : "angleSmallDown"} />}
            />
            <Menu
                classes={{ list: classes.list }}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                keepMounted
                PaperProps={{ style: { width: menuWidth, overflow: "visible" } }}
            >
                {menuItems.map((item, index) => (
                    <MenuItem
                        className={classes.listItem}
                        key={index}
                        disabled={item.disabled}
                        onClick={() => {
                            item.onClick!();
                            handleClose();
                        }}
                    >
                        <LynxTypography variant="body-medium" color="neutral500">
                            {item.title}
                        </LynxTypography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
