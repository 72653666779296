import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import { LynxIcon } from "icons/LynxIcon";
import { errorStyles } from "./ErrorStyles";
import { observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";
import { useEffect } from "react";

const GeneralErrorPage = ({ sectionStyles }: { sectionStyles?: string }) => {
    const classes = errorStyles();

    return (
        <SectionCard className={clsx(classes.sectionCard, sectionStyles)}>
            <LynxIcon name="error" />
            <Typography className={classes.marginTop}>Ooops! Something went wrong.</Typography>
            <Typography>Try reloading this page or contact your admin.</Typography>
        </SectionCard>
    );
};

export const GeneralErrorComponentWrapper = observer((props: { children: React.ReactNode }) => {
    const { commonStore } = useStore();

    useEffect(() => {
        return () => commonStore.setShowGeneralErrorPageToFalse();
    }, []);

    return commonStore.showGeneralErrorPage ? <GeneralErrorPage /> : <>{props.children}</>;
});

export default GeneralErrorPage;
