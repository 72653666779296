import { Grid } from "@material-ui/core";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import { FieldArray, useFormikContext } from "formik";
import { LynxIcon } from "icons/LynxIcon";
import { LowerOperator, UpperOperator } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { formikModels } from "validation";
import { stabilityFormStyles } from "../StabilityFormStyles";
import TemperatureRangeFieldsArea from "./TemperatureRangeFieldsArea";

const TemperatureRangesArea = () => {
    const formik = useFormikContext<formikModels.StabilityFormFormikModel>();
    const classes = stabilityFormStyles();

    return (
        <SectionCard>
            <LynxTypography variant={"h2"} className={classes.marginBottomLarge}>
                Stability Temperature Ranges {`(${formik.values.temperatureRanges.length})`}
            </LynxTypography>
            <FieldArray
                name="temperatureRanges"
                render={(helpers) => (
                    <Grid container direction="column">
                        {formik.values.temperatureRanges.map((range, index) => (
                            <TemperatureRangeFieldsArea
                                key={range.metadata.key}
                                rangeHelpers={helpers}
                                rangeIndex={index}
                            />
                        ))}

                        <Grid item className={classes.marginTopSmall}>
                            <LynxButton
                                variant="secondary"
                                onClick={() => {
                                    const range: formikModels.TemperatureRangeFormikModel = {
                                        lowerLimit: "",
                                        lowerInclusive: true,
                                        upperLimit: "",
                                        upperInclusive: true,
                                        lowerOperator: LowerOperator.GREATER_THAN_OR_EQUAL,
                                        upperOperator: UpperOperator.LESS_THAN_OR_EQUAL,
                                        references: "",
                                        totalStabilityBudget: "",
                                        remainingStabilityBudget: "",
                                        medicalInfo: "",
                                        withUnlimitedBudget: false,
                                        rangeRegions: [],
                                        metadata: {
                                            id: "",
                                            key: crypto.randomUUID(),
                                            isReferenced: false,
                                        },
                                    };

                                    helpers.push(range);
                                }}
                                leftIcon={<LynxIcon name="plusSmall" />}
                            >
                                Add Temperature Range
                            </LynxButton>
                        </Grid>
                    </Grid>
                )}
            />
        </SectionCard>
    );
};

export default TemperatureRangesArea;
