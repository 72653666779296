import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const listPageHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
        },

        topSection: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            "&.fixed": {
                padding: "1rem 1.5rem 0.5rem 1.5rem",
            },
        },

        topSectionLeftSide: {
            display: "flex",
            gap: "1.5rem",
            alignItems: "center",
        },

        searchContainer: {
            display: "flex",
            gap: "0.5rem",
        },

        filtersContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
        },

        filterDropdownsSection: {
            display: "flex",
            gap: "1rem",
            alignItems: "center",
        },

        filterDropdownButtonsContainer: {
            display: "flex",
            gap: "1rem",
        },

        selectedFilterBubblesContainer: {
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
        },

        filterBubble: {
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            borderRadius: "0.3125rem",
            backgroundColor: theme.colors.primary.blue75,
            padding: "0.25rem",
            paddingLeft: "0.5rem",
        },
    })
);
