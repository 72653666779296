import { Grid } from "@material-ui/core";
import { AutocompleteRenderOptionState, FilterOptionsState } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { BatchDetailsModel, EventBatchDetailsModel } from "api/models/events/eventsApi";
import { CustomersDataWithSearch } from "api/models/userManagement/userManagementApi";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxSelectWithSearch } from "components/LynxComponents/LynxSelectWithSearch/LynxSelectWithSearch";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import { FieldArray, FieldArrayRenderProps } from "formik";
import { Observer, observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";
import { formikModelHelpers, formikModels, useLynxFormikContext } from "validation";
import { EditBatchRow } from "./EditBatchRow";
import { eventCreationStyles } from "./EventCreationStyles";

const filter = createFilterOptions<BatchDetailsModel>();

const isBatchDetailsModelType = (value: BatchDetailsModel | null): value is BatchDetailsModel => !!value?.batchNumber;
const emptyBatch = (batchNumber: string): BatchDetailsModel => ({
    id: "",
    batchNumber,
    stabilityFormId: "",
    productName: "",
    doseFormId: "",
    doseFormName: "",
    dosage: "",
    unitOfMeasureId: "",
    unitOfMeasureName: "",
    expirationDate: "",
    stabilityForm: null,
    isEditable: true,
});

export const BatchesSection = observer(() => {
    const { customerDataStore, identityStore, stabilityFormStore } = useStore();
    const classes = eventCreationStyles();
    const formik = useLynxFormikContext<formikModels.EventFormikModel>();
    const batchErrors = typeof formik.errors?.batches === "string" ? (formik.errors?.batches as string) : undefined;

    const getBatches = (value: string) => {
        const request: CustomersDataWithSearch = {
            customerId: identityStore.currentCustomer.id,
            searchValue: value,
            pageNumber: 1,
            pageSize: 5,
        };

        customerDataStore.loadCustomerBatches(request);
    };

    const handleBatchSelect = (value: BatchDetailsModel | null | string, helpers: FieldArrayRenderProps) => {
        let batch: BatchDetailsModel | null = null;

        if (typeof value === "string") {
            const option = customerDataStore.batches.find((x) => x.batchNumber === value);
            batch = option === undefined ? emptyBatch(value) : option;
        } else if (isBatchDetailsModelType(value)) {
            batch = value;
        }

        if (batch) {
            const batchValue: formikModels.BatchFormikModel = formikModelHelpers.convertToBatchFormikModel(batch);
            helpers.unshift(batchValue);
        }
    };

    const renderBatchOption = (option: BatchDetailsModel, state: AutocompleteRenderOptionState) => {
        if (!option.id) {
            return (
                <LynxTypography id="add-new-batch-option" variant="body-medium" color="blue500">
                    + Add New Batch
                </LynxTypography>
            );
        }

        return (
            <>
                <LynxTypography>{option.batchNumber}</LynxTypography>
                <LynxButton variant="tertiary">+ Add</LynxButton>
            </>
        );
    };

    const filterBatchOptions = (
        options: BatchDetailsModel[],
        state: FilterOptionsState<BatchDetailsModel>
    ): BatchDetailsModel[] => {
        const filtered = filter(options, state);

        if (filtered.length === 0 && state.inputValue) {
            const newBatch: EventBatchDetailsModel = {
                ...emptyBatch(state.inputValue),
                eventBatchId: "",
                quantity: "",
                rsbAdjustments: [],
                reactKey: crypto.randomUUID(),
                impactsWithStabilityRangeCount: 0,
            };
            filtered.push(newBatch);
        }

        return filtered;
    };

    return (
        <SectionCard>
            <FieldArray
                name="batches"
                render={(helpers) => (
                    <Observer>
                        {() => (
                            <>
                                <LynxTypography variant="h2" className={classes.titleMargin}>
                                    Batches ({formik.values.batches.length})
                                </LynxTypography>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <LynxSelectWithSearch
                                            disablePortal
                                            forcePopupIcon
                                            isSearchWithAutocomplete
                                            disablePopupIconInteractions
                                            freeSolo={true}
                                            openOnFocus={false}
                                            disableClearable={false}
                                            multiple={false}
                                            options={customerDataStore.batches}
                                            clearInputOnSelect
                                            value={null}
                                            selectOnFocus
                                            clearOnBlur={true}
                                            search={getBatches}
                                            placeholder="Search by Batch Number"
                                            label="Add Batch by searching for Batch Number"
                                            loading={customerDataStore.progressFlags.loadBatches}
                                            onChange={(_, value) => handleBatchSelect(value, helpers)}
                                            filterOptions={filterBatchOptions}
                                            getOptionLabel={(x) => x.batchNumber ?? ""}
                                            getOptionSelected={(x, y) => x.id === y.id}
                                            renderOption={renderBatchOption}
                                            classes={{
                                                option: classes.batchOption,
                                            }}
                                            inputProps={{
                                                name: "batches",
                                                error: !!batchErrors,
                                                assistiveText: batchErrors,
                                            }}
                                        />
                                    </Grid>
                                    {formik.values.batches.map((x, i) => (
                                        <EditBatchRow key={x.metadata.reactKey} batchIndex={i} />
                                    ))}
                                </Grid>
                            </>
                        )}
                    </Observer>
                )}
            ></FieldArray>
        </SectionCard>
    );
});
