import { secondHeaderPortalId, thirdHeaderPortalId } from "layout/LynxHeader";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { createPortal } from "react-dom";
import clsx from "clsx";
import { useState } from "react";
import { ScrollWatcher } from "./ScrollWatcher";

const listPageHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        displayNone: {
            display: "none",
        },
    })
);

interface FixedHeaderRendererProps {
    children: React.ReactNode;
    level: "second" | "third";
    // Used to trigger fixed header later or earlier so it aligns better with default unfixed header
    topMargin?: number;
}

export const FixedHeaderRenderer = ({ children, level, topMargin }: FixedHeaderRendererProps) => {
    const [isFixed, setIsFixed] = useState(false);

    const portalNode = document.getElementById(level === "second" ? secondHeaderPortalId : thirdHeaderPortalId);
    const classes = listPageHeaderStyles();

    return (
        <>
            <ScrollWatcher callback={setIsFixed} topMargin={topMargin} id={`${level}-header`} />
            {portalNode === null
                ? null
                : createPortal(<div className={clsx(!isFixed && classes.displayNone)}>{children}</div>, portalNode)}
        </>
    );
};
