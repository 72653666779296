import React, { ElementType } from "react";
import { lynxTypographyStyles } from "./LynxTypographyStyles";
import { Typography, TypographyProps } from "@material-ui/core";
import clsx from "clsx";

export type LynxTypographyVariant =
    | "h1"
    | "h2"
    | "h3"
    | "body"
    | "body-l"
    | "body-sbold"
    | "body-medium"
    | "body-s"
    | "body-sbold-s"
    | "body-xs"
    | "body-sbold-s-wide"
    | "body-italic"
    | "body-sbold-italic";

export type LynxTypographyColor =
    | "neutral200"
    | "neutral300"
    | "neutral400"
    | "neutral500"
    | "neutral600"
    | "blue500"
    | "blue300"
    | "white"
    | "critical500"
    | "critical600"
    | "success500"
    | "info600";

export interface LynxTypographyProps extends Omit<TypographyProps, "variant" | "component" | "color"> {
    variant?: LynxTypographyVariant;
    component?: ElementType<any>;
    color?: LynxTypographyColor;
}

export default function LynxTypography(props: LynxTypographyProps) {
    const { variant = "body", component = "p", color, className, ...otherProps } = { ...props };
    const classes = lynxTypographyStyles();
    let typographyVariant: "h1" | "h2" | "h3" | undefined;
    if (variant === "h1" || variant === "h2" || variant === "h3") {
        typographyVariant = variant;
    }
    const typClasses = clsx(className, classes[variant], color && classes[color]);

    return <Typography component={component} variant={typographyVariant} {...otherProps} className={typClasses} />;
}
