import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import LynxDurationInputForm from "components/ReusableForms/LynxDurationInputForm";
import LynxSelectForm from "components/ReusableForms/LynxSelectForm";
import { FieldArray, FieldArrayRenderProps, FormikErrors } from "formik";
import { formatRange, jsonParseFormikRange, rangesEqual, stringifyFormikRange } from "helpers/rangeHelpers";
import { LynxIcon } from "icons/LynxIcon";
import { observer, Observer } from "mobx-react";
import { ChangeEvent, useEffect, useState } from "react";
import { useStore } from "store/StoreConfigs";
import { formikModelHelpers, formikModels, useLynxFormikContext } from "validation";
import { eventCreationStyles } from "./EventCreationStyles";

interface ManualRangeExcursionProps {
    batchIndex: number;
}

const EditManualExcursionsPerBatchSection = observer((props: ManualRangeExcursionProps) => {
    const { batchIndex } = { ...props };
    const formik = useLynxFormikContext<formikModels.EventFormikModel>();
    const classes = eventCreationStyles();
    const { stabilityFormStore, identityStore } = useStore();
    const [stabilityRangesPerForm, setStabilityRangesPerForm] = useState<{
        stabilityFormId: string;
        stabilityRanges: formikModels.ManualExcursionPerRangeFormikModel[];
    }>({ stabilityFormId: "", stabilityRanges: [] });
    const batch = formik.values.batches[batchIndex];

    const getManualExcursionsPerRangeError = (index: number) => {
        const manualExcursionsPerRangeError = (
            formik.errors?.batches?.[batchIndex] as FormikErrors<formikModels.BatchFormikModel>
        )?.manualExcursionsPerRange as FormikErrors<formikModels.ManualExcursionPerRangeFormikModel[]>;

        return typeof manualExcursionsPerRangeError?.[index] === "string"
            ? (manualExcursionsPerRangeError?.[index] as string)
            : manualExcursionsPerRangeError?.[index]?.rangeStringified;
    };

    const handleRangeExcurionChange = (
        e: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
        index: number
    ) => {
        const rangeJson = e.target.value as string;
        const parsed = jsonParseFormikRange(rangeJson);

        const rangeExcursion: formikModels.ManualExcursionPerRangeFormikModel = {
            rangeStringified: rangeJson,
            duration: "",
            metadata: { ...formikModelHelpers.convertFromRangeFormikModel(parsed) },
        };
        formik.setFieldValue(`batches.${batchIndex}.manualExcursionsPerRange.${index}`, rangeExcursion);
    };

    const renderRangeExcursions = () =>
        stabilityRangesPerForm.stabilityRanges.map((x) => (
            <MenuItem
                key={x.rangeStringified}
                value={x.rangeStringified}
                disabled={batch.manualExcursionsPerRange.findIndex((y) => rangesEqual(x.metadata, y.metadata)) !== -1}
            >
                {formatRange(x.metadata, true)}
            </MenuItem>
        )) ?? [];

    const addExcursionPerRange = (helpers: FieldArrayRenderProps) => {
        const rangeExcursion = stabilityRangesPerForm.stabilityRanges
            .filter((x) => batch.manualExcursionsPerRange.findIndex((y) => rangesEqual(x.metadata, y.metadata)) === -1)
            .at(0);

        if (rangeExcursion) {
            helpers.push(rangeExcursion);
        }
    };

    const setLocalStabilityRanges = () => {
        const rangesPerProduct = stabilityFormStore.stabilityRangesPerProduct.find(
            (x) => !!batch.stabilityFormId && x.stabilityFormId === batch.stabilityFormId
        );

        if (rangesPerProduct) {
            const formikRangesPerProduct = {
                stabilityFormId: rangesPerProduct.stabilityFormId,
                stabilityRanges: rangesPerProduct.stabilityRanges.map((x) => ({
                    rangeStringified: stringifyFormikRange(x),
                    duration: "",
                    metadata: { ...x },
                })),
            };

            setStabilityRangesPerForm(formikRangesPerProduct);
        }
    };

    useEffect(() => {
        if (!stabilityFormStore.progressFlags.loadingStabilityRangesPerProduct) {
            stabilityFormStore.triggerStabilityRangesPerProductFlag();
        }
    }, [batch.stabilityFormId, stabilityFormStore.progressFlags.loadingStabilityRangesPerProduct]);

    useEffect(() => {
        if (stabilityRangesPerForm.stabilityFormId !== batch.stabilityFormId) {
            setLocalStabilityRanges();
        }
    }, [batch.stabilityFormId, stabilityFormStore.stabilityRangesPerProduct]);

    useEffect(() => {
        if (
            stabilityRangesPerForm.stabilityFormId === batch.stabilityFormId &&
            batch.manualExcursionsPerRange.length === 0
        ) {
            const rangeExcursion = stabilityRangesPerForm.stabilityRanges.at(0);

            if (rangeExcursion) {
                formik.setFieldValue(`batches.${batchIndex}.manualExcursionsPerRange.0`, rangeExcursion);
            }
        }
    }, [stabilityRangesPerForm]);

    useEffect(() => {
        if (!batch.stabilityFormId && batch.manualExcursionsPerRange.length > 0) {
            formik.setFieldValue(`batches.${batchIndex}.manualExcursionsPerRange`, []);
        }
    }, [batch.stabilityFormId]);

    if (batch.manualExcursionsPerRange.length === 0) {
        return null;
    }

    return (
        <FieldArray
            name={`batches.${batchIndex}.manualExcursionsPerRange`}
            render={(helpers) => (
                <Observer>
                    {() => (
                        <>
                            <LynxTypography
                                variant="h3"
                                color="neutral400"
                                className={classes.manualExcursionsPerBatchTitle}
                            >
                                {`${batch.metadata.stabilityFormFullName}, batch id: ${batch.batchNumber}`}
                            </LynxTypography>

                            <Table className={clsx(classes.manualExcursionTable, classes.widthHalf)}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="50%">
                                            <LabelWithRequiredSymbol label="Stability Temperature Range" />
                                        </TableCell>
                                        <TableCell width="45%">
                                            <LabelWithRequiredSymbol label="Excursion Duration" />
                                        </TableCell>
                                        <TableCell width="5%"></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {batch.manualExcursionsPerRange.map((x, i) => (
                                        <TableRow key={x.rangeStringified}>
                                            <TableCell width="50%">
                                                <LynxSelectForm
                                                    name={`batches.${batchIndex}.manualExcursionsPerRange.${i}.rangeStringified`}
                                                    onChange={(
                                                        e: ChangeEvent<{
                                                            name?: string | undefined;
                                                            value: unknown;
                                                        }>
                                                    ) => handleRangeExcurionChange(e, i)}
                                                    assistiveText={getManualExcursionsPerRangeError(i)}
                                                >
                                                    {renderRangeExcursions()}
                                                </LynxSelectForm>
                                            </TableCell>
                                            <TableCell width="45%">
                                                <LynxDurationInputForm
                                                    name={`batches.${batchIndex}.manualExcursionsPerRange.${i}.duration`}
                                                />
                                            </TableCell>
                                            <TableCell width="5%" className={classes.manualExcursionsPerBatchTrashCell}>
                                                <LynxButton
                                                    variant="icon"
                                                    size="medium"
                                                    className={classes.manualExcursionsPerBatchTrashIcon}
                                                    disabled={batch.manualExcursionsPerRange.length <= 1}
                                                    onClick={() => helpers.remove(i)}
                                                >
                                                    <LynxIcon name="trash" />
                                                </LynxButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            <LynxButton
                                variant="tertiary"
                                disabled={
                                    batch.manualExcursionsPerRange.length ===
                                    stabilityRangesPerForm.stabilityRanges.length
                                }
                                onClick={() => addExcursionPerRange(helpers)}
                                leftIcon={<LynxIcon name="plusSmall" />}
                                className={classes.marginTopMiddle}
                            >
                                Add Range & Excursion
                            </LynxButton>
                        </>
                    )}
                </Observer>
            )}
        />
    );
});

export default EditManualExcursionsPerBatchSection;
