import { EventType, ExcursionSource } from "models/thorEvents/eventModels";
import {
    formikModels,
    formikNumberValid,
    lynxDate,
    lynxNumber,
    lynxString,
    temperatureRangeValidationSchema,
    validationConstants,
    validationErrorMessages,
} from "validation";
import { array, lazy, object, ref, string, ValidationError } from "yup";

export const eventValidationSchema = lazy((values: formikModels.EventFormikModel) => {
    return object({
        quarantineDate: lynxDate().nullable(),
        comment: lynxString().nullable().max(1000),
        site: object({
            id: lynxString().when("type", {
                // using "values" variable as when() does not work for nested objects
                is: () => values.type === EventType.Site,
                then: (x) => x.required(),
                otherwise: (x) => x.nullable(),
            }),
        }),
        deliveryInformation: object().when("type", {
            is: EventType.Transportation,
            then: object({
                deliveryNumber: lynxString().required().max(100).onlyZerosNotAllowed(),
                orderNumbers: array().notRequired().of(string().max(50)),
                laneNumber: lynxString().nullable().max(100),
                shipperType: lynxString().nullable().max(200),
                transportationModeId: lynxString().nullable(),
                transportationServiceProviderName: lynxString().nullable().max(100),
                logisticsServiceProviderName: lynxString().nullable().max(100),
                originId: lynxString().required(),
                destinationId: lynxString().required(),
            }).concat(temperatureRangeValidationSchema),
        }),
        batches: array()
            .min(1, validationErrorMessages.event.atLeastOneBatch)
            .required()
            .of(
                object({
                    batchNumber: lynxString()
                        .required()
                        .max(100)
                        .test((value, ctx) => {
                            const noDuplicateBatches =
                                (values.batches as formikModels.BatchFormikModel[]).filter(
                                    (x) => x.batchNumber === value
                                ).length === 1;

                            return noDuplicateBatches
                                ? true
                                : ctx.createError({ message: validationErrorMessages.event.duplicateBatches });
                        }),
                    productName: lynxString().required().max(200),
                    doseFormId: lynxString().required(),
                    dosage: lynxNumber().required().moreThan(0).max(validationConstants.maxAllowedDosage),
                    unitOfMeasureId: lynxString().required(),
                    quantity: lynxNumber().required().integer().min(1).max(1000000),
                    expirationDate: lynxDate().nullable(),
                    stabilityFormId: lynxString().required(),
                    rsbAdjustments: array().of(
                        object({
                            rangeStringified: lynxString().required(),
                            rsbAdjustment: lynxNumber().required().moreThan(0).maxDurationLimit(),
                        })
                    ),
                    manualExcursionsPerRange: array().of(
                        object({
                            duration: lynxNumber().test((value, ctx) => {
                                // TODO: when() condition works only for non nested properties, find better solution to handle condition validation
                                try {
                                    if (values.excursionSource === ExcursionSource.Manual) {
                                        lynxNumber().required().min(0).maxDurationLimit().validateSync(value);
                                    }

                                    return true;
                                } catch (error) {
                                    return ctx.createError({
                                        path: ctx.path,
                                        message: (error as ValidationError).message,
                                    });
                                }
                            }),
                        })
                    ),
                })
            ),

        devices: array().when("excursionSource", {
            is: ExcursionSource.TemperatureRecordingDevice,
            then: array()
                .of(object({ id: lynxString().required() }))
                .min(1, validationErrorMessages.event.atLeastOneDevice)
                .required(),
        }),

        manualExcursion: object().when("excursionSource", {
            is: ExcursionSource.Manual,
            then: object({
                highTemperature: lynxNumber()
                    .nullable()
                    .min(validationConstants.minAllowedTemperature)
                    .max(validationConstants.maxAllowedTemperature),

                lowTemperature: lynxNumber()
                    .min(validationConstants.minAllowedTemperature)
                    .max(validationConstants.maxAllowedTemperature)
                    .when("highTemperature", {
                        is: (highTemperature: number | null | undefined) => formikNumberValid(highTemperature),
                        then: (x) => x.nullable().lessThan(ref("highTemperature")),
                        otherwise: (x) => x.required(),
                    }),

                startDateTime: lynxDate().required(),
                highExcursion: lynxNumber().required().min(0).maxDurationLimit(),

                lowExcursion: lynxNumber()
                    .required()
                    .when("highExcursion", {
                        is: (highExcursion: number | null | undefined) =>
                            formikNumberValid(highExcursion) && highExcursion! <= 0,
                        then: (x) => x.moreThan(0),
                        otherwise: (x) => x.min(0),
                    })
                    .maxDurationLimit(),
                numberOfSpikes: lynxNumber().min(0).max(100_000).nullable(),
            }),
        }),
    });
});
