import { Grid, MenuItem } from "@material-ui/core";
import { CustomersDataWithSearch } from "api/models/userManagement/userManagementApi";
import LynxAutocomplete from "components/LynxComponents/LynxAutocomplete/LynxAutocomplete";
import { SearchOption } from "components/LynxComponents/LynxAutocomplete/LynxAutocompleteProps";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxInputWithTagsForm } from "components/LynxComponents/LynxInputWithTagsForm/LynxInputWithTagsForm";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import LynxSelectForm from "components/ReusableForms/LynxSelectForm";
import RangeFieldsArea from "components/StabilityForm/FormFieldsAreas/RangeFieldsArea";
import { displayPopupNotification } from "helpers/displayPopupNotification";
import { observer } from "mobx-react";
import { NotificationType } from "models/shared/NotificationType";
import { DeliveryInformation, EventType } from "models/thorEvents/eventModels";
import { ChangeEvent, useEffect } from "react";
import { useStore } from "store/StoreConfigs";
import { formikModelHelpers, formikModels, useLynxFormikContext } from "validation";
import { SectionCard } from "../../ReusableComponents/Cards/SectionCard";
import { eventCreationStyles } from "./EventCreationStyles";

export const DeliveryInformationSection = observer(() => {
    const classes = eventCreationStyles();
    const { customerDataStore, identityStore } = useStore();
    const formik = useLynxFormikContext<formikModels.EventFormikModel>();

    const getDeliveries = (value: string | undefined) => {
        const request: CustomersDataWithSearch = {
            searchValue: value,
            customerId: identityStore.currentCustomer.id,
            pageNumber: 1,
            pageSize: 5,
        };
        customerDataStore.loadDeliveries(request);
    };

    const setDeliveryFields = (delivery: DeliveryInformation | null) => {
        formik.setFieldValue("deliveryInformation", formikModelHelpers.convertToDeliveryFormikModel(delivery));
    };

    const handleDeliveryChange = (_event: ChangeEvent<{}>, value: NonNullable<string | SearchOption>) => {
        const option = value as SearchOption;
        const delivery = customerDataStore.deliveries.find((x) => x.id === option.id);
        setDeliveryFields(delivery || null);

        if (delivery) {
            displayPopupNotification(NotificationType.SUCCESS, "Delivery Information was populated");
        }
    };

    useEffect(() => {
        if (formik.dirty) {
            setDeliveryFields(null);
        }
    }, [formik.values.type]);

    return (
        <>
            {formik.values.type === EventType.Transportation && (
                <SectionCard>
                    <LynxTypography variant="h2" className={classes.titleMargin}>
                        Delivery Information
                    </LynxTypography>
                    <Grid container>
                        <Grid container spacing={3} className={classes.autocompleteMargin}>
                            <Grid item xs={6}>
                                {/* TODO: replace with LynxSelectWithSearch */}
                                <LynxAutocomplete
                                    getOptionLabel={(option: SearchOption) => ""}
                                    placeholder="Search by Delivery ID"
                                    // @ts-ignore TODO: use different delivery model for customer data
                                    options={customerDataStore.deliveries.map((x) => ({
                                        id: x.id,
                                        displayName: x.deliveryNumber,
                                    }))}
                                    search={getDeliveries}
                                    renderInput={() => {
                                        return <div></div>;
                                    }}
                                    loading={customerDataStore.progressFlags.loadDeliveries}
                                    cleanAfterSearch={true}
                                    onChange={handleDeliveryChange}
                                />
                            </Grid>
                            <Grid item>
                                <LynxButton size="medium" variant="tertiary" onClick={() => setDeliveryFields(null)}>
                                    Reset
                                </LynxButton>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className={classes.rowPadding}>
                            <Grid item xs={3}>
                                <LynxInputForm
                                    name="deliveryInformation.deliveryNumber"
                                    label={<LabelWithRequiredSymbol label="Delivery ID" />}
                                    placeholder="Enter an Delivery Id"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LynxInputWithTagsForm
                                    name="deliveryInformation.orderNumbers"
                                    label={"PO Number"}
                                    placeholder="Type PO Number and press Enter"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LynxInputForm
                                    name="deliveryInformation.laneNumber"
                                    label={"Lane ID"}
                                    placeholder="Enter Lane ID"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LynxInputForm
                                    name="deliveryInformation.shipperType"
                                    label={"Shipper Type"}
                                    placeholder="Enter Shipper Type"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className={classes.rowPadding}>
                            <Grid item xs={3}>
                                <LynxSelectForm
                                    name="deliveryInformation.transportationModeId"
                                    label="Mode of Transport"
                                    placeholder="None"
                                    loading={customerDataStore.progressFlags.loadTransportationModes}
                                    showGeneratedDefaultOption
                                >
                                    {customerDataStore.transportationModes.map((mode) => (
                                        <MenuItem value={mode.id} key={mode.id}>
                                            {mode.name}
                                        </MenuItem>
                                    ))}
                                </LynxSelectForm>
                            </Grid>
                            <Grid item xs={3}>
                                <LynxInputForm
                                    name="deliveryInformation.transportationServiceProviderName"
                                    label={"Transportation Service Provider"}
                                    placeholder="Enter Transportation Service Provider"
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <LynxInputForm
                                    name="deliveryInformation.logisticsServiceProviderName"
                                    label={"Logistics Service Provider (LSP)"}
                                    placeholder="Enter a Logistics Service Provider (LSP)"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className={classes.rowPadding}>
                            <Grid item xs={6}>
                                <LynxTypography variant="body-s" color="neutral400">
                                    {<LabelWithRequiredSymbol label="Transportation Storage Conditions" />}
                                </LynxTypography>
                                <RangeFieldsArea<formikModels.EventFormikModel> fieldNamesPrefix="deliveryInformation" />
                            </Grid>
                        </Grid>
                    </Grid>
                </SectionCard>
            )}
        </>
    );
});
