import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";

export interface ScrollWatcherProps {
    id: string;
    topMargin?: number;
    callback: (isOutsideViewport: boolean) => void;
}

export const ScrollWatcher = observer((props: ScrollWatcherProps) => {
    const { id, callback, topMargin = 0 } = props;
    const [observer, setObserver] = useState<IntersectionObserver>();

    const refCallback = useCallback((node: HTMLDivElement | null) => {
        if (observer) {
            observer.disconnect();
        }

        if (node !== null) {
            const observerCallback = (entries: IntersectionObserverEntry[]) => {
                // When node is outside the viewport isIntersecting = false
                callback(entries.every((x) => !x.isIntersecting));
            };

            const observer = new IntersectionObserver(observerCallback, {
                rootMargin: `${topMargin}px 0px 0px 0px`,
            });

            observer.observe(node);
            setObserver(observer);
        }
    }, []);

    useEffect(() => {
        return () => {
            if (observer !== undefined) {
                observer.disconnect();
            }
        };
    }, []);

    return <div id={`${id}-scroll-watcher`} ref={refCallback} />;
});
