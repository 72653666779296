import { LowerOperator, UpperOperator } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { RangeModel } from "models/thorEvents/eventModels";
import { formikNumberValid, lynxBoolean, lynxNumber, validationConstants, YupSchema } from "validation";
import { object, ref } from "yup";

export const temperatureRangeValidationSchemaObj = {
    upperLimit: lynxNumber()
        .when("upperOperator", {
            is: UpperOperator.INFINITY,
            then: (x) => x.nullable().oneOf([null]),
            otherwise: (x) => x.required(),
        })
        .min(validationConstants.minAllowedTemperature)
        .max(validationConstants.maxAllowedTemperature),

    lowerLimit: lynxNumber()
        .min(validationConstants.minAllowedTemperature)
        .max(validationConstants.maxAllowedTemperature)
        .when("lowerOperator", {
            is: LowerOperator.INFINITY,
            then: (x) => x.nullable().oneOf([null]),
            otherwise: (x) => x.required(),
        })
        .when("upperLimit", {
            is: (upperLimit: number | null | undefined) => formikNumberValid(upperLimit),
            then: (x) => x.lessThan(ref("upperLimit")),
            otherwise: (x) => x,
        }),
    lowerInclusive: lynxBoolean().required(),
    upperInclusive: lynxBoolean().required(),
};

export const temperatureRangeValidationSchema = object<YupSchema<RangeModel>>(temperatureRangeValidationSchemaObj);
