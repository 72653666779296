import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import { LynxIcon } from "icons/LynxIcon";

interface LynxBackButtonProps {
    path: string;
    title: string;
}

export const LynxBackButton = (props: LynxBackButtonProps) => {
    const { path, title } = props;

    return (
        <LynxRedirectLink to={path}>
            <LynxButton variant="tertiary" leftIcon={<LynxIcon name="arrowLeft" />}>
                Back to {title}
            </LynxButton>
        </LynxRedirectLink>
    );
};
