import { commonConstants } from "lynxConstants";

export const formatDurationToString = (
    durationInMinutes?: string | number | null,
    showZero: boolean = true,
    unlimited: boolean = false
): string => {
    const casted = Number(durationInMinutes);

    if (
        durationInMinutes === null ||
        durationInMinutes === undefined ||
        durationInMinutes === "" ||
        Number.isNaN(casted)
    ) {
        return unlimited ? commonConstants.unlimited : commonConstants.emptyValue;
    }

    const absoluteValue = Math.abs(casted);

    if (absoluteValue === 0 && !showZero) {
        return commonConstants.emptyValue;
    }

    const hours = Math.floor(absoluteValue / 60);
    const minutes = Math.floor(absoluteValue % 60);
    const seconds = Math.round((absoluteValue - Math.trunc(absoluteValue)) * 60);

    const signString = casted < 0 ? "-" : "";
    const hoursString = `${hours}h`;
    const minutesString = minutes === 0 ? " 00m" : ` ${minutes}m`;
    const secondsString = seconds === 0 ? "" : ` ${seconds}s`;

    return `${signString}${hoursString}${minutesString}${secondsString}`;
};
