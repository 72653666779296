import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { ProductRepresentationAllDataApiModel } from "api/models/stabilityForms/stabilityFormsApi";
import clsx from "clsx";
import LynxTextarea from "components/LynxComponents/LynxTextarea/LynxTextarea";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import { stabilityFormStyles } from "components/StabilityForm/StabilityFormStyles";
import { getRangeDisplay } from "helpers/temperatureOperatorsParser";
import { observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";

export default observer(function ProductSection() {
    const { stabilityFormStore } = useStore();
    const classes = stabilityFormStyles();

    const representationTableHeaders = [
        { key: 0, label: "Dose Form", required: true, width: "60%" },
        { key: 1, label: "Dosage", required: true, width: "40%" },
    ];

    const showDosage = (representation: ProductRepresentationAllDataApiModel) =>
        representation.allDosagesFlag ? "All" : `${representation.dosage} ${representation.unitOfMeasureName}`;

    if (!stabilityFormStore.stabilityFormDetails) {
        return null;
    }

    return (
        <SectionCard>
            <LynxTypography variant={"h2"} className={classes.marginBottomLarge}>
                Product Information
            </LynxTypography>
            <Grid container item className={classes.marginBottomLarge} spacing={2}>
                <Grid item xs={3}>
                    <LynxTextarea
                        value={stabilityFormStore.stabilityFormDetails.productBusinessKey}
                        readOnly={true}
                        label="Product ID (GMID)"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxTextarea
                        value={stabilityFormStore.stabilityFormDetails.productName}
                        readOnly={true}
                        label="Product Name"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxTextarea
                        value={stabilityFormStore.stabilityFormDetails.genericProductName}
                        readOnly={true}
                        label="Generic Product Name"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxTextarea
                        value={stabilityFormStore.stabilityFormDetails.categoryName}
                        readOnly={true}
                        label="Product Category"
                    />
                </Grid>
            </Grid>

            <Grid container item className={` ${classes.marginBottomLarge}`} spacing={2}>
                <Grid item xs={3}>
                    <LynxTextarea
                        value={stabilityFormStore.stabilityFormDetails.presentationName}
                        readOnly={true}
                        label="Presentation"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxTextarea
                        value={stabilityFormStore.stabilityFormDetails.storageTypeName}
                        readOnly={true}
                        label="Product Type"
                    />
                </Grid>

                <Grid item xs={6}>
                    <LynxTextarea
                        label={"Product Storage Conditions"}
                        value={getRangeDisplay(
                            stabilityFormStore.stabilityFormDetails.lowerLimit,
                            stabilityFormStore.stabilityFormDetails.upperLimit
                        )}
                        readOnly={true}
                    />
                </Grid>
            </Grid>

            <Grid container item className={` ${classes.marginBottomLarge}`} spacing={2}>
                <Grid item xs={3}>
                    <LynxTextarea
                        value={stabilityFormStore.stabilityFormDetails.expiryPeriodInHours}
                        readOnly={true}
                        label="Expiry Period (hours)"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxTextarea
                        value={stabilityFormStore.stabilityFormDetails.freezeCycleLimit}
                        readOnly={true}
                        label="Freeze Cycle Count Allowed"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxTextarea
                        value={stabilityFormStore.stabilityFormDetails.productSpec}
                        readOnly={true}
                        label="Product Spec"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxTextarea
                        value={stabilityFormStore.stabilityFormDetails.SKUs.join(", ")}
                        readOnly={true}
                        label="SKUs"
                    />
                </Grid>

                {/* <Grid item xs={3}>
                    <LynxTextarea
                        value={stabilityFormStore.formDetails.product.heatCycleLimit}
                        readOnly={true}
                        label="Heat Cycle Count Allowed"
                    />
                </Grid> */}
            </Grid>

            {/* <Grid container item spacing={2}>
                
            </Grid> */}

            <Grid container className={classes.marginTopLarge} lg={5} md={6} sm={6}>
                <LynxTypography variant="h3" color="neutral400">
                    Dose forms and dosages
                </LynxTypography>
                <Table>
                    <TableHead>
                        <TableRow>
                            {representationTableHeaders.map((x) => (
                                <TableCell
                                    key={x.key}
                                    className={clsx(classes.paddingBottomSmall, classes.tableCellNoBorder)}
                                    style={{ width: x.width }}
                                >
                                    <LynxTypography color="neutral400" variant="body-s">
                                        {x.label}
                                    </LynxTypography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stabilityFormStore.stabilityFormDetails.representations.map((representation) => (
                            <TableRow key={representation.id} className={classes.tableContentRow}>
                                <TableCell>
                                    <LynxTextarea value={representation.doseFormName} readOnly={true} />
                                </TableCell>
                                <TableCell>
                                    <LynxTextarea value={showDosage(representation)} readOnly={true} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </SectionCard>
    );
});
