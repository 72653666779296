import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const lynxCommentsStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridMarginBotom: {
            marginBottom: "1rem",
        },
        emptyStateMargin: {
            marginBottom: "1.5rem",
        },
        gridCommentBottom: {
            borderBottom: `1px ${theme.colors.primary.blue100} solid`,
            paddingBottom: "1rem",
            marginBottom: "1.5rem",
        },
        avatarContainer: {
            marginRight: "0.5rem",
        },
        dateTimeMargin: {
            marginBottom: "0.5rem",
        },
        contentStyles: {
            overflowWrap: "break-word",
            wordWrap: "break-word",
            msWordBreak: "break-all",
            wordBreak: "break-word",
        },
        disabledContainer: {
            opacity: 0.5,
        },
        buttonWithIcon: {
            marginBottom: "0.5rem",
        },
        noAvatarMargin: {
            marginLeft: "2rem",
        },
    })
);
