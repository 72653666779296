import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import {
    getSearchPlaceholder,
    LynxSearch,
    LynxSearchParameterSelectProps,
    LynxSearchParametersSelect,
} from "components/LynxComponents/LynxSearch";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxIcon } from "icons/LynxIcon";
import { listPageHeaderStyles } from "./ListPageHeaderStyles";
import { LynxFilter, LynxFilterProps } from "./LynxFilter";
import React from "react";
import { PromptTooltip } from "../PromptTooltip/PromptTooltip";
import { withFixed } from "helpers/withFixed";
import { FixedHeaderRenderer } from "../FixedHeaderRenderer";

interface TitleButtonProps {
    text: string;
    onClick: () => void;
    disabled: boolean;
    loading: boolean;
    tooltip: string;
}

interface SearchProps {
    searchParameterProps: LynxSearchParameterSelectProps;
    searchValue: string;
    setSearchValue: (value: string) => void;
}

interface ListPageHeaderProps {
    title: string;
    filters: LynxFilterProps[];
    request: () => void;
    resetFilters: () => void;
    search: SearchProps;
    titleButtonProps: TitleButtonProps;
}

export const ListPageHeader = (props: ListPageHeaderProps) => {
    const { title, filters, request, resetFilters, search, titleButtonProps } = props;
    const classes = listPageHeaderStyles();

    const topSectionContent = (isFixed: boolean) => {
        return (
            <div className={withFixed(classes.topSection, isFixed)}>
                <div className={classes.topSectionLeftSide}>
                    <LynxTypography variant={isFixed ? "h2" : "h1"} component="h1">
                        {title}
                    </LynxTypography>
                    {!isFixed && (
                        <div className={classes.searchContainer}>
                            <LynxSearchParametersSelect {...search.searchParameterProps} />
                            <LynxSearch
                                searchValue={search.searchValue}
                                setSearchValue={search.setSearchValue}
                                request={request}
                                placeholder={getSearchPlaceholder(
                                    search.searchParameterProps.type,
                                    search.searchParameterProps.parameter
                                )}
                                triggerSearchOnClear
                            />
                        </div>
                    )}
                </div>
                <PromptTooltip placement="top" title={titleButtonProps.tooltip}>
                    <LynxButton
                        size={isFixed ? "small" : "medium"}
                        onClick={titleButtonProps.onClick}
                        disabled={titleButtonProps.disabled}
                        loading={titleButtonProps.loading}
                    >
                        {titleButtonProps.text}
                    </LynxButton>
                </PromptTooltip>
            </div>
        );
    };

    return (
        <>
            <FixedHeaderRenderer level="second" topMargin={-32}>
                {topSectionContent(true)}
            </FixedHeaderRenderer>
            <div className={classes.root}>
                {topSectionContent(false)}

                <div className={classes.filtersContainer}>
                    <div className={classes.filterDropdownsSection}>
                        <LynxTypography color="neutral400">Filter by:</LynxTypography>
                        <div className={classes.filterDropdownButtonsContainer}>
                            {filters.map((x) => (
                                <LynxFilter
                                    label={x.label}
                                    options={x.options}
                                    optionsLoading={x.optionsLoading}
                                    onOptionsChange={(value) => {
                                        x.onOptionsChange(value);
                                        request();
                                    }}
                                    selectedOptions={x.selectedOptions}
                                    key={x.label}
                                    isAssignedUsersFilter={x.isAssignedUsersFilter}
                                />
                            ))}
                        </div>
                    </div>
                    {filters.some((x) => x.selectedOptions.length > 0) && (
                        <div className={classes.selectedFilterBubblesContainer}>
                            {filters.map((filter) => (
                                <React.Fragment key={filter.label}>
                                    {filter.selectedOptions.map((selectedOption) => (
                                        <div className={classes.filterBubble} key={selectedOption.value}>
                                            <LynxTypography variant="body-s" color="neutral500">
                                                {filter.label}: {selectedOption.displayName}
                                            </LynxTypography>
                                            <LynxButton
                                                variant="icon"
                                                onClick={() => {
                                                    const newValue = filter.selectedOptions.filter(
                                                        (option) => option.value !== selectedOption.value
                                                    );

                                                    filter.onOptionsChange(newValue);
                                                    request();
                                                }}
                                            >
                                                <LynxIcon name="crossSmall" />
                                            </LynxButton>
                                        </div>
                                    ))}
                                </React.Fragment>
                            ))}
                            <LynxButton
                                variant="tertiary"
                                onClick={() => {
                                    resetFilters();
                                    request();
                                }}
                            >
                                Clear Filters
                            </LynxButton>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
