import { Divider, Grid, Paper } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { copyrightStyles } from "./CopyrightStyles";
import { TermsOfUseContent } from "components/TermsOfUse/TermsOfUseContent";

export default function TermsOfUse() {
    const classes = copyrightStyles();
    const baseOriginUri = window.origin;

    return (
        <Paper elevation={0} className={classes.paper}>
            <Grid className={classes.paragraphStyle}>
                <LynxTypography variant="h1">Lynx FacTOR Terms and Conditions of Use</LynxTypography>
                <Divider className={classes.divider} />
                <TermsOfUseContent baseOriginUri={baseOriginUri} />
            </Grid>
        </Paper>
    );
}
