import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import { LynxDateTimePickerForm } from "components/ReusableForms/LynxDateTimePickerForm";
import LynxDurationInputForm from "components/ReusableForms/LynxDurationInputForm";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import { FieldArray, useFormikContext } from "formik";
import { commonConstants } from "lynxConstants";
import { Observer, observer } from "mobx-react";
import { EventType } from "models/thorEvents/eventModels";
import { useEffect } from "react";
import { useStore } from "store/StoreConfigs";
import { eventCreationStyles } from "./EventCreationStyles";
import EditManualExcursionsPerBatchSection from "./EditManualExcursionsPerBatchSection";
import { formikModels } from "validation";

const EditManualExcursionsSection = observer(() => {
    const formik = useFormikContext<formikModels.EventFormikModel>();
    const { thorEventViewStore } = useStore();
    const classes = eventCreationStyles();
    const currentTz = thorEventViewStore.eventFormApiResponse.timezone || commonConstants.UTCTimezone;

    useEffect(() => {
        if (formik.values.type === EventType.Site) {
            formik.setFieldValue("manualExcursion.deviceIds", "");
        }
    }, [formik.values.type]);

    return (
        <>
            {formik.values.type === EventType.Transportation && (
                <Grid container item xs={6}>
                    <LynxInputForm name="manualExcursion.deviceIds" placeholder="Enter TRD numbers" label="TRD" />
                </Grid>
            )}

            <LynxTypography variant="h3" color="neutral400" className={classes.temperatureDataSubtitle}>
                General Excursion Information
            </LynxTypography>

            <Table className={classes.manualExcursionTable}>
                <TableHead>
                    <TableRow>
                        <TableCell width="25%">
                            <LabelWithRequiredSymbol label="Min & Max Temperature" />
                        </TableCell>
                        <TableCell width="20%">
                            <LabelWithRequiredSymbol label="Excursion Start Date & Time" />
                        </TableCell>
                        <TableCell width="20%">
                            <LabelWithRequiredSymbol label="High Excursion Duration" />
                        </TableCell>
                        <TableCell width="20%">
                            <LabelWithRequiredSymbol label="Low Excursion Duration" />
                        </TableCell>

                        <TableCell width="15%">
                            <LynxTypography variant="body-s" color="neutral400">
                                Number Of Spikes
                            </LynxTypography>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        <TableCell width="25%">
                            <div style={{ display: "flex" }}>
                                <LynxInputForm name="manualExcursion.lowTemperature" placeholder="Min" />
                                <LynxTypography className={classes.manualExcursionTempSign}>
                                    {commonConstants.temperatureSign}
                                </LynxTypography>
                                <LynxTypography className={classes.manualExcursionTemp}>to</LynxTypography>
                                <LynxInputForm placeholder="Max" name="manualExcursion.highTemperature" />
                                <LynxTypography className={classes.manualExcursionTempSign}>
                                    {commonConstants.temperatureSign}
                                </LynxTypography>
                            </div>
                        </TableCell>
                        <TableCell width="20%">
                            <LynxDateTimePickerForm
                                showNow={false}
                                variant="datetime"
                                customRequestFormat={commonConstants.dateTimeFormatWithTimeZone}
                                showTime
                                format={commonConstants.dateTimeFormat}
                                name="manualExcursion.startDateTime"
                                timezone={currentTz}
                            />
                        </TableCell>
                        <TableCell width="20%">
                            <LynxDurationInputForm name="manualExcursion.highExcursion" />
                        </TableCell>
                        <TableCell width="20%">
                            <LynxDurationInputForm name="manualExcursion.lowExcursion" />
                        </TableCell>
                        <TableCell width="15%">
                            <LynxInputForm name="manualExcursion.numberOfSpikes" placeholder="Number Of Spikes" />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <FieldArray
                name="batches"
                render={() => (
                    <Observer>
                        {() => (
                            <>
                                {formik.values.batches.map((x, i) => (
                                    <EditManualExcursionsPerBatchSection batchIndex={i} key={x.metadata.reactKey} />
                                ))}
                            </>
                        )}
                    </Observer>
                )}
            />
        </>
    );
});

export default EditManualExcursionsSection;
