import { Grid } from "@material-ui/core";
import { AutocompleteChangeReason } from "@material-ui/lab";
import { CalculateDevicesExcursionRequest, ExcursionResultPerDevice } from "api/models/events/eventsApi";
import { services } from "api/serviceConfig";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import LynxSearchForm from "components/ReusableForms/LynxSearchForm";
import { SearchOption } from "components/ReusableForms/Props/LynxSearchFormProps";
import { FieldArray } from "formik";
import { formatDurationToString } from "helpers/formatDurationToString";
import { formatTemperatureRange } from "helpers/rangeHelpers";
import { convertDateToSelectedTimezone } from "helpers/timezoneHelper";
import { LynxIcon } from "icons/LynxIcon";
import _ from "lodash";
import { commonConstants } from "lynxConstants";
import { observer, Observer } from "mobx-react";
import { EventType, ExcursionSource } from "models/thorEvents/eventModels";
import { useEffect, useState } from "react";
import { useStore } from "store/StoreConfigs";
import { formikModelHelpers, formikModels, temperatureRangeValidationSchema, useLynxFormikContext } from "validation";
import { eventCreationStyles } from "./EventCreationStyles";
import { getIconTooltipForExcursionDates } from "./helper-functions";

const EditDevicesSection = observer(() => {
    const formik = useLynxFormikContext<formikModels.EventFormikModel>();
    const classes = eventCreationStyles();
    const { thorEventViewStore, customerDataStore, identityStore } = useStore();
    const [devicesExcursions, setDevicesExcursions] = useState<ExcursionResultPerDevice[]>([]);

    const excursionSource = formik.values.excursionSource;
    const delivery = formik.values.deliveryInformation;
    const currentTz = thorEventViewStore.eventFormApiResponse.timezone || commonConstants.UTCTimezone;
    const dropdownDeviceIds = _.uniq(customerDataStore.devices.map((x) => x.id)).sort();
    const selectedDeviceIds = _.uniq(formik.values.devices.map((x) => x.id)).sort();
    const allDeviceIds = _.union(dropdownDeviceIds, selectedDeviceIds).sort();
    const stabilityFormIds: string[] = _.uniq(
        formik.values.batches.filter((x) => !!x.stabilityFormId).map((x) => x.stabilityFormId)
    ).sort();

    const checkAlarmForDevice = (id: string): boolean => {
        return devicesExcursions.some((x) => x.deviceId === id && x.hasAlarm);
    };

    const calculateDevicesExcursion = async (deviceIds: string[]) => {
        const request: CalculateDevicesExcursionRequest = {
            customerId: identityStore.currentCustomer.id,
            processType: formik.values.metadata.processType,
            deviceIds: deviceIds,
            stabilityFormIds: stabilityFormIds,
            allowedRange: formikModelHelpers.convertFromRangeFormikModel(delivery),
        };

        const response = await services.Events.calculateDevicesExcursion(request);
        return response;
    };

    const getDevices = (searchValue?: string) => {
        customerDataStore.loadCustomerDevices({
            customerId: identityStore.currentCustomer.id,
            searchValue: searchValue,
            pageNumber: 1,
            pageSize: 5,
        });
    };

    useEffect(() => {
        if (formik.values.type === EventType.Site) {
            return;
        }

        getDevices();
    }, []);

    // Calculate cumulative product excursion based on selected devices and transportation conditions
    useEffect(() => {
        const func = async () => {
            if (excursionSource === ExcursionSource.Manual) {
                return;
            }

            if (temperatureRangeValidationSchema.isValidSync(delivery) && selectedDeviceIds.length > 0) {
                const response = await calculateDevicesExcursion(selectedDeviceIds);

                if (_.inRange(response.status, 200, 300)) {
                    const { excursionResultsPerDevice: _, ...excursion } = response.data;
                    thorEventViewStore.setCalculatedExcursion(excursion);
                    return;
                }
            }

            thorEventViewStore.resetCalculatedExcursion();
        };

        func();
    }, [
        excursionSource,
        selectedDeviceIds.join(","),
        delivery.lowerLimit,
        delivery.lowerInclusive,
        delivery.upperLimit,
        delivery.upperInclusive,
    ]);

    // Calculate excursion for each device based on transportation conditions
    useEffect(() => {
        const func = async () => {
            if (excursionSource === ExcursionSource.Manual) {
                return;
            }

            if (temperatureRangeValidationSchema.isValidSync(delivery) && allDeviceIds.length > 0) {
                const response = await calculateDevicesExcursion(allDeviceIds);

                if (_.inRange(response.status, 200, 300)) {
                    setDevicesExcursions(response.data.excursionResultsPerDevice);
                    return;
                }
            }

            setDevicesExcursions([]);
        };

        func();
    }, [
        excursionSource,
        allDeviceIds.join(","),
        delivery.lowerLimit,
        delivery.lowerInclusive,
        delivery.upperLimit,
        delivery.upperInclusive,
    ]);

    return (
        <>
            <FieldArray
                name="devices"
                render={(helpers) => (
                    <Observer>
                        {() => (
                            <>
                                {/* TODO: replace with LynxSelectWithSearch */}
                                <LynxSearchForm
                                    inputProps={{
                                        name: "devices",
                                        error: formik.errors?.devices !== undefined,
                                        assistiveText: formik.errors?.devices as string,
                                    }}
                                    className={classes.trdSearch}
                                    loading={customerDataStore.progressFlags.loadDevices}
                                    disableCloseOnSelect
                                    placeholder="Select TRDs"
                                    name="devices"
                                    label={<LabelWithRequiredSymbol label="TRD" />}
                                    multiple
                                    options={customerDataStore.devices.map((device) => ({
                                        id: device.id,
                                        displayName: device.serialNumber,
                                        hasAlarm: checkAlarmForDevice(device.id),
                                    }))}
                                    search={getDevices}
                                    renderInput={() => {
                                        return <div></div>;
                                    }}
                                    customValue={formik.values.devices.map((x) => ({
                                        id: x.id,
                                        displayName: x.metadata.serialNumber,
                                    }))}
                                    customOnChange={(
                                        event: React.ChangeEvent<{}>,
                                        value: string | SearchOption | null | (string | SearchOption)[],
                                        reason: AutocompleteChangeReason
                                    ) => {
                                        if (
                                            event.nativeEvent instanceof KeyboardEvent &&
                                            (event.nativeEvent.key === "Enter" || event.nativeEvent.key === "Backspace")
                                        ) {
                                            // handle select/unselect by enter/backspace.
                                            return;
                                        }

                                        const options = value as SearchOption[];
                                        const devices: formikModels.DeviceFormikModel[] = options.map((x) => ({
                                            id: x.id,
                                            metadata: { serialNumber: x.displayName },
                                        }));
                                        formik.setValueOrEmptyArr(`devices`, devices, true);
                                    }}
                                />
                                <Grid container spacing={1}>
                                    {formik.values.devices.map((x, i) => {
                                        return (
                                            <Grid item className={classes.selectedTrd} key={x.id}>
                                                <LynxTypography
                                                    variant={"body-medium"}
                                                    className={classes.selectedTrdParagraph}
                                                >
                                                    {checkAlarmForDevice(x.id) && (
                                                        <LynxIcon
                                                            name="triangleWarning"
                                                            className={classes.triangleIcon}
                                                        />
                                                    )}
                                                    {x.metadata.serialNumber}
                                                    <LynxButton
                                                        variant="icon"
                                                        className={classes.selectedTrdIcon}
                                                        onClick={() => helpers.remove(i)}
                                                    >
                                                        <LynxIcon name="crossSmall" />
                                                    </LynxButton>
                                                </LynxTypography>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </>
                        )}
                    </Observer>
                )}
            />
            <LynxTypography variant="h3" color="neutral400" className={classes.temperatureDataSubtitle}>
                Temperature Data
            </LynxTypography>
            <Grid container justifyContent="space-between" xs={7}>
                <Grid item xs={4} className={classes.temperateDataGrid}>
                    <LynxTypography variant="body-s" color="neutral400">
                        Temperature Range
                    </LynxTypography>
                </Grid>
                <Grid item xs={3} className={classes.temperateDataGrid}>
                    <LynxTypography variant="body-s" color="neutral400">
                        Excursion Start Date&Time
                        {getIconTooltipForExcursionDates()}
                    </LynxTypography>
                </Grid>
                <Grid item xs={3} className={classes.temperateDataGrid}>
                    <LynxTypography variant="body-s" color="neutral400">
                        Excursion End Date&Time
                        {getIconTooltipForExcursionDates()}
                    </LynxTypography>
                </Grid>
                <Grid item xs={2} className={classes.temperateDataGrid}>
                    <LynxTypography variant="body-s" color="neutral400">
                        Total Duration
                    </LynxTypography>
                </Grid>
            </Grid>

            <Grid
                container
                justifyContent="space-between"
                xs={7}
                className={clsx(classes.temperatureDataRow, classes.borderBottom)}
            >
                <Grid item xs={4} className={classes.temperateDataGrid}>
                    <LynxTypography>
                        {formatTemperatureRange(
                            thorEventViewStore.calculatedExcursion.minTemperature,
                            thorEventViewStore.calculatedExcursion.maxTemperature
                        )}
                    </LynxTypography>
                </Grid>
                <Grid item xs={3} className={classes.temperateDataGrid}>
                    <LynxTypography>
                        {thorEventViewStore.calculatedExcursion.startedAt !== null
                            ? convertDateToSelectedTimezone(
                                  thorEventViewStore.calculatedExcursion.startedAt,
                                  currentTz
                              ).format(commonConstants.dateTimeFormat)
                            : commonConstants.emptyValue}
                    </LynxTypography>
                </Grid>
                <Grid item xs={3} className={classes.temperateDataGrid}>
                    <LynxTypography>
                        {thorEventViewStore.calculatedExcursion.endedAt !== null
                            ? convertDateToSelectedTimezone(
                                  thorEventViewStore.calculatedExcursion.endedAt,
                                  currentTz
                              ).format(commonConstants.dateTimeFormat)
                            : commonConstants.emptyValue}
                    </LynxTypography>
                </Grid>
                <Grid item xs={2} className={classes.temperateDataGrid}>
                    <LynxTypography>
                        {formatDurationToString(thorEventViewStore.calculatedExcursion.totalDuration)}
                    </LynxTypography>
                </Grid>
            </Grid>
        </>
    );
});

export default EditDevicesSection;
