import clsx from "clsx";
import React from "react";
import { statusBoxStyles } from "./statusBoxStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { StabilityFormStatus } from "models/productAndStabilityForm/productAndStabilityFormModels";

export interface StabilityFormStatusChipProps {
    status: StabilityFormStatus;
}

export const StabilityFormStatusChip = ({ status }: StabilityFormStatusChipProps) => {
    const classes = statusBoxStyles();
    const getText = (status: StabilityFormStatus) => {
        switch (status) {
            case StabilityFormStatus.Draft:
                return "Draft";
            case StabilityFormStatus.PendingApproval:
                return "Pending Approval";
            case StabilityFormStatus.Effective:
                return "Effective";
            case StabilityFormStatus.Deactivated:
                return "Deactivated";
        }
    };
    return (
        <div>
            <LynxTypography
                className={clsx(classes.statusPadding, classes.statusBox, {
                    [classes.sf_draft]: status === StabilityFormStatus.Draft,
                    [classes.sf_pendingApproval]: status === StabilityFormStatus.PendingApproval,
                    [classes.sf_effective]: status === StabilityFormStatus.Effective,
                    [classes.sf_deactivated]: status === StabilityFormStatus.Deactivated,
                })}
                variant="body-sbold-s-wide"
            >
                {getText(status)}
            </LynxTypography>
        </div>
    );
};
