import { Grid, MenuItem } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import { LynxDateTimePickerForm } from "components/ReusableForms/LynxDateTimePickerForm";
import LynxSelectForm from "components/ReusableForms/LynxSelectForm";
import { useFormikContext } from "formik";
import { commonConstants } from "lynxConstants";
import { observer } from "mobx-react";
import { EventType, ExcursionSource } from "models/thorEvents/eventModels";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { formikModels } from "validation";
import { SectionCard } from "../../ReusableComponents/Cards/SectionCard";
import { eventCreationStyles } from "./EventCreationStyles";
import { withFixed } from "helpers/withFixed";
import { FixedHeaderRenderer } from "components/ReusableComponents/FixedHeaderRenderer";

export interface HeaderSectionProps {
    isEventEditing: boolean;
}

export const HeaderSection = observer((props: HeaderSectionProps) => {
    const classes = eventCreationStyles();

    const navigate = useNavigate();
    const { thorEventViewStore } = useStore();
    const formik = useFormikContext<formikModels.EventFormikModel>();

    const navigateToEventDetailsPage = () => navigate(routes.eventDetails.to(formik.values.metadata.id));
    const navigateToEventListPage = () => navigate(routes.events);

    const changeEventType = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        formik.setFieldValue("type", event.target.value);
    };

    useEffect(() => {
        if (formik.dirty) {
            const excursionSource =
                formik.values.type === EventType.Transportation
                    ? ExcursionSource.TemperatureRecordingDevice
                    : ExcursionSource.Manual;

            formik.setFieldValue("excursionSource", excursionSource);
        }
    }, [formik.values.type]);

    const renderHeader = (isFixed: boolean) => {
        return (
            <Grid container className={withFixed(clsx(classes.header, classes.borderBottom), isFixed)}>
                <LynxTypography variant={isFixed ? "h2" : "h1"} component="h1">
                    {props.isEventEditing ? `Edit Event #${formik.values.metadata.displayId}` : "New Event"}
                </LynxTypography>
                <Grid item className={clsx(classes.displayFlex, classes.flexGapSmall)}>
                    {/* First submit button is fix to prevent submitting form by pressing "Enter" */}
                    <button
                        form="stabilityForm"
                        type="submit"
                        disabled
                        style={{ display: "none" }}
                        aria-hidden="true"
                    ></button>
                    <LynxButton
                        onClick={props.isEventEditing ? navigateToEventDetailsPage : navigateToEventListPage}
                        size="medium"
                        variant="tertiary"
                        disabled={thorEventViewStore.progressFlags.createEvent}
                    >
                        Cancel
                    </LynxButton>
                    <LynxButton
                        size="medium"
                        form="createEvent"
                        type="submit"
                        loading={thorEventViewStore.progressFlags.createEvent}
                    >
                        {props.isEventEditing ? "Save Changes" : "Create New Event"}
                    </LynxButton>
                </Grid>
            </Grid>
        );
    };

    return (
        <SectionCard>
            <FixedHeaderRenderer level="second" topMargin={-32}>
                {renderHeader(true)}
            </FixedHeaderRenderer>
            {renderHeader(false)}
            <Grid container item xs={10} spacing={3} alignItems="flex-end" className={clsx(classes.eventTypeSection)}>
                <Grid item xs={3}>
                    <LynxSelectForm
                        disabled={props.isEventEditing}
                        onChange={changeEventType}
                        name="type"
                        label={<LabelWithRequiredSymbol label="Event Type" />}
                        className={classes.select}
                    >
                        <MenuItem value={EventType.Transportation}>{"Transportation"}</MenuItem>
                        <MenuItem value={EventType.Site}>{"Site"}</MenuItem>
                    </LynxSelectForm>
                </Grid>
                <Grid item xs={3}>
                    <LynxDateTimePickerForm
                        timezone={commonConstants.UTCTimezone}
                        variant="date"
                        label="Quarantine Date"
                        format={commonConstants.shortDateFormat}
                        name="quarantineDate"
                        placeholder="Select date..."
                        isDateOnly
                        showToday={false}
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
});
