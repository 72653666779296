import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const filterButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            "& .MuiTypography-root": {
                color: theme.colors.neutrals.neutral600,
            },
        },

        buttonFilterOpened: {
            backgroundColor: theme.colors.primary.blue200,
        },

        listItem: {
            display: "flex",
            gap: "1rem",
            justifyContent: "space-between",

            "&.MuiMenuItem-root": {
                borderBottom: `1px solid ${theme.colors.primary.blue100}`,

                "&:last-child": {
                    borderBottom: "none",
                },

                "&:hover": {
                    backgroundColor: theme.colors.neutrals.white,
                },
            },
        },

        list: {
            marginTop: "0.5rem",
            boxShadow: "0px 0px 10px 0px #33333380;",

            "&.MuiMenu-list": {
                border: `1px solid ${theme.colors.primary.blue100}`,
                borderRadius: "0.25rem",
            },

            "&.MuiList-padding": {
                paddingTop: "0rem",
                paddingBottom: "0rem",
            },
        },

        checkbox: {
            marginRight: "0rem",
        },

        userSearchInputContainer: {
            padding: "0.5rem",
        },

        userSearchResultsContainer: {
            padding: "0rem",
        },

        searchResultRightSide: {
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
        },

        loadingIndicatorContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },

        loadingIndicator: {
            width: "10rem",
            height: "5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },

        userSearchLoadingIndicator: {
            width: "22rem",
            height: "15rem",
        },
    })
);
