import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const termsOfUseStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: "25rem",
        },

        textWrapper: {
            overflowY: "auto",
            height: "29.15rem",
            border: `1px solid ${theme.colors.neutrals.neutral200}`,
            borderRadius: theme.borderRadius,
            margin: "1rem 0rem",
            padding: "1rem",
        },

        button: {
            width: "100%",
            margin: "1rem 0rem",
        },

        titleText: {
            padding: "3rem 0rem 1rem 0rem",
        },

        descriptionText: {
            padding: "1rem 0rem",
        },

        divider: {
            backgroundColor: theme.colors.primary.blue100,
            marginTop: "0.5rem",
            marginBottom: "2rem",
        },

        headingPadding: {
            padding: "0.5rem 0",
        },

        justifyText: {
            textAlign: "justify",
        },

        definitionsList: {
            "& li": {
                padding: "0.25rem",
            },
            "& li::marker": {
                content: 'counter(list-item, lower-alpha) ") "',
            },
        },

        paragraphPadding: {
            padding: "0.5rem 0",
        },
    })
);
