import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const reportingStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.listPagePadding,
        },

        headerContainer: {
            "&.fixed": {
                padding: "1rem 1.5rem 0.5rem 1.5rem",
            },
        },

        fixedTable: {
            tableLayout: "fixed",
        },

        fixedTableContainer: {
            padding: "0rem 1.5rem",
        },

        title: {
            paddingRight: "1.5rem",
        },

        infoBadge: {
            alignSelf: "center",
            alignContent: "center",
            backgroundColor: theme.colors.primary.blue75,
            padding: "0 1rem",
            borderRadius: "0.25rem",
            width: "max-content",
            height: "2rem",
            gap: "0.25rem",
        },

        cellBorderBottom: {
            borderBottom: "none",
        },

        headerCell: {
            paddingTop: "0rem",
            paddingBottom: "0rem",

            "&.fixed": {
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
            },
        },

        headerRow: {
            verticalAlign: "bottom",
        },

        table: {
            borderCollapse: "separate",
            borderSpacing: "0 1rem",
            marginTop: "0.5rem",
        },

        tableRow: {
            boxShadow: theme.mainShadow,
            backgroundColor: "white",
            verticalAlign: "center",
        },

        actionButton: {
            width: "auto",
            float: "right",
            marginRight: "0.5rem",
        },
    })
);
