import { Box, Chip, ChipClassKey, ClickAwayListener, FormLabelProps } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { FieldHookConfig, useField } from "formik";
import { LynxIcon } from "icons/LynxIcon";
import { ChangeEvent, KeyboardEvent, MouseEvent, useEffect, useState } from "react";
import { LynxMultipleInputFormProps, MultipleInputValue } from "./Props/LynxMultipleInputFormProps";
import { reusableFormsStyles } from "./ReusableFormsStyles";

const LynxMultipleInputForm = (props: FieldHookConfig<string[]> & LynxMultipleInputFormProps) => {
    const [field, meta, helpers] = useField(props);
    const hasError = !!meta.error;
    const { onChange, value, ...fieldRest } = { ...field };
    const {
        size = "medium",
        validation,
        LabelProps,
        className,
        assistiveTextClassName,
        inputClassName,
        labelTypographyProps,
        ...rest
    } = { ...props };

    const classes = reusableFormsStyles({ size, error: hasError });

    const [values, setValues] = useState<MultipleInputValue[]>([]);
    const [currentValue, setCurrentValue] = useState("");
    const [renderedValuesCount, setRenderedValuesCount] = useState(0);
    const [isInputFocused, setIsInputFocused] = useState(false);

    useEffect(() => {
        if (values.length <= 0) {
            const mappedValues: MultipleInputValue[] = field.value.map((x) => {
                return { id: crypto.randomUUID(), value: x };
            });

            setValues(mappedValues);
        }
    }, [field.value]);

    const labelProps: FormLabelProps<"label", {}> = {
        id: `${field.name}-label`,
        ...LabelProps,
    };
    const boxChipClasses = clsx(classes.boxChip, props.boxChipClassName);
    const deleteIconClasses = clsx(classes.deleteIcon, props.deleteIconClassName);
    const hiddenValuesIconClasses = clsx(classes.hiddenValuesIcon, props.hiddenValuesIconClassName);
    const hiddenValuesTextClasses = clsx(props.hiddenValuesTextClassName);

    const inputRootClasses = clsx(classes.multipleInputRoot, classes.inputBackground, className);

    const inputClasses = clsx(
        field.value.length > renderedValuesCount ? classes.multipleInputBlur : classes.multipleInputFocus,
        inputClassName
    );

    const chipClasses: Partial<ClassNameMap<ChipClassKey>> = {
        root: clsx(classes.chipRoot, props.chipClasses?.root),
        label: clsx(classes.chipLabel, props.chipClasses?.label),
        ...props.chipClasses,
    };

    const assistiveTextClasses = clsx(assistiveTextClassName, !hasError && classes.assistiveTextHidden);

    const iconComponent = (
        <div className={deleteIconClasses}>
            <LynxIcon name="crossSmall" />
        </div>
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(e.currentTarget.value);

        !!meta.error && helpers.setError(undefined);
    };

    const handleDelete = (id: string) => {
        const data = [...values];

        const index = data.findIndex((v) => v.id === id);

        data.splice(index, 1);

        setValues(data);
        helpers.setValue(data.map((x) => x.value));

        !!meta.error && helpers.setError(undefined);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== "Enter" || currentValue.length <= 0) {
            return;
        }

        if (validation) {
            const { field, errorMessage = "Invalid value", isValid, setFieldError } = { ...validation };

            if (!isValid(currentValue)) {
                setFieldError(field, errorMessage);

                return;
            }
        }

        setValues([...values, { id: crypto.randomUUID(), value: currentValue }]);
        helpers.setValue([...field.value, currentValue]);
        setCurrentValue("");

        const incremented = renderedValuesCount + 1;
        setRenderedValuesCount(incremented);

        !!meta.error && helpers.setError(undefined);
    };

    const handleFocus = () => {
        if (isInputFocused) {
            return;
        }

        setRenderedValuesCount(values.length);
        setIsInputFocused(true);
    };

    const calculateRenderedValuesCount = (data: MultipleInputValue[]) => {
        // 34 - padding and other stuff that is not included in input width
        // 60 - min input width
        const { differenceBetweenLabelAndInputWidth = 34, minInputWidth = 60 } = { ...props.handleBlurValues };

        // I take label width because input width is 0 in css rules - it is done to place chip values
        // and current input on one line
        const labelWidth = document.getElementById(`${field.name}-label`)?.getBoundingClientRect().width ?? 0;

        const inputWidth = labelWidth - differenceBetweenLabelAndInputWidth;

        let renderedValuesCount = 0;
        let accumulativeWidth = 0;

        const comparedValue = inputWidth - minInputWidth;

        data.forEach((v) => {
            const valueWidth = document.getElementById(v.id.toString())?.getBoundingClientRect().width ?? 0;

            accumulativeWidth += valueWidth;

            if (accumulativeWidth > comparedValue) {
                return;
            }

            renderedValuesCount++;
        });

        return renderedValuesCount;
    };

    const handleBlur = (e: MouseEvent<Document>) => {
        // hot fix - to prevent calling this method when input is not focused or when clicking "Enter"
        if (!isInputFocused || e.detail === 0) {
            return;
        }

        const count = calculateRenderedValuesCount(values);

        setRenderedValuesCount(count);

        setIsInputFocused(false);
    };

    return (
        <ClickAwayListener onClickAway={handleBlur}>
            <div>
                <LynxInput
                    autoComplete="off"
                    assistiveTextClassName={assistiveTextClasses}
                    className={inputRootClasses}
                    inputClassName={inputClasses}
                    value={currentValue}
                    onClick={handleFocus}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    LabelProps={labelProps}
                    labelTypographyProps={labelTypographyProps}
                    error={hasError}
                    assistiveText={meta.error || "Error"}
                    {...fieldRest}
                    {...rest}
                    startAdornment={
                        <>
                            {values.map((v, i) => {
                                return (
                                    i < renderedValuesCount && (
                                        <Box id={v.id} key={v.id} className={boxChipClasses}>
                                            <Chip
                                                label={v.value}
                                                onDelete={() => handleDelete(v.id)}
                                                deleteIcon={iconComponent}
                                                classes={chipClasses}
                                            />
                                            <LynxTypography>,</LynxTypography>
                                        </Box>
                                    )
                                );
                            })}
                            {values.length > renderedValuesCount && (
                                <Box className={hiddenValuesIconClasses}>
                                    <LynxTypography
                                        color="white"
                                        variant="body-s"
                                        className={hiddenValuesTextClasses}
                                    >{`+${values.length - renderedValuesCount}`}</LynxTypography>
                                </Box>
                            )}
                        </>
                    }
                />
            </div>
        </ClickAwayListener>
    );
};

export default LynxMultipleInputForm;
