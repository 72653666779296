import { Button, Grid } from "@material-ui/core";
import clsx from "clsx";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import { useFormikContext } from "formik";
import { observer } from "mobx-react";
import { EventType, ExcursionSource } from "models/thorEvents/eventModels";
import { useEffect } from "react";
import { useStore } from "store/StoreConfigs";
import { formikModels } from "validation";
import EditDevicesSection from "./EditDevicesSection";
import EditManualExcursionsSection from "./EditManualExcursionsSection";
import { eventCreationStyles } from "./EventCreationStyles";

const EditExcursionInformationSection = observer(() => {
    const formik = useFormikContext<formikModels.EventFormikModel>();
    const { stabilityFormStore, identityStore } = useStore();
    const classes = eventCreationStyles();
    const siteEvent = formik.values.type === EventType.Site;
    const excursionSourceTRD = formik.values.excursionSource === ExcursionSource.TemperatureRecordingDevice;

    useEffect(() => {
        if (formik.values.excursionSource === ExcursionSource.Manual) {
            stabilityFormStore.loadStabilityRanges(
                identityStore.currentCustomer.id,
                formik.values.batches.map((x) => x.stabilityFormId)
            );
        }
    }, [formik.values.excursionSource, stabilityFormStore.stabilityRangesPerProductFlag]);

    useEffect(() => {
        return () => {
            stabilityFormStore.resetStabilityRangePerProduct();
        };
    }, []);

    return (
        <SectionCard>
            <LynxTypography variant="h2" className={clsx(siteEvent && classes.titleMargin)}>
                Excursion Information
            </LynxTypography>
            {!siteEvent && (
                <Grid container item xs={6} className={classes.titleMargin}>
                    <Grid item xs={6}>
                        <Button
                            disabled={siteEvent}
                            onClick={() =>
                                formik.setFieldValue("excursionSource", ExcursionSource.TemperatureRecordingDevice)
                            }
                            className={clsx(classes.deviceSectionButton, classes.leftButton, {
                                [classes.selectedButton]: excursionSourceTRD,
                            })}
                        >
                            <LynxTypography
                                variant={"body-medium"}
                                color={excursionSourceTRD ? "blue500" : "neutral600"}
                            >
                                Find TRDs
                            </LynxTypography>
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={() => formik.setFieldValue("excursionSource", ExcursionSource.Manual)}
                            className={clsx(classes.deviceSectionButton, classes.rightButton, {
                                [classes.selectedButton]: !excursionSourceTRD,
                            })}
                        >
                            <LynxTypography
                                variant={"body-medium"}
                                color={!excursionSourceTRD ? "blue500" : "neutral600"}
                            >
                                Enter Excursion Manually
                            </LynxTypography>
                        </Button>
                    </Grid>
                </Grid>
            )}

            {excursionSourceTRD ? <EditDevicesSection /> : <EditManualExcursionsSection />}
        </SectionCard>
    );
});

export default EditExcursionInformationSection;
