import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const lynxTypographyStyles = makeStyles((theme: Theme) =>
    createStyles({
        h1: {
            "&.MuiTypography-h1": {
                fontSize: "2.5rem",
                lineHeight: "3rem",
                fontFamily: "Gibson-Semibold",
            },
        },

        h2: {
            "&.MuiTypography-h2": {
                fontSize: "1.5rem",
                lineHeight: "2rem",
                fontFamily: "Gibson-Semibold",
            },
        },

        h3: {
            "&.MuiTypography-h3": {
                fontSize: "0.875rem",
                lineHeight: "1rem",
                fontFamily: "Gibson-Semibold",
                textTransform: "uppercase",
            },
        },

        body: {
            "&.MuiTypography-body1": { fontSize: "1rem", lineHeight: "1.5rem", fontFamily: "Gibson" },
        },

        "body-l": {
            "&.MuiTypography-body1": {
                fontSize: "1.125rem",
                lineHeight: "1.5rem",
                fontFamily: "Gibson-Medium",
            },
        },

        "body-sbold": {
            "&.MuiTypography-body1": {
                fontSize: "1rem",
                lineHeight: "1.5rem",
                fontFamily: "Gibson-Semibold",
            },
        },

        "body-medium": {
            "&.MuiTypography-body1": {
                fontSize: "1rem",
                lineHeight: "1.5rem",
                fontFamily: "Gibson-Medium",
            },
        },

        "body-s": {
            "&.MuiTypography-body1": {
                fontSize: "0.875rem",
                lineHeight: "1rem",
                fontFamily: "Gibson",
            },
        },

        "body-sbold-s": {
            "&.MuiTypography-body1": {
                fontSize: "0.875rem",
                lineHeight: "1rem",
                fontFamily: "Gibson-Semibold",
            },
        },

        "body-xs": {
            "&.MuiTypography-body1": {
                fontSize: "0.75rem",
                fontFamily: "Gibson",
                lineHeight: "1rem",
            },
        },

        "body-sbold-s-wide": {
            "&.MuiTypography-body1": {
                fontSize: "0.875rem",
                lineHeight: "1rem",
                fontFamily: "Gibson-Semibold",
                letterSpacing: "0.07rem",
                textTransform: "uppercase",
            },
        },
        "body-italic": {
            "&.MuiTypography-body1": {
                fontSize: "1rem",
                lineHeight: "1.5rem",
                fontFamily: "Gibson",
                fontStyle: "italic",
            },
        },

        "body-sbold-italic": {
            "&.MuiTypography-body1": {
                fontSize: "1rem",
                lineHeight: "1.5rem",
                fontFamily: "Gibson-Semibold",
                fontStyle: "italic",
            },
        },

        neutral200: {
            color: theme.colors.neutrals.neutral200,
        },
        neutral300: {
            color: theme.colors.neutrals.neutral300,
        },

        neutral400: {
            color: theme.colors.neutrals.neutral400,
        },

        neutral500: {
            color: theme.colors.neutrals.neutral500,
        },

        neutral600: {
            color: theme.colors.neutrals.neutral600,
        },

        blue500: {
            color: theme.colors.primary.blue500,
        },
        blue300: {
            color: theme.colors.primary.blue300,
        },
        white: {
            color: theme.colors.neutrals.white,
        },

        critical500: {
            color: theme.colors.critical.critical500,
        },

        critical600: {
            color: theme.colors.critical.critical600,
        },

        success500: {
            color: theme.colors.success.success500,
        },

        info600: {
            color: theme.colors.info.info600,
        },
    })
);
