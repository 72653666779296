import { FormControl, Grid, RadioGroup } from "@material-ui/core";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxRadio } from "components/LynxComponents/LynxRadio/LynxRadio";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { getPermissionKey } from "helpers/permissionHelpers";
import { pluralize } from "helpers/pluralize";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect, useState } from "react";
import { useStore } from "store/StoreConfigs";
import { promptTooltipMessages } from "lynxConstants";
import { eventReviewStyles } from "./EventReviewStyles";
import { EventReviewAuthModal } from "./EventReviewAuthModal";
import { AssessmentModel, YesNoType } from "models/thorEvents/eventModels";

export interface EventReviewProps {
    assessmentsCount: number;
}

export const EventReview = observer(({ assessmentsCount }: EventReviewProps) => {
    const { thorEventViewStore, permissionsStore, identityStore } = useStore();
    const event = thorEventViewStore.eventDetails;
    const classes = eventReviewStyles();

    const [isProductsWithCustomer, setIsProductsWithCustomer] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const eventReviewPermissionKey = getPermissionKey(actions.customer.tor.events.review, event.id);

    const eventReviewPermission = permissionsStore.getPermissionStatus(eventReviewPermissionKey);

    const isUserAssignedToEvent = event.reviewerUserId === identityStore.currentUser.id;
    const isPermissionAllowed = eventReviewPermission === MarvelPermissionStatus.Allow;
    const isLoading = thorEventViewStore.progressFlags.settingCompleteReview;
    const allProductsHaveStabilityForm = event.assessments.every((x) => x.stabilityFormId !== null);
    const allAssessmentsHaveReviewerDecision = thorEventViewStore.eventDetails.assessments.every(
        (x) => x.reviewerDecision !== null
    );

    const isButtonDisabled =
        !isPermissionAllowed ||
        !isProductsWithCustomer ||
        isLoading ||
        !allProductsHaveStabilityForm ||
        !allAssessmentsHaveReviewerDecision;
    const isRadioButtonDisabled =
        !isPermissionAllowed ||
        !isUserAssignedToEvent ||
        isLoading ||
        !allProductsHaveStabilityForm ||
        !allAssessmentsHaveReviewerDecision;

    const getTooltipMessage = (isForButton: boolean) => {
        switch (true) {
            case !isUserAssignedToEvent:
                return promptTooltipMessages.event.assignToYourself;
            case !isPermissionAllowed:
                return promptTooltipMessages.accessDenied;
            case isForButton && !isProductsWithCustomer:
                return promptTooltipMessages.event.productWithCustomer;
            default:
                return promptTooltipMessages.empty;
        }
    };

    const getAssessmentsWithFirstEconomicCustomerInfo = (assessments: AssessmentModel[]) => {
        let result = "";

        if (assessments.every((x) => x.isWithFirstEconomicCustomer)) {
            result = YesNoType.Yes;
        } else if (assessments.every((x) => x.isWithFirstEconomicCustomer === false)) {
            result = YesNoType.No;
        }

        return result;
    };

    useEffect(() => {
        setIsProductsWithCustomer(getAssessmentsWithFirstEconomicCustomerInfo(event.assessments));
    }, []);

    return (
        <>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <LynxTypography color="neutral500">
                        Are all of the products in this event with the customer? This response will apply to
                        {assessmentsCount > 1 && ` all ${assessmentsCount}`}
                        {pluralize(" assessment", assessmentsCount)} in this event.
                    </LynxTypography>

                    <PromptTooltip title={getTooltipMessage(false)} placement="bottom">
                        <FormControl component="fieldset" disabled={isRadioButtonDisabled}>
                            <RadioGroup
                                className={classes.radioGroup}
                                onChange={(e) => {
                                    setIsProductsWithCustomer(e.target.value as string);
                                }}
                                value={isProductsWithCustomer}
                            >
                                <LynxRadio value={YesNoType.Yes} label="Yes" />
                                <LynxRadio value={YesNoType.No} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </PromptTooltip>
                </Grid>
                <Grid item>
                    <PromptTooltip placement="top" title={getTooltipMessage(true)}>
                        <LynxButton
                            disableRipple={false}
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                            size="large"
                            loading={isLoading}
                            disabled={isButtonDisabled}
                        >
                            Complete Review
                        </LynxButton>
                    </PromptTooltip>
                </Grid>
            </Grid>
            <EventReviewAuthModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                isWithCustomer={isProductsWithCustomer === YesNoType.Yes}
                assessments={event.assessments}
            />
        </>
    );
});
