// Hack: import default styles for these components first to allow overriding them later

import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import { RestoreOriginalUriFunction } from "@okta/okta-react/bundles/types/OktaContext";
import TermsOfUse from "components/Copyright/TermsOfUse";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { RequiredAuth } from "components/SecureRoute/SecureRoute";
import Sso from "components/Sso/Sso";
import { config } from "config";
import { localStorageService } from "helpers/localStorageService";
import { ImportDefaultStyles } from "ImportDefaults";
import { LoginLayout } from "layout/LoginLayout";
import { MainLayout } from "layout/MainLayout";
import { commonConstants } from "lynxConstants";
import { observer } from "mobx-react";
import { LocalStorageStringKey } from "models/shared/LocalStorageKeyEnums";
import EnterEmailForResetPassword from "pages/Login/ForgotPassword/EnterEmailForResetPassword";
import PasswordResetForm from "pages/Login/ForgotPassword/PasswordResetForm";
import PasswordSuccessfullyReset from "pages/Login/ForgotPassword/PasswordSuccessfullyReset";
import RecoveryEmailSent from "pages/Login/ForgotPassword/RecoveryEmailSent";
import RecoveryTokenInvalid from "pages/Login/ForgotPassword/RecoveryTokenInvalid";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { Login } from "./pages/Login/Login";
import PrivacyNotice from "components/Copyright/PrivacyNotice";

const oktaAuth = new OktaAuth(config.oktaAuthOptions);

const FallbackToLogin = observer(() => {
    const navigate = useNavigate();
    const { commonStore } = useStore();

    useEffect(() => {
        commonStore.setLoginError("This user does not have permission to access Lynx FacTOR.");
        oktaAuth.closeSession();
        navigate("..");
    }, []);

    return null;
});

export const App = () => {
    const navigate = useNavigate();
    const currentCustomerId = localStorageService.getString(LocalStorageStringKey.CurrentCustomerId);
    const customerBasedUri = currentCustomerId === commonConstants.system ? "/userManagement" : "/events";

    const restoreOriginalUri: RestoreOriginalUriFunction = async (oktaAuth, originalUri) => {
        navigate(toRelativeUrl(originalUri || customerBasedUri, window.location.origin));
    };

    // redirects to the website when tried to be opened inside <iframe>
    useEffect(() => {
        if (!!window.top && window.top !== window.self) {
            window.top.location.href = window.self.location.href;
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>LYNX FacTOR</title>
            </Helmet>
            <ImportDefaultStyles />
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <Routes>
                    <Route
                        path="login/callback"
                        element={
                            <LoginCallback
                                errorComponent={FallbackToLogin}
                                loadingElement={<FullScreenLoadingIndicator />}
                            />
                        }
                    />
                    <Route element={<LoginLayout isLargeContent={false} />}>
                        <Route path={routes.login} element={<Login />} />
                        <Route path={routes.forgotPassword} element={<EnterEmailForResetPassword />} />
                        <Route path={routes.recoveryEmailed} element={<RecoveryEmailSent />} />
                        <Route path={routes.passwordSuccessfullyReset} element={<PasswordSuccessfullyReset />} />
                        <Route path={routes.recoveryTokenInvalid} element={<RecoveryTokenInvalid />} />
                    </Route>

                    <Route element={<LoginLayout isLargeContent={true} />}>
                        <Route path={routes.resetPassword} element={<PasswordResetForm />} />
                    </Route>

                    <Route path={routes.termsOfUse} element={<TermsOfUse />} />
                    <Route path={routes.privacyNotice} element={<PrivacyNotice />} />

                    <Route path="/sso" element={<Sso />} />
                    <Route path="*" element={<RequiredAuth />}>
                        <Route path="*" element={<MainLayout />} />
                    </Route>
                </Routes>
            </Security>
            <ToastContainer
                position="bottom-right"
                closeButton={false}
                closeOnClick={false}
                hideProgressBar
                autoClose={false}
                limit={6}
            />
        </>
    );
};
