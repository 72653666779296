import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const privacyNoticeStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: "25rem",
        },

        paper: {
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "55rem",
            marginTop: "3rem",
            padding: "2.5rem",
            borderRadius: "0",
            boxShadow: theme.mainShadow,
        },

        justifyText: {
            textAlign: "justify",
        },

        divider: {
            backgroundColor: theme.colors.primary.blue100,
            marginTop: "0.5rem",
            marginBottom: "2rem",
        },

        circleList: {
            marginTop: "0rem",
            marginBottom: "0rem",
            "& li": {
                padding: "0.2rem",
                listStyleType: "circle",
            },
        },
        discList: {
            marginTop: "0rem",
            marginBottom: "0rem",
            "& li": {
                padding: "0.2rem",
                listStyleType: "disc",
            },
        },
        lowerAlphaList: {
            marginTop: "0rem",
            marginBottom: "0rem",
            "& li": {
                padding: "0.25rem",
            },
            "& li::marker": {
                content: 'counter(list-item, lower-alpha) ") "',
            },
        },

        paragraphPadding: {
            padding: "0.5rem 0",
        },

        underlined: {
            textDecoration: "underline",
        },
    })
);
