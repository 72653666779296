import { LinkItem } from "models/commonModels";
import { DeviceModel, DuplicatedDeviceEvents } from "models/thorEvents/eventModels";
import routes from "routes";
import { eventCreationStyles } from "./EventCreationStyles";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { LynxIcon } from "icons/LynxIcon";

export const getUniqueSerialNumbers = (devices: DeviceModel[]): string => {
    const serialNumbersSet = new Set(devices.map((device) => `#${device.serialNumber}`));
    const uniqueSerialNumbers = Array.from(serialNumbersSet).join(", ");
    return `TRDs ${uniqueSerialNumbers} are used in the following Event(s):`;
};

export const convertToLinkItems = (events: DuplicatedDeviceEvents[]): LinkItem[] => {
    const result = events.map((event) => ({
        text: `${event.displayId} (delivery #${event.deliveryNumber})`,
        url: routes.eventDetails.to(event.id),
    }));

    return result;
};

export const getIconTooltipForExcursionDates = () => {
    const classes = eventCreationStyles();

    return (
        <PromptTooltip placement="top-start" title={"UTC by default unless timezone was changed for the event"}>
            <LynxIcon name="info" className={classes.icon} />
        </PromptTooltip>
    );
};
