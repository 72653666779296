import { ReactNode } from "react";

export enum BatchHoursReduced {
    Yes = 0,
    No = 1,
    NoDecisionYet = 2,
}

export interface LynxAssessmentCardProps {
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    action: string;
    actionDate: string | Date | null;
    infoBadgeText: ReactNode;
    children: ReactNode;
}
