import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const stabilityFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.resourcePagePadding,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
        },

        backButtonIconSpace: {
            marginRight: "0.5rem",
        },

        header: {
            padding: "0 0 0.5rem 0",
            alignItems: "center",
            borderBottom: `1px solid ${theme.colors.primary.blue100}`,
            minHeight: "60px",

            "&.fixed": {
                padding: "1rem 1.5rem 0.5rem 1.5rem",
                borderBottom: "none",
            },
        },

        headerText: {
            "&.fixed": {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },

        documentInfoSection: {
            padding: "2.5rem 0 0 0",
        },

        buttonPadding: {
            paddingRight: "0.5rem",
        },

        loadingIndicatorContainer: {
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },

        label: {
            marginBottom: "0.1875rem",
            marginRight: "0.5rem",

            "&.Mui-focused": {
                color: theme.colors.neutrals.neutral400,
            },
        },

        minWidthOperator: {
            minWidth: "4.375rem",
        },

        tableCellNoBorder: {
            border: "none",
        },

        // Assistive text
        assistiveTextHidden: {
            opacity: "0",
        },

        assistiveTextDisplayNone: {
            display: "none",
        },

        // Alignment
        verticalAlignmentBaseLine: {
            verticalAlign: "baseline !important",
        },

        verticalAlignTop: {
            verticalAlign: "top !important",
        },

        verticalAlignBottom: {
            verticalAlign: "bottom !important",
        },

        verticalAlignMiddle: {
            verticalAlign: "middle !important",
        },

        paddingTopMedium: {
            paddingTop: "1rem",
        },

        paddingRightZero: {
            paddingRight: "0 !important",
        },

        paddingRightSmall: {
            paddingRight: "0.5rem !important",
        },

        paddingRightMedium: {
            paddingRight: "1rem !important",
        },

        paddingRightLarge: {
            paddingRight: "2rem !important",
        },

        paddingLeftSmall: {
            paddingLeft: "0.5rem !important",
        },

        paddingBottomSmall: {
            paddingBottom: "0.5rem !important",
        },

        paddingBottomZero: {
            paddingBottom: "0 !important",
        },

        alignCenterText: {
            textAlign: "center",
        },

        deleteRegionButtonAlignment: {
            marginTop: "1.3rem",
        },

        marginBottomLarge: {
            marginBottom: "2rem",
        },

        marginBottomMedium: {
            marginBottom: "1rem",
        },

        marginBottomSmall: {
            marginBottom: "0.5rem",
        },

        marginTopNone: {
            marginTop: "0",
        },

        marginTopSmall: {
            marginTop: "0.5rem",
        },

        marginTopMedium: {
            marginTop: "1rem",
        },

        marginTopLarge: {
            marginTop: "2rem",
        },

        inputsGapMedium: {
            gap: "1.5rem",
        },

        inputsGapSmall: {
            gap: "0.5rem",
        },

        trashIconMargin: {
            marginTop: "0.3rem",
        },

        // Containers
        rangeSection: {
            border: `1px solid ${theme.colors.primary.blue100}`,
            marginBottom: "1rem",
            borderRadius: "4px",
            padding: "1.5rem",
        },

        heightFitContent: {
            height: "fit-content",
        },

        dividerBottom: {
            borderBottom: `1px solid ${theme.colors.primary.blue100}`,
        },

        tableContentRow: {
            "&.MuiTableRow-root": {
                verticalAlign: "top",

                "&:nth-child(odd)": {
                    backgroundColor: theme.colors.primary.blue75,
                },
            },
        },

        addFlowButtonContainer: {
            padding: "1rem 0",
            borderBottom: `1px solid ${theme.colors.primary.blue100}`,
            width: "inherit",
            marginBottom: "1.5rem",
        },

        regionSelectWidth: {
            width: "25%",
            minWidth: "20rem",
        },

        // Styles for history
        historyContent: {
            marginTop: "5rem",
        },

        historyActionSection: {
            padding: "1.25rem",
            boxShadow: theme.mainShadow,
        },

        historyLoadingIndicator: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },

        historyTitlesGrid: {
            margin: "1.875rem 0 0.625rem 0",
        },

        historyContentGrid: {
            marginBottom: "1.875rem",
        },

        textArea: {
            fontFamily: "Gibson-Semibold !important",
            lineHeight: "1.5rem !important",
        },

        tableHeaderCellFlow: {
            padding: "0 1rem !important",
        },

        displayFlex: {
            display: "flex",
        },

        buttonsAboveHeaderContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            "& > div": {
                display: "flex",
                gap: "1rem",
            },
        },

        widthFull: {
            width: "100%",
        },

        formStatus: {
            alignContent: "center",
            padding: "0 1rem",
        },

        secondSection: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
        },

        buttons: {
            display: "flex",
            justifyContent: "flex-end",
            gap: "0.5rem",
        },

        dates: {
            paddingBottom: "0.75rem",
            justifyContent: "flex-end",
            gap: "1rem",
        },

        infoContainer: {
            paddingTop: "2.5rem",
        },

        userInfo: {
            padding: "0.625rem 0rem",
        },
    })
);
