import { setLocale } from "yup";
import { validationErrorMessages } from "./constants";

setLocale({
    string: {
        max: (params) => validationErrorMessages.string.max(params.max),
        min: (params) => validationErrorMessages.string.min(params.min),
        matches: validationErrorMessages.string.matches,
        email: validationErrorMessages.string.email,
    },
    number: {
        min: (params) => validationErrorMessages.number.min(params.min),
        max: (params) => validationErrorMessages.number.max(params.max),
        moreThan: (params) => validationErrorMessages.number.moreThan(params.more),
        lessThan: (params) => validationErrorMessages.number.lessThan(params.less),
        integer: validationErrorMessages.number.integer,
    },
    mixed: {
        required: validationErrorMessages.general.required,
    },
});
