import { Grid } from "@material-ui/core";
import LynxTextarea from "components/LynxComponents/LynxTextarea/LynxTextarea";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import { stabilityFormStyles } from "components/StabilityForm/StabilityFormStyles";
import { StabilityFormStatusChip } from "components/ThorEvents/utilityComponents/StabilityFormStatus";
import { convertDateToSelectedTimezone } from "helpers/timezoneHelper";
import { fullDateTimeFormat, shortDateFormat, UTCTimezone } from "lynxConstants/commonConstants";
import { observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";
import { withFixed } from "helpers/withFixed";
import { FixedHeaderRenderer } from "components/ReusableComponents/FixedHeaderRenderer";
import { FormActionButtons } from "./FormActionButtons";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { FormAssignSection } from "./FormAssignSection";
import { StabilityFormStatus } from "models/productAndStabilityForm/productAndStabilityFormModels";

export const FormSection = observer(() => {
    const classes = stabilityFormStyles();
    const { stabilityFormStore } = useStore();

    if (!stabilityFormStore.stabilityFormDetails) {
        return null;
    }

    const stabilityForm = stabilityFormStore.stabilityFormDetails;
    const isFormEffective = stabilityForm.status === StabilityFormStatus.Effective;

    const renderHeader = (isFixed: boolean) => {
        return (
            <Grid container className={withFixed(classes.header, isFixed)}>
                <Grid item container xs={4} direction="row">
                    <Grid item container direction="column">
                        {!isFixed && (
                            <LynxTypography variant="body-s" color="neutral400">
                                Product Name
                            </LynxTypography>
                        )}
                    </Grid>
                    <Grid item>
                        <LynxTypography component="h1" variant={isFixed ? "h2" : "h1"}>
                            {stabilityForm.productName}
                        </LynxTypography>
                    </Grid>

                    <Grid item className={classes.formStatus}>
                        <StabilityFormStatusChip status={stabilityForm.status} />
                    </Grid>
                </Grid>

                <Grid item container xs={8} direction="column" className={classes.secondSection}>
                    {!isFixed && (
                        <Grid item container className={classes.dates}>
                            <Span variant="body-s" color="neutral400">
                                Form Created:&nbsp;
                                {convertDateToSelectedTimezone(stabilityForm.createdAt, UTCTimezone).format(
                                    fullDateTimeFormat
                                )}
                            </Span>

                            <Span variant="body-s" color="neutral400">
                                Created by: {stabilityForm.createdBy}
                            </Span>

                            {isFormEffective && (
                                <Span variant="body-s" color="neutral400">
                                    Form Approved:&nbsp;
                                    {convertDateToSelectedTimezone(stabilityForm.approvedAt, UTCTimezone).format(
                                        fullDateTimeFormat
                                    )}
                                </Span>
                            )}
                        </Grid>
                    )}
                    <Grid item className={classes.buttons}>
                        <FormActionButtons />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <SectionCard>
            <FixedHeaderRenderer level="second" topMargin={-24}>
                {renderHeader(true)}
            </FixedHeaderRenderer>
            {renderHeader(false)}
            <Grid container className={classes.infoContainer}>
                <Grid item xs={8}>
                    <Grid container>
                        <Grid item xs={4}>
                            <LynxTextarea readOnly={true} value={stabilityForm.number} label="Form Number" />
                        </Grid>

                        <Grid item xs={4}>
                            <LynxTextarea
                                readOnly={true}
                                value={stabilityForm.additionalInfo}
                                label="Additional Info"
                            />
                        </Grid>

                        <Grid container className={classes.paddingTopMedium}>
                            <Grid item xs={4}>
                                <LynxTextarea readOnly={true} value={stabilityForm.version} label="Version" />
                            </Grid>
                            {isFormEffective && (
                                <Grid item xs={4}>
                                    <LynxTextarea
                                        readOnly={true}
                                        value={dateToFormat(stabilityForm.effectiveDate, shortDateFormat)}
                                        label="Effective Date"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <FormAssignSection />
                </Grid>
            </Grid>
        </SectionCard>
    );
});
