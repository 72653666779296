import { Grid, Tooltip } from "@material-ui/core";
import InfoSubheader from "components/ReusableComponents/InfoSubheader";
import { formatTemperatureRange } from "helpers/rangeHelpers";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";
import { InfoRow, InfoRowItem } from "./eventLocationInfoHelpers";
import { eventLocationInfoStyles } from "./eventLocationInfoStyles";

const transportationTooltipContent =
    "Due to not having transportation requirements, product storage conditions were used for calculations.";

const transportationConditionsTooltip = () => {
    const classes = eventLocationInfoStyles();

    return (
        <Tooltip
            classes={{
                tooltip: classes.transportationTooltip,
                arrow: classes.transportationTooltipArrow,
            }}
            title={transportationTooltipContent}
            placement="bottom-start"
            arrow
        >
            <LynxIcon name="info" className={classes.icon} />
        </Tooltip>
    );
};

const EventTransportationInfo = observer(() => {
    const classes = eventLocationInfoStyles();
    const { thorEventViewStore } = useStore();
    const event = thorEventViewStore.eventDetails;

    const transportationRequirements = formatTemperatureRange(
        event.lowerLimit,
        event.upperLimit,
        event.lowerInclusive,
        event.upperInclusive,
        true
    );

    return (
        <Grid container spacing={4} className={classes.container}>
            <Grid item xs={12} className={classes.paddingBottomZero}>
                <InfoSubheader text="transportation information" icon={<LynxIcon name="transportation" />} />
            </Grid>
            <InfoRow>
                <InfoRowItem
                    label="Transportation Requirements"
                    value={transportationRequirements}
                    tooltip={event.hasTransportationConditions ? transportationConditionsTooltip() : undefined}
                />
                <InfoRowItem label="Lane ID" value={event.laneNumber} />
                <InfoRowItem label="Lane Status" value={event.laneStatus} />
            </InfoRow>

            <InfoRow>
                <InfoRowItem label="Origin Address" value={event.origin.addressLine1} />
                <InfoRowItem label="Origin Site ID" value={event.origin.code} />
                <InfoRowItem label="Origin Site Name" value={event.origin.name} />
            </InfoRow>

            <InfoRow>
                <InfoRowItem label="Destination Address" value={event.destination.addressLine1} />
                <InfoRowItem label="Destination Site ID" value={event.destination.code} />
                <InfoRowItem label="Destination Site Name" value={event.destination.name} />
            </InfoRow>

            <InfoRow>
                <InfoRowItem label="Shipper Type" value={event.shipperType} />
                <InfoRowItem label="Mode of Transport" value={event.transportationModeName} />
                <InfoRowItem label="Transportation Service Provider" value={event.transportationServiceProviderName} />
                <InfoRowItem label="Logistics Service Provider" value={event.logisticsServiceProviderName} />
            </InfoRow>
        </Grid>
    );
});

export default EventTransportationInfo;
