import { FormLabelProps, Grid } from "@material-ui/core";
import clsx from "clsx";
import { FieldHookConfig, useField } from "formik";
import { LynxIcon } from "icons/LynxIcon";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { LynxButton } from "../LynxButton/LynxButton";
import { LynxInput } from "../LynxInput/LynxInput";
import LynxTypography from "../LynxTypography/LynxTypography";
import { LynxInputWithTagsProps, MultipleInputValue } from "./LynxInputWithTagsFormProps";
import { LynxInputWithTagsStyles } from "./LynxInputWithTagsFormStyles";

export const LynxInputWithTagsForm = (props: FieldHookConfig<string[]> & LynxInputWithTagsProps) => {
    const [field, meta, helpers] = useField(props);
    const hasError = !!meta.error;
    const { onChange, value, ...fieldRest } = { ...field };
    const {
        size = "medium",
        LabelProps,
        className,
        assistiveTextClassName,
        inputClassName,
        labelTypographyProps,
        ...rest
    } = { ...props };

    const classes = LynxInputWithTagsStyles();

    const [values, setValues] = useState<MultipleInputValue[]>([]);
    const [currentValue, setCurrentValue] = useState("");

    useEffect(() => {
        if (Array.isArray(meta.initialValue)) {
            const mappedValues: MultipleInputValue[] = meta.initialValue.map((v) => ({
                id: crypto.randomUUID(),
                value: v,
            }));
            setValues(mappedValues);
        }
    }, [meta.initialValue]);

    const labelProps: FormLabelProps<"label", {}> = {
        id: `${field.name}-label`,
        ...LabelProps,
    };

    const inputRootClasses = clsx(classes.multipleInputRoot, classes.inputBackground);

    const assistiveTextClasses = clsx(assistiveTextClassName, !hasError && classes.assistiveTextHidden);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(e.currentTarget.value);

        !!meta.error && helpers.setError(undefined);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            let newValue = currentValue.trim();

            if (newValue.startsWith("0")) {
                newValue = newValue.replace(/^0+/, "");
            }

            if (newValue.length === 0) {
                setCurrentValue("");
                return;
            }

            const updatedValues = [...values, { id: crypto.randomUUID(), value: newValue }];

            setValues(updatedValues);
            helpers.setValue(updatedValues.map((item) => item.value));

            setCurrentValue("");
            if (meta.error) {
                helpers.setError(undefined);
            }
        }
    };

    const handleDelete = (id: string) => {
        const newValues = values.filter((item) => item.id !== id);

        setValues(newValues);

        helpers.setValue(newValues.map((item) => item.value));
    };

    const renderChips = () => {
        return (
            <Grid container alignItems="center">
                {values.map((x) => {
                    return (
                        <Grid item key={x.id}>
                            <div className={classes.chips}>
                                <LynxTypography variant={"body-medium"} className={classes.chipsDisplayName}>
                                    {x.value}
                                </LynxTypography>
                                <LynxButton
                                    variant="icon"
                                    className={classes.removeIcon}
                                    disabled={props.disabled}
                                    onClick={() => {
                                        handleDelete(x.id);
                                    }}
                                >
                                    <LynxIcon name="crossSmall" />
                                </LynxButton>
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    return (
        <div>
            <LynxInput
                autoComplete="off"
                assistiveTextClassName={assistiveTextClasses}
                className={inputRootClasses}
                value={currentValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                LabelProps={labelProps}
                labelTypographyProps={labelTypographyProps}
                error={hasError}
                assistiveText={meta.error || "Error"}
                {...fieldRest}
                {...rest}
            />
            {renderChips()}
        </div>
    );
};
