import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { observer } from "mobx-react";
import { assessmentDetailsStyles } from "../AssessmentDetailsStyles";
import { formatTemperatureRange } from "helpers/rangeHelpers";
import { formatDurationToString } from "helpers/formatDurationToString";
import { AssessmentDecision, BatchHistoryEntryModel, EventStatus, Flow } from "models/thorEvents/eventModels";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import { useStore } from "store/StoreConfigs";
import { getPermissionKey } from "helpers/permissionHelpers";
import { actions } from "models/userManagement/actions";
import clsx from "clsx";
import { BatchHistoryProps } from "./BatchHistoryProps";
import ThorPagination from "components/ReusableComponents/ThorPagination/ThorPagination";
import { LynxAvatarWithTooltip } from "components/ReusableComponents/LynxAvatar/LynxAvatarWithTooltip";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { AssessmentDetailsSection } from "../AssessmentDetailsSection";
import routes from "routes";
import { productDecisionsText, commonConstants } from "lynxConstants";
import { PaginationArea } from "models/shared/Page";
import { ReactNode } from "react";

export default observer(function BatchHistory({ batchHistory, flows }: BatchHistoryProps) {
    const { permissionsStore, identityStore, thorEventViewStore } = useStore();

    const classes = assessmentDetailsStyles();
    const tableHeaders = [
        "Event ID",
        "Flow",
        "Temp Range of Excursion",
        "Event Excursion Time",
        "Batch Remaining Stability Budget",
        "Can Product be used?",
        "With Customer?",
        "Batch Hours Reduced?",
        "Decision Made By",
    ];

    const renderDecisionColumn = (decision: AssessmentDecision | null) => {
        switch (decision) {
            case AssessmentDecision.NoImpactOnProductQuality:
                return (
                    <LynxTypography variant="body-medium" color="success500">
                        Yes
                    </LynxTypography>
                );
            case AssessmentDecision.ExistingImpactOnProductQuality:
                return (
                    <LynxTypography variant="body-medium" color="critical500">
                        No
                    </LynxTypography>
                );
            default:
                return <LynxTypography color="neutral300">{productDecisionsText.noDecision}</LynxTypography>;
        }
    };

    const renderIsWithCustomerColumn = (isWithCustomer: boolean | null) => {
        const text = isWithCustomer === null ? productDecisionsText.noDecision : isWithCustomer ? "Yes" : "No";
        return <LynxTypography>{text}</LynxTypography>;
    };

    const renderBatchHoursReducedColumn = (isWithCustomer: boolean | null, decision: AssessmentDecision | null) => {
        if (isWithCustomer || decision === AssessmentDecision.ExistingImpactOnProductQuality) {
            return <LynxTypography>No</LynxTypography>;
        } else if (decision === null || decision === undefined) {
            return <LynxTypography color="neutral300">{productDecisionsText.noDecision}</LynxTypography>;
        } else if (decision === AssessmentDecision.AdditionalReviewRequired) {
            throw new Error(`Final assessment decision should not be ${productDecisionsText.additionalReviewRequired}`);
        } else {
            return <LynxTypography>Yes</LynxTypography>;
        }
    };

    const viewEventDetailsPermissionKey = getPermissionKey(
        actions.customer.tor.events.viewDetails,
        identityStore.currentCustomer.id
    );

    const getFlowRegionAndSteps = (flow: Flow | undefined) => {
        if (flow) {
            return `${flow.region ?? "All Regions and Countries"}: ${
                flow.name ?? flow.steps ?? commonConstants.emptyValue
            }`;
        } else {
            return commonConstants.emptyValue;
        }
    };

    const renderDecisionMadeByColumn = (assessment: BatchHistoryEntryModel) => {
        return assessment.qaUserId ? (
            <Grid container wrap="nowrap" alignItems="center">
                <Grid item>
                    <LynxAvatarWithTooltip
                        email={assessment.qaUserId}
                        firstName={assessment.qaFirstName}
                        lastName={assessment.qaLastName}
                    />
                </Grid>
                <Grid item>
                    <LynxTypography>{`${assessment.qaFirstName} ${assessment.qaLastName} `}</LynxTypography>
                </Grid>
            </Grid>
        ) : (
            <LynxTypography color="neutral300">{productDecisionsText.noDecision}</LynxTypography>
        );
    };

    const renderIfEventClosed = (assessment: BatchHistoryEntryModel, content: ReactNode) => {
        return assessment.eventStatus === EventStatus.Closed ? (
            content
        ) : (
            <LynxTypography color="neutral300">{productDecisionsText.noDecision}</LynxTypography>
        );
    };

    return (
        <AssessmentDetailsSection title={batchHistory ? `Batch History (${batchHistory.length})` : "Batch History"}>
            {batchHistory !== undefined && batchHistory.length !== 0 ? (
                <>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.verticalAlignBottom}>
                                {tableHeaders.map((header) => (
                                    <TableCell key={header} className={classes.productInfoHeader}>
                                        <LynxTypography variant="body-s" color="neutral400">
                                            {header}
                                        </LynxTypography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {batchHistory.map((assessment) => (
                                <TableRow
                                    className={clsx(classes.historyTableRow, classes.verticalAlignTop)}
                                    key={assessment.eventId}
                                >
                                    <TableCell>
                                        <LynxRedirectLink
                                            target="_blank"
                                            to={routes.eventDetails.to(assessment.eventId)}
                                            disabled={!permissionsStore.hasPermission(viewEventDetailsPermissionKey)}
                                            loading={permissionsStore.permissionLoading(viewEventDetailsPermissionKey)}
                                        >
                                            {assessment.eventDisplayId}
                                        </LynxRedirectLink>
                                    </TableCell>
                                    <TableCell>
                                        <LynxTypography>
                                            {getFlowRegionAndSteps(flows.find((flow) => flow.id === assessment.flowId))}
                                        </LynxTypography>
                                    </TableCell>
                                    <TableCell className={classes.batchHistoryTempCell}>
                                        <LynxTypography>
                                            {assessment.impacts
                                                .slice()
                                                .sort((a, b) => a.position - b.position)
                                                .map((x) => (
                                                    <>
                                                        {formatTemperatureRange(
                                                            x.lowerLimit,
                                                            x.upperLimit,
                                                            x.lowerInclusive,
                                                            x.upperInclusive
                                                        )}
                                                        <br />
                                                    </>
                                                ))}
                                        </LynxTypography>
                                    </TableCell>
                                    <TableCell>
                                        <LynxTypography>
                                            {assessment.impacts
                                                .slice()
                                                .sort((a, b) => a.position - b.position)
                                                .map((x) => (
                                                    <>
                                                        {formatDurationToString(x.excursionDuration)}
                                                        <br />
                                                    </>
                                                ))}
                                        </LynxTypography>
                                    </TableCell>
                                    <TableCell>
                                        <LynxTypography>
                                            {assessment.impacts
                                                .slice()
                                                .sort((a, b) => a.position - b.position)
                                                .map((x) => (
                                                    <>
                                                        {formatDurationToString(
                                                            x.batchRsbAfter,
                                                            true,
                                                            x.withUnlimitedBudget
                                                        )}
                                                        <br />
                                                    </>
                                                ))}
                                        </LynxTypography>
                                    </TableCell>
                                    <TableCell>
                                        {renderIfEventClosed(assessment, renderDecisionColumn(assessment.decision))}
                                    </TableCell>
                                    <TableCell>
                                        {renderIfEventClosed(
                                            assessment,
                                            renderIsWithCustomerColumn(assessment.isWithFirstEconomicCustomer)
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {renderIfEventClosed(
                                            assessment,
                                            renderBatchHoursReducedColumn(
                                                assessment.isWithFirstEconomicCustomer,
                                                assessment.decision
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {renderIfEventClosed(assessment, renderDecisionMadeByColumn(assessment))}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <ThorPagination
                        page={thorEventViewStore.currentBatchHistoryPage}
                        pages={thorEventViewStore.totalBatchHistoryPages}
                        onPageChange={thorEventViewStore.moveToBatchHistoryPage}
                        isLastPage={thorEventViewStore.isLastBatchHistoryPage}
                        setPage={thorEventViewStore.setBatchHistoryPage}
                        area={PaginationArea.BatchHistory}
                        setPageSize={thorEventViewStore.setBatchHistoryPageSize}
                    />
                </>
            ) : (
                <LynxTypography color="neutral300">No batch history</LynxTypography>
            )}
        </AssessmentDetailsSection>
    );
});
