import { Grid } from "@material-ui/core";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { getPermissionKey } from "helpers/permissionHelpers";
import { promptTooltipMessages } from "lynxConstants";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { useStore } from "store/StoreConfigs";
import { SendEventBackForReviewAuthModal } from "./SendEventBackForReviewAuthModal";
import { useState } from "react";

export const SendEventBackForReview = observer(() => {
    const { thorEventViewStore, identityStore, permissionsStore } = useStore();
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

    const isLoading = thorEventViewStore.progressFlags.settingBackToReview;
    const event = thorEventViewStore.eventDetails;
    const isUserQA = identityStore.currentUser.id === event.qaUserId;
    const getBackToReviewPermissionKey = getPermissionKey(actions.customer.tor.events.sendBackForReview, event.id);
    const hasPermission = permissionsStore.hasPermission(getBackToReviewPermissionKey);
    const isButtonDisabled = isLoading || !hasPermission || !isUserQA;

    return (
        <>
            <SendEventBackForReviewAuthModal isModalOpen={isAuthModalOpen} setIsModalOpen={setIsAuthModalOpen} />

            <Grid container justifyContent="space-between">
                <Grid item>
                    <LynxTypography color="neutral500">
                        Review has been completed. In case of any issue, you can send this event back for review
                    </LynxTypography>
                </Grid>
                <Grid item>
                    <PromptTooltip
                        placement="top"
                        title={!hasPermission ? promptTooltipMessages.accessDenied : promptTooltipMessages.empty}
                    >
                        <LynxButton
                            size="large"
                            onClick={() => setIsAuthModalOpen(true)}
                            variant="secondary"
                            loading={isLoading}
                            disabled={isButtonDisabled}
                        >
                            Send Back for Review
                        </LynxButton>
                    </PromptTooltip>
                </Grid>
            </Grid>
        </>
    );
});
