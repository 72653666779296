import { Drawer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import LoadingIndicator from "components/ReusableComponents/LoadingIndicator/LoadingIndicator";
import ThorPagination from "components/ReusableComponents/ThorPagination/ThorPagination";
import { displayPopupNotification } from "helpers/displayPopupNotification";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { useEffect, useState } from "react";
import { useStore } from "store/StoreConfigs";
import { AddCustomerForm } from "../forms/AddCustomerForm";
import { EditCustomerForm } from "../forms/EditCustomerForm";
import UserActions, { UserActionOption } from "../UserActions";
import { userManagementStyles } from "./userManagementStyles";
import { CustomerListItem, CustomerStatus, MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { NotificationType } from "models/shared/NotificationType";
import { useNavigate } from "react-router";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";
import { PaginationArea } from "models/shared/Page";

export default observer(function CustomersTab() {
    const classes = userManagementStyles();
    const { customerStore, permissionsStore, commonStore } = useStore();

    const navigate = useNavigate();

    const [showAddCustomerForm, setShowAddCustomerForm] = useState(false);
    const [showEditCustomerForm, setShowEditCustomerForm] = useState(false);

    const editPermission = permissionsStore.getPermissionResult(actions.system.customers.manage);

    const viewPermission = permissionsStore.getPermissionResult(actions.system.customers.view);

    const addCustomerSuccessCallback = (customerName: string) => {
        setShowAddCustomerForm(false);
        const successNotification = `Customer '${customerName}' was created successfully`;
        displayPopupNotification(NotificationType.SUCCESS, successNotification);

        customerStore.clearPagination();
        customerStore.loadCustomers();
    };

    const editCustomerSuccessCallback = (customerName: string) => {
        setShowEditCustomerForm(false);

        const successNotification = `Customer '${customerName}' was edited successfully`;
        displayPopupNotification(NotificationType.SUCCESS, successNotification);
    };

    useEffect(() => {
        customerStore.loadCustomers();
    }, [customerStore.currentPage, customerStore.pageSize]);

    const getCustomerActions = (customer: CustomerListItem) => {
        const result: UserActionOption[] = [
            {
                optionName: "View / Edit Details",
                action: () => {
                    customerStore.loadCustomerDetails(customer.id);
                    setShowEditCustomerForm(true);
                },
            },
        ];

        if (editPermission.status === MarvelPermissionStatus.Allow) {
            result.push({
                optionName: customer.status === CustomerStatus.Active ? "Deactivate" : "Activate",
                action: () =>
                    customerStore.changeCustomerStatus(
                        customer.id,
                        customer.status === CustomerStatus.Active ? CustomerStatus.Deactivated : CustomerStatus.Active
                    ),
            });
        }

        if (viewPermission.status === MarvelPermissionStatus.Allow) {
            result.push({
                optionName: "Audit Trail",
                action: () => navigate(`/userManagement/customers/${customer.id}/audit`),
                withImmediateAction: true,
            });
        }

        return result;
    };

    return (
        <GeneralErrorComponentWrapper>
            <main className={classes.tabContainer}>
                <LynxButton
                    size="medium"
                    className={classes.addButton}
                    onClick={() => setShowAddCustomerForm(true)}
                    disabled={editPermission.status !== MarvelPermissionStatus.Allow}
                    loading={editPermission.status === MarvelPermissionStatus.Loading}
                >
                    Add Customer
                </LynxButton>

                {showAddCustomerForm && (
                    <Drawer
                        anchor="right"
                        open={showAddCustomerForm}
                        onClose={() => setShowAddCustomerForm(false)}
                        className={classes.drawer}
                    >
                        <AddCustomerForm successCallback={addCustomerSuccessCallback} />
                    </Drawer>
                )}

                {showEditCustomerForm && (
                    <Drawer
                        anchor="right"
                        className={classes.drawer}
                        open={showEditCustomerForm}
                        onClose={() => setShowEditCustomerForm(false)}
                    >
                        <EditCustomerForm successCallback={editCustomerSuccessCallback} />
                    </Drawer>
                )}

                {customerStore.progressFlags.loadingCustomers ? (
                    <LoadingIndicator className={classes.loadingIndicator} />
                ) : (
                    <>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow style={{ borderBottom: "none" }}>
                                    {/* <TableCell className={classes.headerCell}>Id</TableCell> */}
                                    <TableCell className={classes.headerCell}>Name</TableCell>
                                    <TableCell className={classes.headerCell}>Email</TableCell>
                                    <TableCell className={classes.headerCell}>Address</TableCell>
                                    <TableCell className={classes.headerCell}>Status</TableCell>
                                    <TableCell className={classes.headerCell} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {customerStore.customers.map((customer) => (
                                    <TableRow key={customer.id} className={classes.tableRow}>
                                        {/* <TableCell>
                                        <LynxTypography className={classes.bolderText}>{customer.id}</LynxTypography>
                                    </TableCell> */}
                                        <TableCell>
                                            <LynxTypography className={classes.bolderText}>
                                                {customer.name}
                                            </LynxTypography>
                                        </TableCell>
                                        <TableCell>
                                            <LynxTypography className={classes.tableText}>
                                                {customer.email}
                                            </LynxTypography>
                                        </TableCell>
                                        <TableCell>
                                            <LynxTypography className={classes.tableText}>
                                                {customer.address}
                                            </LynxTypography>
                                        </TableCell>
                                        <TableCell>
                                            <LynxTypography className={classes.tableText}>
                                                {customer.status}
                                            </LynxTypography>
                                        </TableCell>
                                        <TableCell>
                                            <UserActions options={getCustomerActions(customer)} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        <ThorPagination
                            page={customerStore.currentPage}
                            pages={customerStore.totalPages}
                            onPageChange={customerStore.movePage}
                            isLastPage={customerStore.isLastPage}
                            setPage={customerStore.setPage}
                            area={PaginationArea.Customers}
                            setPageSize={customerStore.setPageSize}
                        />
                    </>
                )}
            </main>
        </GeneralErrorComponentWrapper>
    );
});
