import { useOktaAuth } from "@okta/okta-react";
import { AuthModel } from "api/models/sharedModels/AuthModel";
import { StabilityFormAuthRequest } from "api/models/stabilityForms/stabilityFormsApi";
import LynxJustificationModal from "components/LynxComponents/LynxJustificationModal/LynxJustificationModal";
import { AuthModal } from "components/ReusableComponents/AuthModal/AuthModal";
import { stabilityFormApproveNewVersionAuthModalText } from "lynxConstants";
import { observer } from "mobx-react";
import { useState } from "react";
import { useStore } from "store/StoreConfigs";

interface StabilityFormActionAuthModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    actionRequest: (request: StabilityFormAuthRequest) => Promise<void>;
    loading: boolean;
    isApproveNewVersion?: boolean;
}

export const StabilityFormActionAuthModal = observer(
    ({
        actionRequest,
        isModalOpen,
        setIsModalOpen,
        loading,
        isApproveNewVersion,
    }: StabilityFormActionAuthModalProps) => {
        const { oktaAuth } = useOktaAuth();
        const { stabilityFormStore, identityStore } = useStore();

        const [justification, setJustification] = useState("");
        const [isShowReAuthenticate, setIsShowReAuthenticate] = useState(false);
        const [errorMessage, setErrorMessage] = useState("");
        const [numberOfRetries, setNumberOfRetries] = useState(2);

        const handleResponse = async (statusCode: number) => {
            switch (statusCode) {
                case 204:
                    setNumberOfRetries(2);
                    setIsShowReAuthenticate(false);
                    break;
                case 401:
                    setNumberOfRetries(numberOfRetries - 1);
                    setErrorMessage(numberOfRetries + " login attempt remaining, please try again or contact an admin");
                    if (numberOfRetries <= 0) {
                        setNumberOfRetries(2);
                        await oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` });
                    }
                    break;
                default:
                    setErrorMessage("Something went wrong. Please, try again");
                    break;
            }
        };

        const submitRequest = async (authModel: AuthModel) => {
            const request: StabilityFormAuthRequest = {
                justification: justification,
                stabilityFormId: stabilityFormStore.stabilityFormDetails!.id,
                callback: handleResponse,
                authModel: authModel,
                customerId: identityStore.currentCustomer.id,
            };

            await actionRequest(request);
        };

        return (
            <>
                <LynxJustificationModal
                    open={isModalOpen}
                    title="Confirm your actions by providing comments"
                    onClose={() => {
                        setIsModalOpen(false);
                    }}
                    onConfirm={() => {
                        setIsModalOpen(false);
                        setIsShowReAuthenticate(true);
                    }}
                    value={justification}
                    handleValueChange={setJustification}
                />
                <AuthModal
                    isOpen={isShowReAuthenticate}
                    onCancel={() => {
                        setIsShowReAuthenticate(false);
                    }}
                    onClose={() => {
                        setIsShowReAuthenticate(false);
                    }}
                    onSubmit={(authModel: AuthModel) => {
                        submitRequest(authModel);
                    }}
                    errorMessage={errorMessage}
                    loading={loading}
                    disabled={loading}
                    onBack={() => {
                        setIsShowReAuthenticate(false);
                        setIsModalOpen(true);
                    }}
                    additionalInfo={isApproveNewVersion ? stabilityFormApproveNewVersionAuthModalText : undefined}
                />
            </>
        );
    }
);
