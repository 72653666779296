import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { pluralize } from "helpers/pluralize";
import { observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";
import { AssessmentRow } from "./AssessmentRow/AssessmentRow";
import { assessmentStyles } from "./AssessmentStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { EventReview } from "./EventReview/EventReview";
import { AssessmentStatus, EventStatus } from "models/thorEvents/eventModels";
import { ReactNode } from "react";
import { SendEventBackForReview } from "./EventReview/SendEventBackForReview";

export const Assessment = observer(() => {
    const { thorEventViewStore } = useStore();
    const classes = assessmentStyles();
    const event = thorEventViewStore.eventDetails;
    const assessmentsCount = event.assessments.length;
    const isAnyAssessmentInStatusPendingReview = event.assessments.some(
        (x) => x.status === AssessmentStatus.PendingReview
    );
    const isEventInPendingApprovalStatus = event.status === EventStatus.PendingApproval;

    const tableColumnHeader = (name: ReactNode) => {
        return (
            <TableCell>
                <LynxTypography variant="h3" color="neutral400">
                    {name}
                </LynxTypography>
            </TableCell>
        );
    };

    return (
        <>
            <LynxTypography variant="h2" paragraph>
                {pluralize("Assessment", assessmentsCount)} ({assessmentsCount})
            </LynxTypography>
            <br />
            <Table>
                <TableHead>
                    <TableRow className={classes.assessmentHeaderRow}>
                        {tableColumnHeader("Product")}
                        {tableColumnHeader("Batch #")}
                        {tableColumnHeader("Temperature")}
                        {tableColumnHeader("Event Duration")}
                        {tableColumnHeader("Quantity")}
                        {tableColumnHeader("Automated Assessment")}
                        {tableColumnHeader("Final Decision")}
                        {tableColumnHeader("Status")}
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {thorEventViewStore.eventDetails.assessments.map((x) => (
                        <AssessmentRow assessment={x} key={x.id} />
                    ))}
                </TableBody>
            </Table>
            <div className={classes.container}>
                <LynxTypography variant="h3" color="neutral400" className={classes.sectionTitle}>
                    Event Review
                </LynxTypography>
                {isAnyAssessmentInStatusPendingReview && <EventReview assessmentsCount={assessmentsCount} />}
                {isEventInPendingApprovalStatus && <SendEventBackForReview />}
            </div>
        </>
    );
});
