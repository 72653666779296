import { Grid, MenuItem } from "@material-ui/core";
import clsx from "clsx";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import LynxSelectForm from "components/ReusableForms/LynxSelectForm";
import { useFormikContext } from "formik";
import { LowerOperator, UpperOperator } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { ChangeEvent } from "react";
import { stabilityFormStyles } from "../StabilityFormStyles";
import { RangeFieldsAreaProps } from "./Props/RangeFieldsAreaProps";

const RangeFieldsArea = <TFormikRootModel,>(props: RangeFieldsAreaProps) => {
    const classes = stabilityFormStyles();
    const formik = useFormikContext<TFormikRootModel>();
    const { fieldNamesPrefix, disableWholeArea = false } = {
        ...props,
    };

    const prefix = fieldNamesPrefix ? `${fieldNamesPrefix}.` : "";

    const [lowerProps, lowerMeta] = [
        formik.getFieldProps<string>(`${prefix}lowerLimit`),
        formik.getFieldMeta<string>(`${prefix}lowerLimit`),
    ];
    const [upperProps, upperMeta] = [
        formik.getFieldProps<string>(`${prefix}upperLimit`),
        formik.getFieldMeta<string>(`${prefix}upperLimit`),
    ];
    const [lowerInclusiveProps] = [
        formik.getFieldProps<boolean>(`${prefix}lowerInclusive`),
        formik.getFieldMeta<boolean>(`${prefix}lowerInclusive`),
    ];
    const [upperInclusiveProps] = [
        formik.getFieldProps<boolean>(`${prefix}upperInclusive`),
        formik.getFieldMeta<boolean>(`${prefix}upperInclusive`),
    ];
    const [lowerOperatorProps, lowerOperatorMeta] = [
        formik.getFieldProps<LowerOperator>(`${prefix}lowerOperator`),
        formik.getFieldMeta<LowerOperator>(`${prefix}lowerOperator`),
    ];
    const [upperOperatorProps, upperOperatorMeta] = [
        formik.getFieldProps<UpperOperator>(`${prefix}upperOperator`),
        formik.getFieldMeta<UpperOperator>(`${prefix}upperOperator`),
    ];

    const handleLowerOperatorChange = (
        e: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        formik.setFieldValue(lowerOperatorProps.name, e.target.value as LowerOperator);
        formik.setFieldValue(lowerInclusiveProps.name, e.target.value !== LowerOperator.GREATER_THAN);

        if (e.target.value === LowerOperator.INFINITY) {
            formik.setFieldValue(lowerProps.name, "");
        }
    };

    const handleUpperOperatorChange = (
        e: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        formik.setFieldValue(upperOperatorProps.name, e.target.value as UpperOperator);
        formik.setFieldValue(upperInclusiveProps.name, e.target.value !== UpperOperator.LESS_THAN);

        if (e.target.value === UpperOperator.INFINITY) {
            formik.setFieldValue(upperProps.name, "");
        }
    };

    return (
        <>
            <Grid container item xs={12} alignItems="center">
                <Grid container item xs={5} alignItems="center">
                    <Grid item xs={5} className={classes.paddingRightMedium}>
                        <LynxSelectForm
                            className={classes.widthFull}
                            disabled={disableWholeArea}
                            onChange={handleLowerOperatorChange}
                            leaveSpaceForContent={false}
                            name={lowerOperatorProps.name}
                        >
                            <MenuItem key={0} value={LowerOperator.GREATER_THAN}>
                                {">"}
                            </MenuItem>
                            <MenuItem key={1} value={LowerOperator.GREATER_THAN_OR_EQUAL}>
                                {">="}
                            </MenuItem>
                            <MenuItem
                                key={2}
                                value={LowerOperator.INFINITY}
                                disabled={upperOperatorMeta.value === UpperOperator.INFINITY}
                            >
                                {"∞"}
                            </MenuItem>
                        </LynxSelectForm>
                    </Grid>
                    <Grid item xs={6} className={classes.paddingRightSmall}>
                        <LynxInputForm
                            multiline
                            assistiveText=""
                            name={lowerProps.name}
                            error={false}
                            placeholder="Low"
                            disabled={disableWholeArea || lowerOperatorMeta.value === LowerOperator.INFINITY}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.alignCenterText}>
                        <LynxTypography variant="body-s">°C</LynxTypography>
                    </Grid>
                </Grid>
                <Grid item xs={1} className={classes.alignCenterText}>
                    <LynxTypography variant="body-s">to</LynxTypography>
                </Grid>
                <Grid item container xs={5} alignItems="center">
                    <Grid item xs={5} className={classes.paddingRightMedium}>
                        <LynxSelectForm
                            className={classes.widthFull}
                            disabled={disableWholeArea}
                            onChange={handleUpperOperatorChange}
                            leaveSpaceForContent={false}
                            name={upperOperatorProps.name}
                        >
                            <MenuItem key={0} value={UpperOperator.LESS_THAN}>
                                {"<"}
                            </MenuItem>
                            <MenuItem key={1} value={UpperOperator.LESS_THAN_OR_EQUAL}>
                                {"<="}
                            </MenuItem>
                            <MenuItem
                                key={2}
                                value={UpperOperator.INFINITY}
                                disabled={lowerOperatorMeta.value === LowerOperator.INFINITY}
                            >
                                {"∞"}
                            </MenuItem>
                        </LynxSelectForm>
                    </Grid>
                    <Grid item xs={6} className={classes.paddingRightSmall}>
                        <LynxInputForm
                            multiline
                            assistiveText=""
                            error={false}
                            placeholder="High"
                            disabled={disableWholeArea || upperOperatorMeta.value === UpperOperator.INFINITY}
                            name={upperProps.name}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.alignCenterText}>
                        <LynxTypography variant="body-s">°C</LynxTypography>
                    </Grid>
                </Grid>
            </Grid>

            {/* Assistive & Error Text */}
            <Grid container item xs={12}>
                <Grid container item xs={5}>
                    <Grid item xs={5} className={classes.paddingRightMedium}>
                        <LynxTypography variant="body-xs" color={"neutral400"}>
                            Min Temp
                        </LynxTypography>
                    </Grid>
                    <Grid item xs={7}>
                        <LynxTypography
                            variant="body-s"
                            color={"critical500"}
                            className={clsx(!lowerMeta.error && classes.assistiveTextHidden)}
                        >
                            {lowerMeta.error ?? "Error"}
                        </LynxTypography>
                    </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid container item xs={5}>
                    <Grid item xs={5} className={classes.paddingRightMedium}>
                        <LynxTypography variant="body-xs" color={"neutral400"}>
                            Max Temp
                        </LynxTypography>
                    </Grid>
                    <Grid item xs={5}>
                        <LynxTypography
                            variant="body-s"
                            color={"critical500"}
                            className={clsx(!upperMeta.error && classes.assistiveTextHidden)}
                        >
                            {upperMeta.error ?? "Error"}
                        </LynxTypography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default RangeFieldsArea;
