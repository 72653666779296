import { FormControl, FormLabel, IconButton, InputAdornment, InputBase } from "@material-ui/core";
import clsx from "clsx";
import { LynxIcon } from "icons/LynxIcon";
import { forwardRef, useState } from "react";
import LynxTypography from "../LynxTypography/LynxTypography";
import { LynxInputProps } from "./LynxInputProps";
import { lynxInputStyles } from "./LynxInputStyles";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";

export const LynxInput = forwardRef<HTMLInputElement, LynxInputProps>((props, ref) => {
    const {
        size = "medium",
        error = false,
        passwordInput = false,
        className,
        inputClassName,
        labelClassName,
        assistiveTextClassName,
        formControlClassName,
        assistiveText,
        LabelProps,
        FormControlProps,
        labelTypographyProps,
        endAdornment,
        type,
        required,
        ...rest
    } = props;

    const [showPassword, setShowPassword] = useState(false);

    const classes = lynxInputStyles({ size, error });

    const inputClasses = clsx(classes.input, inputClassName);
    const labelClasses = clsx(classes.label, labelClassName);
    const formControlClasses = clsx(classes.root, formControlClassName);

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const eyeIcon = (
        <InputAdornment position="end">
            <IconButton onClick={handleTogglePasswordVisibility} edge="end" className={classes.eyeIconButton}>
                {<LynxIcon name={showPassword ? "eye" : "eyeCrossed"} />}
            </IconButton>
        </InputAdornment>
    );

    // In numerical inputs, when you focus on the input and scroll with the mouse wheel, the entered number can increase or decrease (depending on the direction of scrolling)
    // To avoid unobvious entered data changes - we override this event.
    const handleOnWheel = (e: any) => {
        e.target.blur();
    };

    return (
        <FormControl className={formControlClasses} {...FormControlProps}>
            {props.label && (
                <FormLabel htmlFor={props.name} className={labelClasses} {...LabelProps}>
                    {required ? (
                        <LabelWithRequiredSymbol
                            label={props.label}
                            variant="body-s"
                            color="neutral400"
                            {...labelTypographyProps}
                        />
                    ) : (
                        <LynxTypography variant="body-s" color="neutral400" {...labelTypographyProps}>
                            {props.label}
                        </LynxTypography>
                    )}
                </FormLabel>
            )}
            <InputBase
                ref={ref}
                onWheel={props.type === "number" ? handleOnWheel : props.onWheel}
                // MUI 4 don`t have classes for readOnly prop. Need update to MUI 5.
                className={clsx(classes.inputBase, className, {
                    [classes.readOnly]: props.readOnly === true,
                })}
                error={error}
                type={passwordInput ? (showPassword ? "text" : "password") : type}
                classes={{
                    focused: classes.focused,
                    input: inputClasses,
                    error: classes.error,
                    disabled: classes.disabled,
                }}
                endAdornment={endAdornment ?? (passwordInput ? eyeIcon : null)}
                {...rest}
            />
            {props.assistiveText && (
                <LynxTypography
                    color={error ? "critical500" : "neutral400"}
                    variant="body-s"
                    className={assistiveTextClassName}
                >
                    {props.assistiveText}
                </LynxTypography>
            )}
        </FormControl>
    );
});
