import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { stabilityFormStyles } from "../StabilityFormStyles";
import { withFixed } from "helpers/withFixed";
import { FixedHeaderRenderer } from "components/ReusableComponents/FixedHeaderRenderer";
import { StabilityFormDetailsProps, StabilityFormMode } from "../StabilityFormDetails/StabilityFormDetails";

export const StabilityFormFieldsArea = observer(({ mode }: StabilityFormDetailsProps) => {
    const { stabilityFormId } = useParams();
    const { stabilityFormStore } = useStore();
    const classes = stabilityFormStyles();
    const isEditing = mode === StabilityFormMode.EDIT;
    const isNewVersion = mode === StabilityFormMode.NEW_VERSION;

    const navigate = useNavigate();

    const closeNavigate = () =>
        !isEditing && !isNewVersion
            ? navigate(routes.stabilityForms)
            : navigate(routes.stabilityFormDetails.to(stabilityFormId!));

    const isPendingVersion =
        stabilityFormStore.stabilityFormToUpdate !== null && stabilityFormStore.stabilityFormToUpdate.version !== 1;

    const renderHeader = (isFixed: boolean) => {
        return (
            <Grid container direction="row" className={withFixed(classes.header, isFixed)}>
                <Grid item xs={8}>
                    <LynxTypography variant={isFixed ? "h2" : "h1"} component="h1">
                        {isNewVersion
                            ? "New Version"
                            : isEditing
                            ? stabilityFormStore.stabilityFormToUpdate?.productName
                            : "New Stability Form"}
                    </LynxTypography>
                </Grid>
                <Grid item container xs={4} justifyContent="flex-end">
                    <Grid item>
                        {/* First submit button is fix to prevent submitting form by pressing "Enter" */}
                        <button
                            form="stabilityForm"
                            type="submit"
                            disabled
                            style={{ display: "none" }}
                            aria-hidden="true"
                        ></button>
                    </Grid>
                    <Grid item>
                        <LynxButton size="medium" variant="tertiary" onClick={() => closeNavigate()}>
                            Cancel
                        </LynxButton>
                        <LynxButton form="stabilityForm" type="submit" size="medium">
                            {!stabilityFormId ? "Save Draft" : "Save Changes"}
                        </LynxButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <SectionCard>
            <FixedHeaderRenderer level="second" topMargin={-32}>
                {renderHeader(true)}
            </FixedHeaderRenderer>
            {renderHeader(false)}
            <Grid container spacing={3} className={clsx(classes.documentInfoSection)}>
                <Grid item xs={3}>
                    <LynxInputForm
                        disabled={isNewVersion || isPendingVersion}
                        label={<LabelWithRequiredSymbol label="Form Number" />}
                        multiline
                        name="number"
                        placeholder="Enter Form Number"
                    />
                </Grid>
                <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="additionalInfo"
                        label="Additional info"
                        placeholder="Enter Additional info"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
});
