import {
    ResourceType,
    MarvelPermissionStatus,
    PermissionRequest,
    PermissionResult,
} from "models/userManagement/userManagementModels";

export const getPermissionKey = (action: string, prefix?: string) => {
    return prefix && prefix !== "system" ? `${prefix}-${action}` : action;
};

export const createPermissionRequest = (
    userId: string,
    permissionId: string,
    resourceType: ResourceType,
    resourceId?: string,
    invalidate?: boolean
): PermissionRequest => {
    const key: string = getPermissionKey(permissionId, resourceId);

    if (resourceType === ResourceType.None) {
        return { key, invalidate, body: { userId, permissionId } };
    } else if (resourceType === ResourceType.Customer) {
        return { key, invalidate, body: { userId, permissionId, data: { customerId: resourceId } } };
    } else if (resourceType === ResourceType.Event) {
        return { key, invalidate, body: { userId, permissionId, data: { eventId: resourceId } } };
    } else if (resourceType === ResourceType.StabilityForm) {
        return { key, invalidate, body: { userId, permissionId, data: { stabilityFormId: resourceId } } };
    } else {
        throw new Error("createPermissionRequest: Invalid resource type.");
    }
};

export const anyEquals = (
    base: PermissionResult[],
    toCheck: PermissionResult[] | "all",
    ...statuses: (MarvelPermissionStatus | undefined)[]
) => {
    const result = base.some(
        (x) => (toCheck === "all" || toCheck.map((x) => x.key).includes(x.key)) && statuses.includes(x.status)
    );
    return result;
};
