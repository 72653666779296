import { ApiValidationError } from "api/models/sharedModels/ApiValidationError";
import { AuthModel } from "api/models/sharedModels/AuthModel";
import { BaseResponse } from "api/models/sharedModels/ResponseModels";
import * as stabilityFormApi from "api/models/stabilityForms/stabilityFormsApi";
import { services } from "api/serviceConfig";
import { SearchParameter } from "components/LynxComponents/LynxSearch";
import dayjs from "dayjs";
import { FormikErrors } from "formik";
import { extractFileNameFromContentDisHeader, forceFileDownload } from "helpers/fileHelpers";
import { localStorageService } from "helpers/localStorageService";
import _ from "lodash";
import { makeAutoObservable } from "mobx";
import * as stabilityFormAndProductModels from "models/productAndStabilityForm/productAndStabilityFormModels";
import { PaginationArea } from "models/shared/Page";
import { formikModels } from "validation";
import CommonStore from "./CommonStore";
import { AssigneeStabilityFormUserType } from "models/userManagement/userManagementModels";
import { LynxFilterOption } from "components/ReusableComponents/ListPageHeader/LynxFilter";

interface ProgressFlags {
    loadingStabilityForms: boolean;
    loadingStabilityForm: boolean;
    loadingStabilityFormHistoryChanges: boolean;
    loadingReAuthentication: boolean;
    exportingPdf: boolean;
    loadingStabilityRangesPerProduct: boolean;
}

export class StabilityFormStore {
    constructor(commonStore: CommonStore) {
        makeAutoObservable(this);
        this.commonStore = commonStore;
    }

    commonStore: CommonStore;

    isAssignMenuOpen = false;

    // Stability Form Errors
    formErrors: FormikErrors<formikModels.StabilityFormFormikModel> = {};
    setErrors = (errors: FormikErrors<formikModels.StabilityFormFormikModel>) => (this.formErrors = errors);

    // Stability Forms
    stabilityForms: stabilityFormAndProductModels.StabilityFormListItem[] = [];

    stabilityFormDetails: stabilityFormApi.StabilityFormAllDataApiModel | null = null;

    stabilityFormToUpdate: stabilityFormApi.StabilityFormApiModelToUpdate | null = null;

    // Stability Form Changes History
    stabilityFormChangesHistory: stabilityFormAndProductModels.StabilityFormChangesHistory[] = [];
    currentHistoryPage = 1;
    totalHistoryPages = 1;
    pageHistorySize = localStorageService.getPageSize(PaginationArea.StabilityFormHistory) ?? 10;

    stabilityRangesPerProduct: stabilityFormApi.StabilityRangePerStabilityFormApiModel[] = [];
    resetStabilityRangePerProduct = () => (this.stabilityRangesPerProduct = []);

    stabilityRangesPerProductFlag = false;
    triggerStabilityRangesPerProductFlag = () =>
        (this.stabilityRangesPerProductFlag = !this.stabilityRangesPerProductFlag);

    get isLastHistoryPage() {
        return this.currentHistoryPage === this.totalHistoryPages;
    }

    setHistoryPage = (page: number) => {
        this.currentHistoryPage = page;
    };

    setHistoryPageSize = (pageSize: number) => {
        this.pageHistorySize = pageSize;
    };

    clearHistoryPagination = () => {
        this.stabilityFormChangesHistory = [];
        this.currentHistoryPage = 1;
        this.totalHistoryPages = 1;
    };

    moveToHistoryPage = (direction: "back" | "forward") => {
        direction === "back" ? this.currentHistoryPage-- : this.currentHistoryPage++;
    };

    // Pagination
    currentPage = 1;
    totalPages = 1;
    totalCount = 0;
    pageSize = localStorageService.getPageSize(PaginationArea.StabilityForms) ?? 10;

    get isLastPage() {
        return this.currentPage === this.totalPages;
    }

    setPage = (page: number) => {
        this.currentPage = page;
    };

    setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    };

    clearPagination = () => {
        this.stabilityForms = [];
        this.currentPage = 1;
        this.totalPages = 1;
    };

    moveToPage = (direction: "back" | "forward") => {
        direction === "back" ? this.currentPage-- : this.currentPage++;
    };

    // Filtering
    formAssignedSelectedFilters: LynxFilterOption[] = [];
    formStatusSelectedFilters: LynxFilterOption[] = [];

    searchValue: string = "";
    searchParameter: SearchParameter<"product"> = "ProductName";

    get searchInputTrimmed() {
        return this.searchValue.trim();
    }

    setSearchParameter = (value: SearchParameter<"product">) => {
        this.searchParameter = value;
    };

    setSearchValue = (value: string) => {
        this.searchValue = value;
    };

    setAssignedFilter = (value: LynxFilterOption[]) => {
        this.formAssignedSelectedFilters = value.filter((x) => x);
    };

    setFormStatusFilter = (value: LynxFilterOption[]) => {
        this.formStatusSelectedFilters = value.filter((x) => x);
    };

    resetAllFilters = () => {
        this.formAssignedSelectedFilters = [];
        this.formStatusSelectedFilters = [];
    };

    setAssignMenuOpen = (isOpen: boolean) => {
        this.isAssignMenuOpen = isOpen;
    };

    // Loading flags
    progressFlags = {
        loadingStabilityForms: false,
        loadingStabilityForm: false,
        loadingStabilityFormHistoryChanges: false,
        loadingReAuthentication: false,
        exportingPdf: false,
        loadingStabilityRangesPerProduct: false,
        sendingForApproval: false,
        sendingBackForEdit: false,
        approvingStabilityForm: false,
        assignUserToStabilityForm: false,
    };

    setProgressFlags = (value: Partial<ProgressFlags>) => {
        this.progressFlags = { ...this.progressFlags, ...value };
    };

    loadStabilityForms = async (request: stabilityFormApi.ListStabilityFormsRequest) => {
        this.progressFlags.loadingStabilityForms = true;

        try {
            const response = await services.StabilityForms.listStabilityForms(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.stabilityForms = response.data.items;
            this.totalPages = response.data.totalPages;
            this.totalCount = response.data.totalCount;

            if (this.currentPage > response.data.totalPages && response.data.totalPages !== 0) {
                this.currentPage = response.data.totalPages;
            }
        } finally {
            this.progressFlags.loadingStabilityForms = false;
        }
    };

    loadStabilityFormHistoryChanges = async (request: stabilityFormApi.GetStabilityFormChangesHistoryRequest) => {
        this.progressFlags.loadingStabilityFormHistoryChanges = true;

        try {
            const response = await services.StabilityForms.getStabilityFormChangesHistory(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.stabilityFormChangesHistory = response.data.items;
            this.totalHistoryPages = response.data.totalPages;

            if (this.currentHistoryPage > response.data.totalPages && response.data.totalPages !== 0) {
                this.currentHistoryPage = response.data.totalPages;
            }
        } finally {
            this.progressFlags.loadingStabilityFormHistoryChanges = false;
        }
    };

    createStabilityForm = async (
        customerId: string,
        model: stabilityFormApi.StabilityFormApiInputModel,
        authModel: AuthModel,
        justification: string,
        callback: (stabilityFormId: string, input: number, errors: ApiValidationError[]) => void
    ) => {
        let response;
        this.progressFlags.loadingReAuthentication = true;

        try {
            const request: stabilityFormApi.CreateStabilityFormRequest = {
                model,
                authModel,
                justification,
                customerId,
            };
            response = await services.StabilityForms.createStabilityForm(request);
        } finally {
            this.progressFlags.loadingReAuthentication = false;

            callback(
                response?.data?.id ?? "",
                response ? response.status : 0,
                (response?.data as unknown as BaseResponse)?.validationErrors ?? []
            );
        }
    };

    getStabilityFormDetails = async (customerId: string, stabilityFormId: string) => {
        this.progressFlags.loadingStabilityForm = true;

        try {
            const response = await services.StabilityForms.getStabilityFormDetails(customerId, stabilityFormId);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.stabilityFormDetails = response.data;
        } finally {
            this.progressFlags.loadingStabilityForm = false;
        }
    };

    getStabilityFormToUpdate = async (customerId: string, stabilityFormId: string) => {
        this.progressFlags.loadingStabilityForm = true;

        try {
            const response = await services.StabilityForms.getStabilityFormToUpdate(customerId, stabilityFormId);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.stabilityFormToUpdate = response.data;
        } finally {
            this.progressFlags.loadingStabilityForm = false;
        }
    };

    editStabilityForm = async (
        customerId: string,
        stabilityFormId: string,
        model: stabilityFormApi.StabilityFormApiInputModel,
        authModel: AuthModel,
        justification: string,
        callback: (stabilityFormId: string, input: number, errors: ApiValidationError[]) => void
    ) => {
        let response;
        this.progressFlags.loadingReAuthentication = true;

        try {
            const request: stabilityFormApi.UpdateStabilityFormRequest = {
                model,
                authModel,
                justification,
                customerId,
                stabilityFormId,
            };
            response = await services.StabilityForms.updateStabilityForm(request);
        } finally {
            this.progressFlags.loadingReAuthentication = false;

            callback(
                stabilityFormId,
                response ? response.status : 0,
                (response?.data as unknown as BaseResponse)?.validationErrors ?? []
            );
        }
    };

    loadStabilityRanges = async (customerId: string, stabilityFormIds: string[]) => {
        if (this.progressFlags.loadingStabilityRangesPerProduct) {
            return;
        }

        this.progressFlags.loadingStabilityRangesPerProduct = true;

        try {
            const normalized = _.uniq(stabilityFormIds.filter((x) => !!x));
            const difference = _.difference(
                normalized,
                this.stabilityRangesPerProduct.map((x) => x.stabilityFormId)
            );

            if (difference.length > 0) {
                const response = await services.StabilityForms.getStabilityRangesWithExtremes(customerId, difference);

                if (!_.inRange(response.status, 200, 300)) return;

                this.stabilityRangesPerProduct = [...this.stabilityRangesPerProduct, ...response.data];
            }
        } finally {
            this.progressFlags.loadingStabilityRangesPerProduct = false;
        }
    };

    sendStabilityFormForApproval = async (request: stabilityFormApi.StabilityFormAuthRequest) => {
        if (this.stabilityFormDetails === null) {
            throw Error("Stability form not set.");
        }

        this.progressFlags.sendingForApproval = true;
        let response;
        try {
            response = await services.StabilityForms.sendStabilityFormForApproval(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.stabilityFormDetails.status = stabilityFormAndProductModels.StabilityFormStatus.PendingApproval;
        } finally {
            request.callback(response ? response.status : 0);
            this.progressFlags.sendingForApproval = false;
            if (response?.status !== 401) {
                this.setAssignMenuOpen(true);
            }
        }
    };

    sendStabilityFormBackForEdit = async (request: stabilityFormApi.StabilityFormAuthRequest) => {
        if (this.stabilityFormDetails === null) {
            throw Error("Stability form not set.");
        }

        this.progressFlags.sendingBackForEdit = true;
        let response;
        try {
            response = await services.StabilityForms.sendStabilityFormBackForEdit(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.stabilityFormDetails.status = stabilityFormAndProductModels.StabilityFormStatus.Draft;
            this.stabilityFormDetails.approverEmail = null;
        } finally {
            request.callback(response ? response.status : 0);
            this.progressFlags.sendingBackForEdit = false;
        }
    };

    approveStabilityForm = async (request: stabilityFormApi.StabilityFormAuthRequest) => {
        if (this.stabilityFormDetails === null) {
            throw Error("Stability form not set.");
        }

        this.progressFlags.approvingStabilityForm = true;
        let response;
        try {
            response = await services.StabilityForms.approveStabilityForm(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.stabilityFormDetails.status = stabilityFormAndProductModels.StabilityFormStatus.Effective;
            this.stabilityFormDetails.effectiveDate = dayjs.utc().toDate();
            this.stabilityFormDetails.approvedAt = dayjs.utc().toDate();
        } finally {
            request.callback(response ? response.status : 0);
            this.progressFlags.approvingStabilityForm = false;
        }
    };

    createNewStabilityFormVersion = async (
        customerId: string,
        stabilityFormId: string,
        model: stabilityFormApi.StabilityFormApiInputModel,
        authModel: AuthModel,
        justification: string,
        callback: (stabilityFormId: string, input: number, errors: ApiValidationError[]) => void
    ) => {
        let response;
        this.progressFlags.loadingReAuthentication = true;

        try {
            const request: stabilityFormApi.CreateStabilityFormRequest = {
                model,
                authModel,
                justification,
                customerId,
            };
            response = await services.StabilityForms.createNewStabilityFormVersion(request, stabilityFormId);
        } finally {
            this.progressFlags.loadingReAuthentication = false;

            callback(
                response?.data?.id ?? "",
                response ? response.status : 0,
                (response?.data as unknown as BaseResponse)?.validationErrors ?? []
            );
        }
    };

    resetStabilityFormData = () => {
        this.stabilityFormDetails = null;
        this.stabilityFormToUpdate = null;
        this.formErrors = {};
    };

    generatePdf = async (customerId: string, stabilityFormId: string) => {
        const currentTimezone = dayjs.tz.guess();

        const request: stabilityFormApi.BaseStabilityFormRequest = {
            customerId,
            stabilityFormId,
            currentTimezone,
        };

        this.progressFlags.exportingPdf = true;

        try {
            const response = await services.StabilityForms.generatePdf(request);

            if (!_.inRange(response.status, 200, 300)) return;

            const fileName = extractFileNameFromContentDisHeader(response);

            forceFileDownload(fileName, response.data);
        } finally {
            this.progressFlags.exportingPdf = false;
        }
    };

    assignUserToStabilityForm = async (request: stabilityFormApi.AssignUserToStabilityFormRequest) => {
        if (this.stabilityFormDetails === null) {
            throw Error("Stability form not set.");
        }

        this.progressFlags.assignUserToStabilityForm = true;

        try {
            const response = await services.StabilityForms.assignUserToStabilityForm(request);

            if (!_.inRange(response.status, 200, 300)) return;

            if (request.assignUserType === AssigneeStabilityFormUserType.Owner) {
                this.stabilityFormDetails.ownerEmail = response.data.userId;
                this.stabilityFormDetails.ownerFirstName = response.data.firstName;
                this.stabilityFormDetails.ownerLastName = response.data.lastName;
            } else {
                this.stabilityFormDetails.approverEmail = response.data.userId;
                this.stabilityFormDetails.approverFirstName = response.data.firstName;
                this.stabilityFormDetails.approverLastName = response.data.lastName;
            }
        } finally {
            this.setAssignMenuOpen(false);
            this.progressFlags.assignUserToStabilityForm = false;
        }
    };
}
