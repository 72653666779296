import * as eventsApi from "api/models/events/eventsApi";
import { ApiValidationError } from "api/models/sharedModels/ApiValidationError";
import { AuthModel } from "api/models/sharedModels/AuthModel";
import { BaseResponse } from "api/models/sharedModels/ResponseModels";
import { services } from "api/serviceConfig";
import { AxiosResponse } from "axios";
import { SearchParameter } from "components/LynxComponents/LynxSearch";
import dayjs from "dayjs";
import { FormikErrors } from "formik";
import { extractFileNameFromContentDisHeader, forceFileDownload } from "helpers/fileHelpers";
import { getColorByIndex } from "helpers/getColorByIndex";
import { localStorageService } from "helpers/localStorageService";
import { dateInUTC } from "helpers/timezoneHelper";
import _ from "lodash";
import { commonConstants } from "lynxConstants";
import { makeAutoObservable } from "mobx";
import { PaginationArea } from "models/shared/Page";
import * as eventModel from "models/thorEvents/eventModels";
import * as usersModels from "models/userManagement/userManagementModels";
import { formikModelHelpers, formikModels } from "validation";
import CommonStore from "./CommonStore";
import IdentityStore from "./IdentityStore";
import { LynxFilterOption } from "components/ReusableComponents/ListPageHeader/LynxFilter";

export class ThorEventViewStore {
    constructor(identityStore: IdentityStore, commonStore: CommonStore) {
        makeAutoObservable(this);
        this.identityStore = identityStore;
        this.commonStore = commonStore;
        this.eventFormApiResponse = this.getBlankEventModelToUpdate();
    }

    identityStore: IdentityStore;
    commonStore: CommonStore;
    activeEvents: eventModel.EventListItem[] = [];
    currentEventPage = 1;
    totalEventPages = 1;
    totalCount = 0;
    eventPageSize = localStorageService.getPageSize(PaginationArea.Events) ?? 10;

    currentBatchHistoryPage = 1;
    totalBatchHistoryPages = 1;
    batchHistoryPageSize = localStorageService.getPageSize(PaginationArea.BatchHistory) ?? 10;

    // TODO: Separate sorting and filtering to other store?
    sortingDirection: "ASC" | "DESC" = "DESC";
    sortingValue: string = "DisplayId";

    dueDateSelectedFilters: LynxFilterOption[] = [];
    evaluationStatusSelectedFilters: LynxFilterOption[] = [];
    prioritySelectedFilters: LynxFilterOption[] = [];
    assignedUsersSelectedFilters: LynxFilterOption[] = [];
    eventCodeSelectedFilters: LynxFilterOption[] = [];

    // search
    searchValue: string = "";

    searchParameter: SearchParameter<"event"> = "DisplayId";

    get searchInputTrimmed() {
        return this.searchValue.trim();
    }

    setSearchParameter = (value: SearchParameter<"event">) => {
        this.searchParameter = value;
    };

    setSearchValue = (value: string) => {
        this.searchValue = value;
    };

    setDueDateFilter = (value: LynxFilterOption[]) => {
        this.dueDateSelectedFilters = value.filter((x) => x);
    };

    setEventCodeFilter = (value: LynxFilterOption[]) => {
        this.eventCodeSelectedFilters = value.filter((x) => x);
    };

    setEvaluationStatusFilter = (value: LynxFilterOption[]) => {
        this.evaluationStatusSelectedFilters = value.filter((x) => x);
    };

    setPriorityFilter = (value: LynxFilterOption[]) => {
        this.prioritySelectedFilters = value.filter((x) => x);
    };

    setAssignedUsersFilter = (value: LynxFilterOption[]) => {
        this.assignedUsersSelectedFilters = value.filter((x) => x);
    };

    resetAllFiltersAndSearch = () => {
        this.dueDateSelectedFilters = [];
        this.evaluationStatusSelectedFilters = [];
        this.prioritySelectedFilters = [];
        this.assignedUsersSelectedFilters = [];
        this.eventCodeSelectedFilters = [];
    };

    isAssignMenuOpen = false;

    isFlowChanged = false;

    eventDetails: eventModel.EventDetails = {
        id: "",
        displayId: 0,
        type: eventModel.EventType.Transportation,
        status: eventModel.EventStatus.New,
        processType: eventModel.EventProcessType.Commercial,
        excursionSource: eventModel.ExcursionSource.TemperatureRecordingDevice,
        createdAt: "",
        createdBy: "",
        closedAt: null,
        eventCodeIds: [],
        priorityId: null,
        timezone: commonConstants.UTCTimezone,
        dueDate: null,
        quarantineDate: null,
        reviewerUserId: null,
        reviewerFirstName: null,
        reviewerLastName: null,
        qaUserId: null,
        qaFirstName: null,
        qaLastName: null,
        minTemperature: 0,
        maxTemperature: 0,
        totalExcursionDuration: 0,
        totalLowExcursionDuration: null,
        totalHighExcursionDuration: null,
        conditioningRight: null,
        conditioningLeft: null,
        conditioningAppliedDt: null,
        conditioningAppliedBy: null,
        conditioningAppliedByFirstName: null,
        conditioningAppliedByLastName: null,
        conditioningNote: null,
        lowerLimit: null,
        lowerInclusive: true,
        upperLimit: null,
        upperInclusive: true,
        hasTransportationConditions: false,
        manualExcursionDeviceIds: null,
        excursionSegments: [],
        assessments: [],
        handlingUnits: [],
        openedPreviousEvents: [],
        deliveryNumber: null,
        orderNumbers: [],
        shippingDate: null,
        deliveryDate: null,
        origin: {
            id: null,
            code: null,
            name: null,
            addressLine1: null,
            addressLine2: null,
            city: null,
            country: null,
            postalCode: null,
            region: null,
            site: null,
            state: null,
        },
        destination: {
            id: null,
            code: null,
            name: null,
            addressLine1: null,
            addressLine2: null,
            city: null,
            country: null,
            postalCode: null,
            region: null,
            site: null,
            state: null,
        },
        site: {
            id: null,
            code: null,
            name: null,
            addressLine1: null,
            addressLine2: null,
            city: null,
            country: null,
            postalCode: null,
            region: null,
            site: null,
            state: null,
        },
        shipperType: null,
        transportationModeName: null,
        transportationServiceProviderName: null,
        logisticsServiceProviderName: null,
        laneNumber: null,
        laneStatus: null,
    };

    // Event Attachments
    attachments: eventsApi.EventAttachmentWithFullNameResponse[] = [];
    attachmentsTotalCount: number = 0;
    attachmentsFirstSetLoadingCount: number = 0;
    attachmentPageNumber = 1;

    isFileAttachModalOpen = false;
    isFileViewModalOpen = false;

    selectedDevice: string = "";

    devicesData: eventModel.DeviceData[] = [];

    eventFormApiResponse: eventsApi.EventModelToUpdate;

    eventFormikErrors: FormikErrors<formikModels.EventFormikModel> = {};
    setEventFormikErrors = (errors: FormikErrors<formikModels.EventFormikModel>) => (this.eventFormikErrors = errors);

    calculatedExcursion: eventModel.CalculatedExcursion = {
        minTemperature: null,
        maxTemperature: null,
        totalDuration: null,
        totalLowDuration: null,
        totalHighDuration: null,
        startedAt: null,
        endedAt: null,
    };

    setAssessmentReview = (model: eventsApi.CompleteAssessmentReviewModel) => {
        const assessment = this.eventDetails.assessments.find((x) => x.id === model.assessmentId)!;
        assessment.comments = model.comments;
        assessment.reviewerDecision = model.reviewerDecision;
    };

    devicesExcursion: Array<eventsApi.ExcursionResultPerDevice> = [];

    progressFlags = {
        loadingEvents: false,
        loadingEventShortInfo: false,
        loadingEventDetails: false,
        makingProductDecision: false,
        loadingBatchHistory: false,
        exportingPdf: false,
        loadingProductFlows: false,
        selectingProductFlow: false,
        settingPriority: false,
        settingCodes: false,
        settingTimezone: false,
        assigningUserToEvent: false,
        settingCompleteReview: false,
        settingBackToReview: false,
        settingQuantity: false,
        settingEventType: false,
        loadingDeviceData: false,
        settingDueDate: false,
        settingShippingDate: false,
        settingDeliveryDate: false,
        settingQuarantineDate: false,
        settingEventProcessType: false,
        isConditioningInProgress: false,
        isTransportationTemperatureChangeInProgress: false,
        loadingPriorities: false,
        loadingAttachments: false,
        uploadingAttachments: false,
        loadingNextSetOfAttachments: false,
        loadingEditEventInfo: false,
        editingEvent: false,
        createEvent: false,
        loadManualEventInformation: false,
        checkingForDuplicatedDevices: false,
    };

    get isLastEventPage() {
        return this.currentEventPage === this.totalEventPages;
    }

    setCalculatedExcursion = (excursion: eventModel.CalculatedExcursion) => {
        this.calculatedExcursion = excursion;
    };

    resetCalculatedExcursion = () => {
        this.calculatedExcursion.minTemperature = null;
        this.calculatedExcursion.maxTemperature = null;
        this.calculatedExcursion.startedAt = null;
        this.calculatedExcursion.endedAt = null;
        this.calculatedExcursion.totalDuration = null;
    };

    setEventPage = (page: number) => {
        this.currentEventPage = page;
    };

    setEventsPageSize = (pageSize: number) => {
        this.eventPageSize = pageSize;
    };

    moveToEventPage = (direction: "back" | "forward") => {
        direction === "back" ? this.currentEventPage-- : this.currentEventPage++;
    };

    clearEventPagination = () => {
        this.activeEvents = [];
        this.currentEventPage = 1;
        this.totalEventPages = 1;
    };

    get isLastBatchHistoryPage() {
        return this.currentBatchHistoryPage === this.totalBatchHistoryPages;
    }

    setBatchHistoryPage = (page: number) => {
        this.currentBatchHistoryPage = page;
    };

    setBatchHistoryPageSize = (pageSize: number) => {
        this.batchHistoryPageSize = pageSize;
    };

    moveToBatchHistoryPage = (direction: "back" | "forward") => {
        direction === "back" ? this.currentBatchHistoryPage-- : this.currentBatchHistoryPage++;
    };

    clearBatchHistoryPagination = () => {
        this.currentBatchHistoryPage = 1;
        this.totalBatchHistoryPages = 1;
    };

    selectedAssessment: string = "";

    setSelectedAssessment = (assessmentId: string) => {
        this.selectedAssessment = assessmentId;
    };

    setSortingDirection = (direction: "ASC" | "DESC") => {
        this.sortingDirection = direction;
    };

    setSortingValue = (sortingValue: string) => {
        this.sortingValue = sortingValue;
    };

    setSelectedDevice = (label: string) => {
        this.selectedDevice = label;
    };

    setFileAttachModalOpen = (open: boolean) => {
        this.isFileAttachModalOpen = open;
    };

    setFileViewModalOpen = (open: boolean) => {
        this.isFileViewModalOpen = open;
    };

    setAssignMenuOpen = (isOpen: boolean) => {
        this.isAssignMenuOpen = isOpen;
    };

    incrementAttachmentsFirstSetCount = () => {
        this.attachmentsFirstSetLoadingCount++;
    };

    setAttachmentPageNumber = (value: number) => {
        this.attachmentPageNumber = value;
    };

    setUploadingAttachmentsFlag = (value: boolean) => {
        this.progressFlags.uploadingAttachments = value;
    };

    setLoadingAttachmentsFlag = (value: boolean) => {
        this.progressFlags.loadingAttachments = value;
    };

    loadEvents = async (request: eventsApi.ListEventsRequest) => {
        this.progressFlags.loadingEvents = true;

        try {
            const response = await services.Events.listEvents(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.activeEvents = response.data.items;
            this.totalEventPages = response.data.totalPages;
            this.totalCount = response.data.totalCount;

            if (this.currentEventPage > response.data.totalPages && response.data.totalPages !== 0) {
                this.currentEventPage = response.data.totalPages;
            }
        } finally {
            this.progressFlags.loadingEvents = false;
        }
    };

    loadEventDetails = async (request: eventsApi.GetEventDetailsRequest) => {
        this.progressFlags.loadingEventDetails = true;

        try {
            const response = await services.Events.getEventDetails(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.eventDetails = response.data;
        } finally {
            this.progressFlags.loadingEventDetails = false;
        }
    };

    makeProductDecision = async (
        request: eventsApi.MakeProductDecisionRequest,
        assessment: eventModel.AssessmentModel
    ) => {
        let response;
        this.progressFlags.makingProductDecision = true;

        try {
            response = await services.Events.makeProductDecision(request);

            if (!_.inRange(response.status, 200, 300)) return;

            assessment.decision = request.assessmentDecision;
            assessment.qaUserId = this.identityStore.currentUser.id;
            assessment.qaFirstName = this.identityStore.currentUser.firstName;
            assessment.qaLastName = this.identityStore.currentUser.lastName;
            assessment.completedDate = dayjs().toDate();
            assessment.justification = request.justification;
            assessment.decision = request.assessmentDecision;
            assessment.status = eventModel.AssessmentStatus.EvaluationCompleted;

            if (
                this.eventDetails.assessments.every((x) => x.status === eventModel.AssessmentStatus.EvaluationCompleted)
            ) {
                this.eventDetails.closedAt = dayjs().toDate();
                this.eventDetails.status = eventModel.EventStatus.Closed;
            }

            this.commonStore.incrementCommentLoadingCountToForceReload();
        } finally {
            request.callback(response ? response.status : 0);
            this.progressFlags.makingProductDecision = false;
        }
    };

    generatePdf = async (customerId: string, eventId: string) => {
        const currentTimezone = dayjs.tz.guess();
        const eventTimezone = this.eventDetails.timezone;

        const request: eventsApi.GetPdfSummaryRequest = {
            customerId,
            eventId,
            currentTimezone,
            eventTimezone,
        };

        this.progressFlags.exportingPdf = true;

        try {
            const response = await services.Events.generatePdf(request);

            if (!_.inRange(response.status, 200, 300)) return;

            const fileName = extractFileNameFromContentDisHeader(response);

            forceFileDownload(fileName, response.data);
        } finally {
            this.progressFlags.exportingPdf = false;
        }
    };

    getProductFlows = async (request: eventsApi.GetProductFlowsRequest) => {
        this.progressFlags.loadingProductFlows = true;

        try {
            const response = await services.Events.getProductFlows(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.eventDetails.assessments
                .filter((x) => x.stabilityFormId === request.stabilityFormId)
                .forEach((x) => {
                    x.flows = response.data;
                });
        } finally {
            this.progressFlags.loadingProductFlows = false;
        }
    };

    setFlowForAssessment = async (request: eventsApi.SetFlowForAssessmentRequest) => {
        this.progressFlags.selectingProductFlow = true;

        try {
            const response = await services.Events.setFlowForAssessment(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.isFlowChanged = true;
            this.setSelectedAssessment("");
        } finally {
            this.progressFlags.selectingProductFlow = false;
            this.isFlowChanged = false;
        }
    };

    changeDeliveryTemperatureRange = async (request: eventsApi.ChangeDeliveryTemperatureRangeRequest) => {
        this.progressFlags.isTransportationTemperatureChangeInProgress = true;

        try {
            await services.Events.changeDeliveryTemperatureRange(request);
        } finally {
            this.progressFlags.isTransportationTemperatureChangeInProgress = false;
        }
    };

    setEventCodes = async (request: eventsApi.SetEventCodesRequest) => {
        this.progressFlags.settingCodes = true;

        try {
            const response = await services.Events.setEventCodes(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.eventCodeIds = request.eventCodesIds;
        } finally {
            this.progressFlags.settingCodes = false;
        }
    };

    setEventPriority = async (request: eventsApi.SetEventPriorityRequest) => {
        this.progressFlags.settingPriority = true;

        try {
            const response = await services.Events.setEventPriority(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.priorityId = request.priorityId;
            this.eventDetails.dueDate = response.data;
        } finally {
            this.progressFlags.settingPriority = false;
        }
    };

    setEventTimezone = async (request: eventsApi.SetEventTimezoneRequest) => {
        this.progressFlags.settingTimezone = true;

        try {
            const response = await services.Events.setEventTimezone(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.timezone = request.timezone;
        } finally {
            this.progressFlags.settingTimezone = false;
        }
    };

    assignUserToEvent = async (request: eventsApi.AssignUserToEventRequest) => {
        this.progressFlags.assigningUserToEvent = true;

        try {
            const response = await services.Events.assignUserToEvent(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventAssignHelper(this.eventDetails, response.data);

            this.commonStore.incrementCommentLoadingCountToForceReload();
        } finally {
            this.setAssignMenuOpen(false);
            this.progressFlags.assigningUserToEvent = false;
        }
    };

    completeEventReview = async (
        request: eventsApi.CompleteEventReviewRequest,
        assessments: eventModel.AssessmentModel[]
    ) => {
        this.progressFlags.settingCompleteReview = true;
        let response;
        try {
            response = await services.Events.completeEventReview(request, assessments);

            if (!_.inRange(response.status, 200, 300)) return;

            assessments.forEach((assessment) => {
                assessment.status = eventModel.AssessmentStatus.PendingDecision;
                assessment.isWithFirstEconomicCustomer = request.isWithCustomer;
                assessment.reviewerUserId = this.identityStore.currentUser.id;
                assessment.reviewerFirstName = this.identityStore.currentUser.firstName;
                assessment.reviewerLastName = this.identityStore.currentUser.lastName;
                assessment.reviewedDate = dayjs().toDate();
            });

            if (assessments.every((assessment) => assessment.status === eventModel.AssessmentStatus.PendingDecision)) {
                this.eventDetails.status = eventModel.EventStatus.PendingApproval;
            }
        } finally {
            request.callback(response ? response.status : 0);
            this.progressFlags.settingCompleteReview = false;
            if (response?.status !== 401) {
                this.setAssignMenuOpen(true);
            }
        }
    };

    sendBackForReview = async (request: eventsApi.SendEventBackForReviewRequest) => {
        this.progressFlags.settingBackToReview = true;

        try {
            const response = await services.Events.sendBackForReview(request);
            if (!_.inRange(response.status, 200, 300)) return;

            this.commonStore.incrementCommentLoadingCountToForceReload();

            const event = this.eventDetails;

            event.assessments.forEach((assessment) => {
                assessment.status = eventModel.AssessmentStatus.PendingReview;
                assessment.qaUserId = null;
                assessment.qaFirstName = null;
                assessment.qaLastName = null;
                assessment.justification = null;
                assessment.completedDate = null;
                assessment.decision = null;
            });

            event.status = eventModel.EventStatus.PendingReview;
            event.qaUserId = null;
            event.qaFirstName = null;
            event.qaLastName = null;
        } finally {
            this.progressFlags.settingBackToReview = false;
        }
    };

    getBatchHistory = async (request: eventsApi.GetBatchHistoryRequest) => {
        this.progressFlags.loadingBatchHistory = true;

        try {
            const response = await services.Events.getBatchHistory(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.eventDetails.assessments
                .filter((x) => x.batchId === request.batchId)
                .forEach((x) => {
                    x.batchHistory = response.data.items;
                });

            this.totalBatchHistoryPages = response.data.totalPages;

            if (this.currentBatchHistoryPage > response.data.totalPages && response.data.totalPages !== 0) {
                this.currentBatchHistoryPage = response.data.totalPages;
            }
        } finally {
            this.progressFlags.loadingBatchHistory = false;
        }
    };

    setEventType = async (request: eventsApi.SetEventTypeRequest) => {
        this.progressFlags.settingEventType = true;

        try {
            const response = await services.Events.setEventType(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.type = request.eventType;
        } finally {
            this.progressFlags.settingEventType = false;
        }
    };

    loadDeviceData = async (request: eventsApi.GetEventDeviceDataRequest) => {
        this.progressFlags.loadingDeviceData = true;

        try {
            const response = await services.Events.getEventDeviceData(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.devicesData = response.data;
            for (let index = 0; index < this.devicesData.length; index++) {
                this.devicesData[index].borderColor = getColorByIndex(index);
                this.devicesData[index].backgroundColor = getColorByIndex(index);
                this.devicesData[index].borderWidth = 2;
            }
        } finally {
            this.progressFlags.loadingDeviceData = false;
        }
    };

    setDueDate = async (request: eventsApi.SetDueDateRequest) => {
        this.progressFlags.settingDueDate = true;

        try {
            const response = await services.Events.setDueDate(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.dueDate = dateInUTC(request.date, true).toISOString();
        } finally {
            this.progressFlags.settingDueDate = false;
        }
    };

    setQuarantineDate = async (request: eventsApi.SetQuarantineDateRequest) => {
        this.progressFlags.settingQuarantineDate = true;

        try {
            const response = await services.Events.setQuarantineDate(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.quarantineDate = dateInUTC(request.date, true).toISOString();
        } finally {
            this.progressFlags.settingQuarantineDate = false;
        }
    };

    setShippingDate = async (request: eventsApi.SetShippingDateRequest) => {
        this.progressFlags.settingShippingDate = true;

        try {
            const response = await services.Events.setShippingDate(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.shippingDate = dateInUTC(request.date, true).toISOString();
        } finally {
            this.progressFlags.settingShippingDate = false;
        }
    };

    setDeliveryDate = async (request: eventsApi.SetDeliveryDateRequest) => {
        this.progressFlags.settingDeliveryDate = true;

        try {
            const response = await services.Events.setDeliveryDate(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.deliveryDate = dateInUTC(request.date, true).toISOString();
        } finally {
            this.progressFlags.settingDeliveryDate = false;
        }
    };

    setEventProcessType = async (request: eventsApi.SetEventProcessTypeRequest) => {
        this.progressFlags.settingEventProcessType = true;

        try {
            const response = await services.Events.setEventProcessType(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.processType = request.processType;
        } finally {
            this.progressFlags.settingEventProcessType = false;
        }
    };

    resetAllEvents = async (request: eventsApi.ResetAllEventsRequest) => {
        await services.Events.resetAllEvents(request);
        window.location.reload();
    };

    resetEvent = async (request: eventsApi.ResetEventRequest) => {
        await services.Events.resetEvent(request);
        window.location.reload();
    };

    conditionEvent = async (request: eventsApi.ConditionEventRequest) => {
        let response;
        this.progressFlags.isConditioningInProgress = true;

        try {
            response = await services.Events.conditionEvent(request);

            if (!_.inRange(response.status, 200, 300)) return;

            window.location.reload();
        } finally {
            request.callback(response ? response.status : 0);
            this.progressFlags.isConditioningInProgress = false;
        }
    };

    loadEventAttachments = async (request: eventsApi.GetEventAttachmentsRequest, isFirstLoad: boolean) => {
        if (this.progressFlags.loadingAttachments || this.progressFlags.loadingNextSetOfAttachments) {
            return;
        }

        isFirstLoad
            ? (this.progressFlags.loadingAttachments = true)
            : (this.progressFlags.loadingNextSetOfAttachments = true);

        try {
            const response = await services.Events.getEventAttachments(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.attachments = isFirstLoad ? response.data.items : _.concat(this.attachments, response.data.items);
            this.attachmentsTotalCount = response.data.totalCount;
        } finally {
            if (isFirstLoad) {
                this.progressFlags.loadingAttachments = false;
                this.attachmentPageNumber = 1;
            } else {
                this.progressFlags.loadingNextSetOfAttachments = false;
            }
        }
    };

    createManualEvent = async (
        customerId: string,
        authModel: AuthModel,
        justification: string,
        eventModel: formikModels.EventFormikModel,
        callback: (input: number, errors: ApiValidationError[]) => void
    ) => {
        if (this.progressFlags.createEvent) {
            return;
        }

        this.progressFlags.createEvent = true;
        let response: AxiosResponse<any> | null = null;

        const requestModel = formikModelHelpers.convertToEventInputModel(eventModel);

        try {
            const request: eventsApi.CreateEventRequest = {
                authModel,
                customerId,
                justification,
                model: requestModel,
            };

            response = await services.Events.createEvent(request);

            if (!_.inRange(response.status, 200, 300)) return;

            // Get id for redirect
            this.eventDetails.id = response.data;
        } finally {
            callback(
                response ? response.status : 0,
                (response?.data as unknown as BaseResponse)?.validationErrors ?? []
            );
            this.progressFlags.createEvent = false;
        }
    };

    getEventToUpdate = async (customerId: string, eventId: string) => {
        this.progressFlags.loadManualEventInformation = true;

        try {
            const response = await services.Events.getEventToUpdate({ customerId: customerId, eventId: eventId });

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.eventFormApiResponse = response.data;
            this.eventDetails.id = response.data.id;
            this.eventDetails.displayId = response.data.displayId;
        } finally {
            this.progressFlags.loadManualEventInformation = false;
        }
    };

    updateEvent = async (
        customerId: string,
        authModel: AuthModel,
        justification: string,
        eventModel: formikModels.EventFormikModel,
        eventId: string,
        callback: (input: number, errors: ApiValidationError[]) => void
    ) => {
        if (this.progressFlags.createEvent) {
            return;
        }

        this.progressFlags.createEvent = true;
        let response: AxiosResponse<any> | null = null;

        try {
            const requestModel = formikModelHelpers.convertToEventInputModel(eventModel);

            const request: eventsApi.UpdateEventRequest = {
                authModel,
                eventId,
                customerId,
                justification,
                model: requestModel,
            };

            response = await services.Events.updateEvent(request);

            if (!_.inRange(response.status, 200, 300)) return;

            // Get id for redirect
            this.eventDetails.id = response.data;
        } finally {
            callback(
                response ? response.status : 0,
                (response?.data as unknown as BaseResponse).validationErrors ?? []
            );
            this.progressFlags.createEvent = false;
        }
    };

    getDuplicatesDeviceEvents = async (
        customerId: string,
        eventId: string | undefined,
        devices: string[]
    ): Promise<eventsApi.DuplicatedDeviceEventsResponse[] | undefined> => {
        const requestModel = {
            customerId: customerId,
            eventId: eventId,
            deviceIds: devices,
        };

        this.progressFlags.checkingForDuplicatedDevices = true;
        try {
            const response = await services.Events.getlistEventsWithDuplicateDevices(requestModel);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            return response.data;
        } finally {
            this.progressFlags.checkingForDuplicatedDevices = false;
        }
    };

    // Private methods
    eventAssignHelper = (
        event: eventModel.EventDetails | eventModel.EventListItem,
        response: eventsApi.AssignedUserModel
    ) => {
        if (response.assigneeType === usersModels.AssigneeType.Reviewer) {
            event.reviewerUserId = response.userId;
            event.reviewerFirstName = response.firstName;
            event.reviewerLastName = response.lastName;
            event.status = eventModel.EventStatus.PendingReview;
        } else {
            event.qaUserId = response.userId;
            event.qaFirstName = response.firstName;
            event.qaLastName = response.lastName;
            event.status = eventModel.EventStatus.PendingApproval;
        }
    };

    resetEventFormApiResponse = () => {
        this.eventFormApiResponse = this.getBlankEventModelToUpdate();
    };

    getBlankEventModelToUpdate = (): eventsApi.EventModelToUpdate => ({
        id: "",
        displayId: 0,
        type: eventModel.EventType.Transportation,
        excursionSource: eventModel.ExcursionSource.TemperatureRecordingDevice,
        processType: eventModel.EventProcessType.Commercial,
        quarantineDate: null,
        timezone: commonConstants.UTCTimezone,
        deliveryInformation: {
            id: "",
            deliveryNumber: "",
            orderNumbers: [],
            laneNumber: "",
            shipperType: "",
            transportationModeId: "",
            transportationServiceProviderName: "",
            logisticsServiceProviderName: "",
            lowerLimit: 0,
            lowerInclusive: true,
            upperLimit: 0,
            upperInclusive: true,
            originId: "",
            originCode: "",
            originName: "",
            destinationId: "",
            destinationCode: "",
            destinationName: "",
        },
        site: {
            id: "",
            code: "",
            name: "",
        },
        batches: [],
        devices: [],
        manualExcursion: {
            lowTemperature: null,
            highTemperature: null,
            startDateTime: new Date(),
            highExcursion: 0,
            lowExcursion: 0,
            numberOfSpikes: null,
            deviceIds: null,
        },
        eventAttachments: [],
    });
}
