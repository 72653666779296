import clsx from "clsx";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { reusableFormsStyles } from "../ReusableFormsStyles";
import { LabelWithRequiredSymbolProps } from "./Props/LabelWithRequiredRequiredSymbolProps";

const asterisk = <>&nbsp;*</>;

const LabelWithRequiredSymbol = (props: LabelWithRequiredSymbolProps) => {
    const { variant = "body-s", color = "neutral400", ...rest } = props;
    const classes = reusableFormsStyles({ size: "medium" });

    const requiredSymbolClasses = clsx(classes.requiredSymbol, props.requiredSymbolClassName);

    return (
        <>
            <LynxTypography component="span" variant={variant} color={color}>
                {props.label}
            </LynxTypography>
            <LynxTypography variant="body-s" component="span" color="critical500" className={requiredSymbolClasses}>
                {asterisk}
            </LynxTypography>
        </>
    );
};

export default LabelWithRequiredSymbol;
