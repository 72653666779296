import { apiRoutes } from "api/apiRoutes";
import * as stabilityFormApi from "api/models/stabilityForms/stabilityFormsApi";
import { torAxios, torAxiosIgnoreErrors } from "api/serviceConfig";
import { AxiosRequestConfig } from "axios";
import {
    StabilityFormChangesHistory,
    StabilityFormListItem,
} from "models/productAndStabilityForm/productAndStabilityFormModels";
import { Page } from "models/shared/Page";

export class StabilityFormsService {
    getFormDownloadPresignedUrl = async (request: stabilityFormApi.BaseStabilityFormRequest) => {
        const url = apiRoutes.stabilityForms.getFormDownloadPresignedUrl(request.customerId, request.stabilityFormId);
        const result = await torAxios.get<string>(url);
        return result;
    };

    listStabilityForms = async (request: stabilityFormApi.ListStabilityFormsRequest) => {
        const url = apiRoutes.stabilityForms.listStabilityForms(request.customerId);

        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                assignees: request.assignees,
                formStatus: request.formStatus,
                searchValue: request.searchValue,
                searchParameter: request.searchParameter,
            },
        };

        const result = await torAxiosIgnoreErrors.get<Page<StabilityFormListItem>>(url, requestConfig);
        return result;
    };

    createStabilityForm = async (request: stabilityFormApi.CreateStabilityFormRequest) => {
        const url = apiRoutes.stabilityForms.createStabilityForm(request.customerId);
        const body = {
            ...request.model,
            justification: request.justification,
            authModel: request.authModel,
        };

        return await torAxios.post<stabilityFormApi.StabilityFormShortInfoApiModel>(url, body);
    };

    getStabilityFormDetails = async (customerId: string, stabilityFormId: string) => {
        const url = apiRoutes.stabilityForms.getStabilityFormDetails(customerId, stabilityFormId);

        return await torAxiosIgnoreErrors.get<stabilityFormApi.StabilityFormAllDataApiModel>(url);
    };

    getStabilityFormToUpdate = async (customerId: string, stabilityFormId: string) => {
        const url = apiRoutes.stabilityForms.getStabilityFormToUpdate(customerId, stabilityFormId);

        return await torAxiosIgnoreErrors.get<stabilityFormApi.StabilityFormApiModelToUpdate>(url);
    };

    updateStabilityForm = async (request: stabilityFormApi.UpdateStabilityFormRequest) => {
        const url = apiRoutes.stabilityForms.updateStabilityForm(request.customerId, request.stabilityFormId);
        const body = {
            ...request.model,
            justification: request.justification,
            authModel: request.authModel,
        };

        return await torAxios.put<stabilityFormApi.StabilityFormShortInfoApiModel>(url, body);
    };

    getStabilityFormChangesHistory = async (request: stabilityFormApi.GetStabilityFormChangesHistoryRequest) => {
        const url = apiRoutes.stabilityForms.getStabilityFormChangesHistory(
            request.customerId,
            request.stabilityFormId
        );

        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
            },
        };

        const result = await torAxiosIgnoreErrors.get<Page<StabilityFormChangesHistory>>(url, requestConfig);
        return result;
    };

    generatePdf = async (request: stabilityFormApi.BaseStabilityFormRequest) => {
        const url = apiRoutes.stabilityForms.generatePdf(request.customerId, request.stabilityFormId);

        const requestConfig: AxiosRequestConfig = {
            params: { currentTimezone: request.currentTimezone },
            responseType: "blob",
        };

        const result = await torAxios.get(url, requestConfig);

        return result;
    };

    getStabilityRangesWithExtremes = async (customerId: string, stabilityFormIds: string[]) => {
        const url = apiRoutes.stabilityForms.getStabilityRangesWithExtremes(customerId);

        const requestConfig: AxiosRequestConfig = {
            params: { stabilityFormIds: stabilityFormIds },
            paramsSerializer: {
                indexes: null,
            },
        };

        const result = await torAxios.get<stabilityFormApi.StabilityRangePerStabilityFormApiModel[]>(
            url,
            requestConfig
        );
        return result;
    };

    sendStabilityFormForApproval = async (request: stabilityFormApi.StabilityFormAuthRequest) => {
        const url = apiRoutes.stabilityForms.sendStabilityFormForApproval(request.customerId, request.stabilityFormId);

        const body = {
            justification: request.justification,
            authModel: request.authModel,
        };

        return await torAxios.patch(url, body);
    };

    sendStabilityFormBackForEdit = async (request: stabilityFormApi.StabilityFormAuthRequest) => {
        const url = apiRoutes.stabilityForms.sendStabilityFormBackForEdit(request.customerId, request.stabilityFormId);

        const body = {
            justification: request.justification,
            authModel: request.authModel,
        };

        return await torAxios.patch(url, body);
    };

    approveStabilityForm = async (request: stabilityFormApi.StabilityFormAuthRequest) => {
        const url = apiRoutes.stabilityForms.approveStabilityForm(request.customerId, request.stabilityFormId);

        const body = {
            justification: request.justification,
            authModel: request.authModel,
        };

        return await torAxios.patch(url, body);
    };

    createNewStabilityFormVersion = async (
        request: stabilityFormApi.CreateStabilityFormRequest,
        stabilityFormId: string
    ) => {
        const url = apiRoutes.stabilityForms.createNewStabilityFormVersion(request.customerId, stabilityFormId);
        const body = {
            ...request.model,
            justification: request.justification,
            authModel: request.authModel,
        };

        return await torAxios.post<stabilityFormApi.StabilityFormShortInfoApiModel>(url, body);
    };

    assignUserToStabilityForm = async (request: stabilityFormApi.AssignUserToStabilityFormRequest) => {
        const url = apiRoutes.stabilityForms.assignUserToStabilityForm(request.customerId, request.stabilityFormId);

        const body = {
            userId: request.userId,
            comment: request.comment,
            userType: request.assignUserType,
        };

        const result = await torAxios.patch<stabilityFormApi.StabilityFormAssignedUserModel>(url, body);
        return result;
    };
}
