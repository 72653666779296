import { lynxString, validationErrorMessages, YupSchema } from "validation";
import { object, ref } from "yup";

export enum ResetPasswordErrorType {
    MinLength = "min",
    AtLeastOneLowercase = "atLeastOneLowercase",
    AtLeastOneUppercase = "atLeastOneUppercase",
    AtLeastOneNumeric = "atLeastOneNumeric",
}

export const resetPasswordValidationSchema = (passwordMinLength: number) =>
    object<YupSchema<{ password: string; confirmPassword: string }>>({
        password: lynxString()
            .required()
            .min(passwordMinLength)
            .max(100)
            .matches(/(?=.*[a-z])/, {
                message: validationErrorMessages.passwordReset.atLeastOneLowercase,
                name: ResetPasswordErrorType.AtLeastOneLowercase,
            })
            .matches(/(?=.*[A-Z])/, {
                message: validationErrorMessages.passwordReset.atLeastOneUppercase,
                name: ResetPasswordErrorType.AtLeastOneUppercase,
            })
            .matches(/(?=.*?[0-9])/, {
                message: validationErrorMessages.passwordReset.atLeastOneNumeric,
                name: ResetPasswordErrorType.AtLeastOneNumeric,
            }),
        confirmPassword: lynxString()
            .required()
            .max(100)
            .oneOf([ref("password")], validationErrorMessages.passwordReset.mustMatch),
    });
