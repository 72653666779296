import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { termsOfUseStyles } from "./TermsOfUseStyles";
import { Grid } from "@material-ui/core";
import { ReactNode } from "react";

export const TermsOfUseContent = ({ baseOriginUri }: { baseOriginUri: string }) => {
    const classes = termsOfUseStyles();

    const renderParagraph = (paragraphNumber: string, otherText: ReactNode) => (
        <Grid container item direction="row" className={classes.paragraphPadding}>
            <Grid item xs={1}>
                {paragraphNumber}
            </Grid>
            <Grid item xs={11}>
                {otherText}
            </Grid>
        </Grid>
    );

    return (
        <Grid className={classes.justifyText}>
            <LynxTypography>
                These Lynx FacTOR Terms and Conditions of Use (&quot;
                <LynxTypography variant="body-sbold" component="span">
                    Terms
                </LynxTypography>
                &quot;), together with the Privacy Notice, permit Permitted Users to use the Service (as defined below)
                and set forth the basic terms and conditions under which the Service will be delivered and performed.
            </LynxTypography>
            <LynxTypography variant="h2" className={classes.headingPadding}>
                1 DEFINITIONS
            </LynxTypography>
            <LynxTypography>
                Capitalized words used herein and not otherwise defined shall have the meanings ascribed to them in
                these Terms and Conditions.
            </LynxTypography>
            <LynxTypography>
                <ol className={classes.definitionsList}>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Agreement
                        </LynxTypography>
                        &quot; means the Master SaaS Subscription Agreement between Customer and Sensitech Inc. (&quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Sensitech
                        </LynxTypography>
                        &quot;), an affiliate of Carrier Corporation (&quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Carrier
                        </LynxTypography>
                        &quot;).
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Customer
                        </LynxTypography>
                        &quot; means the legal entity that has entered into an Order Form to use the Service subject to
                        the Agreement.
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Customer Data
                        </LynxTypography>
                        &quot; means any data of any type that is submitted to the Service by or on behalf of Customer,
                        including but not limited to data submitted, uploaded, or imported to the Service by Customer or
                        Permitted Users (including from Third Party Platforms).
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Data Privacy Laws
                        </LynxTypography>
                        &quot; means applicable national, federal, state and provincial laws relating to data privacy,
                        the protection of personal information or data, and the cross-border transfer of personal
                        information or data, including, without limitation, the laws and regulations of the European
                        Union member states under the General Data Protection Regulation (&quot;
                        <LynxTypography variant="body-sbold" component="span">
                            GDPR
                        </LynxTypography>
                        &quot;) and any European Union law or regulation that may be enacted to implement or replace the
                        GDPR.
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Documentation
                        </LynxTypography>
                        &quot; means the technical user documentation provided with the Service.
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Order Form
                        </LynxTypography>
                        &quot; means a mutually accepted, formal transaction document that includes some or all of the
                        following information: Customer name, Customer’s address and billing information, the
                        subscription period, the fees due from Customer, and any maintenance and support being purchased
                        (if applicable).
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Permitted User
                        </LynxTypography>
                        &quot; means an employee or contractor who is authorized to access the Service.
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Permitted User Data
                        </LynxTypography>
                        &quot; means any data of any type that is submitted, uploaded, or imported to the Service by
                        Permitted Users (including from Third Party Platforms).
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Personal Data
                        </LynxTypography>
                        &quot; means any information relating to an identified or identifiable natural person (&quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Data Subject
                        </LynxTypography>
                        &quot;); an identifiable natural person is one who can be identified, directly or indirectly, in
                        particular by reference to an identifier such as a name, an identification number, location
                        data, an online identifier or to one or more factors specific to the physical, physiological,
                        genetic, mental, economic, cultural or social identity of that natural person.
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Privacy Notice
                        </LynxTypography>
                        &quot; means the Lynx FacTOR Privacy and Data Usage Notice as published on&nbsp;
                        <a href={baseOriginUri}>{baseOriginUri}</a> and as may be amended from time to time by Carrier.
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Sensitive Personal Data
                        </LynxTypography>
                        &quot; means any of the following: (i) credit, debit or other payment card data subject to the
                        Payment Card Industry Data Security Standards (&quot;
                        <LynxTypography variant="body-sbold" component="span">
                            PCI DSS
                        </LynxTypography>
                        &quot;); (ii) patient, medical or other protected health information regulated by the Health
                        Insurance Portability and Accountability Act (&quot;
                        <LynxTypography variant="body-sbold" component="span">
                            HIPAA
                        </LynxTypography>
                        &quot;); or (iii) any other Personal Data of a Data Subject deemed to be in a
                        &quot;special&quot; or similar category as identified in applicable Data Privacy Laws.
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Service
                        </LynxTypography>
                        &quot; means Lynx FacTOR delivered to the Customer as a web-based digital user interface, or as
                        otherwise described in the Order Form.
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Service Level
                        </LynxTypography>
                        &quot; means the quantitative performance standards for the Service, as further specified in the
                        Service Level Agreement.
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Service Level Agreement
                        </LynxTypography>
                        &quot; means the service level agreement applicable to the Service as published by Carrier
                        on&nbsp;
                        <a href={baseOriginUri}> {baseOriginUri}</a> and as may be amended from time to time by Carrier.
                    </li>
                    <li>
                        &quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Third Party Platforms
                        </LynxTypography>
                        &quot; means any software, software-as-a-service, data sources or other products or Service not
                        provided by Carrier that are integrated with or otherwise accessible through the Service.
                    </li>
                </ol>
            </LynxTypography>
            <Grid container>
                <Grid item>
                    <LynxTypography variant="h2" className={classes.headingPadding}>
                        2 CARRIER SERVICES
                    </LynxTypography>
                </Grid>
                {renderParagraph(
                    "2.1",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            Access to the Service.&nbsp;
                        </LynxTypography>
                        Customer and its Permitted Users may access and use the Service solely for Customer’s benefit
                        and in accordance with the terms and conditions of the Agreement, these Terms, the Privacy
                        Notice, the Documentation, and any scope of use restrictions designated in the applicable Order
                        Form. Use of and access to the Service is permitted only for Permitted Users. Permitted Users
                        shall keep API keys, user ID and password information strictly confidential and not share such
                        information with any unauthorized person. User IDs are granted to individual, named persons and
                        may not be shared.
                    </LynxTypography>
                )}
                {renderParagraph(
                    "2.2",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            General Restrictions.&nbsp;
                        </LynxTypography>
                        Permitted Users shall not: (a) make the Service available to anyone other than Permitted Users;
                        (b) sell, resell, rent, lease, provide access to, or sublicense the Service to a third party;
                        (c) interfere with or disrupt the integrity or performance of the Service or any content
                        contained therein; (d) attempt to gain unauthorized access to the Service or the underlying
                        systems or networks; (e) use the Service or data derived therefrom to provide, or incorporate
                        the Service into, any unauthorized product or service provided to a third party, or to create a
                        competing offering; (f) reverse engineer, decompile, disassemble, or otherwise seek to obtain
                        the source code or non-public APIs to the Service, except to the extent expressly permitted by
                        applicable law (and then only upon advance notice to Carrier); (g) circumvent any technical
                        limitations in the Service that limit or restrict access to or use of Service or any content,
                        file, or other work, except as expressly permitted by applicable law notwithstanding this
                        limitation; (h) degrade, impede access, copy, or modify the Service or any Documentation, or
                        create any derivative work from any of the foregoing; (i) remove or obscure any proprietary or
                        other notices contained in the Service; (j) publicly disseminate information regarding the
                        performance of the Service; (k) use any Carrier or other third party names or trademarks
                        referenced in the provision of the Service in any manner without Carrier’s written consent; or
                        (l) use the Service in a manner outside of its intended purpose.
                    </LynxTypography>
                )}
            </Grid>
            <Grid container>
                <Grid item>
                    <LynxTypography variant="h2" className={classes.headingPadding}>
                        3 DATA RIGHTS
                    </LynxTypography>
                </Grid>
                {renderParagraph(
                    "3.1",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            Permitted User Data.&nbsp;
                        </LynxTypography>
                        The Service contains features that allow for the collection and submission of information
                        regarding, inter alia, temperature data that allows Carrier and its service providers to deliver
                        the Service back to Permitted Users. Permitted User is responsible for any Permitted User Data,
                        and Permitted User is fully responsible for such content and Permitted User’s use of such
                        content, including without limitation its legality, reliability, accuracy, and appropriateness.
                    </LynxTypography>
                )}
                {renderParagraph(
                    "3.2",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            Rights in Permitted User Data.&nbsp;
                        </LynxTypography>
                        Permitted User will retain all right, title, and interest in and to the Permitted User Data as
                        provided to Carrier, and hereby grants to Carrier and its affiliates a non-exclusive, perpetual,
                        sub-licensable, irrevocable and worldwide, royalty-free right and license to receive, host,
                        store, analyze, process, enhance, copy, transmit, modify, create derivative works of, maintain,
                        display, or otherwise use the Permitted User Data to provide the Service in accordance with this
                        Agreement.
                    </LynxTypography>
                )}
                {renderParagraph(
                    "3.3",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            Aggregated Data.&nbsp;
                        </LynxTypography>
                        Subject to these Terms, Permitted User acknowledges and agrees that Carrier monitors Permitted
                        User’s use of the Service and collects, logs, and aggregates usage statistics and usage data as
                        part of the normal operation of the Service (&quot;
                        <LynxTypography variant="body-sbold" component="span">
                            Aggregated Data
                        </LynxTypography>
                        &quot;). Carrier may use Aggregated Data for any business purpose during or after the term,
                        including but not limited to (i) improving the Service, (ii) producing and sharing aggregated
                        insights from the usage statistics or usage data, or (iii) comparing usage and adoption rates of
                        Customer’s and other organizations as long as the Aggregated Data is anonymized or
                        de-identified. For the avoidance of doubt, Permitted User Data does not include Aggregated Data,
                        and Carrier will not disclose or provide Customer or Permitted User Data or Customer’s or
                        Permitted Users’ identities to third parties, except as provided in the Agreement, these Terms,
                        or the Privacy Policy, and Carrier shall own all right, title and interest in and to Aggregated
                        Data, the usage statistics, and usage data including without limitation all intellectual
                        property rights therein.
                    </LynxTypography>
                )}
            </Grid>
            <Grid container>
                <Grid item>
                    <LynxTypography variant="h2" className={classes.headingPadding}>
                        4 DATA PRIVACY AND SECURITY
                    </LynxTypography>
                </Grid>
                {renderParagraph(
                    "4.1",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            Processing of Personal Data.&nbsp;
                        </LynxTypography>
                        Permitted User acknowledges and agrees that in the execution and the administration of the
                        Service, Personal Data of Permitted User will be collected and processed, and that where
                        Permitted User provides Carrier with any Personal Data for such purpose, Permitted User will
                        ensure that it has the legal right to do so. Where Carrier processes Personal Data in relation
                        to providing the Service, it will do so in accordance with the Privacy Notice.
                    </LynxTypography>
                )}
                {renderParagraph(
                    "4.2",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            No Sensitive Personal Information.&nbsp;
                        </LynxTypography>
                        Permitted User specifically agrees not to use the Service to collect, store, process, or
                        transmit any Sensitive Personal Data. Permitted User acknowledges that Carrier is not a Business
                        Associate or subcontractor (as such terms are defined in HIPAA) or a payment card processor, and
                        that the Service is neither HIPAA- nor PCI DSS-compliant. Carrier will have no liability for
                        Sensitive Personal Data, notwithstanding anything to the contrary herein.
                    </LynxTypography>
                )}
                {renderParagraph(
                    "4.3",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            Security.&nbsp;
                        </LynxTypography>
                        Carrier agrees to use commercially reasonable technical and organizational measures designed to
                        prevent unauthorized access, use, alteration, or disclosure of any Permitted User Data. However,
                        as the Service is to be provided over the internet, Carrier will have no responsibility for
                        errors in transmission, unauthorized third-party access, interruptions due to a failure of
                        third-party communication technologies, or any other causes beyond Carrier’s control.
                    </LynxTypography>
                )}
            </Grid>
            <Grid container>
                <Grid item>
                    <LynxTypography variant="h2" className={classes.headingPadding}>
                        5 PROPRIETARY RIGHTS
                    </LynxTypography>
                </Grid>
                {renderParagraph(
                    "5.1",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            Reservation of Rights.&nbsp;
                        </LynxTypography>
                        Permitted User acknowledges that it is obtaining only a limited and revocable right and license
                        to access and use the Service. The Service is licensed, not sold. As between the parties, the
                        Service (including without limitation, any updates, upgrades, modifications, customizations, and
                        improvements thereto) and all intellectual property rights therein are and will remain the sole
                        property of Carrier and/or Carrier’s affiliates, partners, suppliers, and licensors, and that
                        irrespective of any use of the words “purchase”, “sale” or like terms in any agreement, no
                        ownership rights are being conveyed to Customer and its Permitted Users under these Terms with
                        respect to the Service. Customer’s, and any Permitted User’s, access to and use of the Service
                        do not transfer to Customer, any Permitted User, or any third party any rights, title, or
                        interest in or to such intellectual property rights. Carrier, its affiliates, partners,
                        suppliers, and licensors reserve all rights not expressly granted to Customer or Permitted
                        Users.
                    </LynxTypography>
                )}
                {renderParagraph(
                    "5.2",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            Suggestions.&nbsp;
                        </LynxTypography>
                        Permitted User may choose to, or may be invited to, submit comments, suggestions, or ideas about
                        the Service, including how to improve the Service (
                        <LynxTypography variant="body-sbold" component="span">
                            &quot;Feedback&quot;
                        </LynxTypography>
                        ). Permitted User agrees that Feedback submissions are voluntary, gratuitous, unsolicited, and
                        without restriction and will not place Carrier under any fiduciary or other obligation. Carrier
                        may use, copy, modify, publish, or redistribute Feedback and its contents for any purpose.
                        Carrier shall have a royalty-free, worldwide, transferable, sub-licensable, irrevocable,
                        perpetual right and license to use, copy, modify, publish, or redistribute Feedback and its
                        contents for any purpose, including the right and license to use or incorporate into the Service
                        any suggestions, enhancement requests, recommendations, or other Feedback provided by Customer
                        and its Permitted Users relating to the Service. Permitted User waives all moral rights it may
                        have in the Feedback. Permitted User also agrees that Carrier does not waive any rights to use
                        similar or related ideas previously known to Carrier, developed by its employees, or obtained
                        from other sources.
                    </LynxTypography>
                )}
            </Grid>
            <LynxTypography variant="h2" className={classes.headingPadding}>
                6 TERM AND TERMINATION
            </LynxTypography>
            <LynxTypography>
                Permitted User shall only have access to the Service while Customer’s Agreement and/or Order Form
                remains valid. Carrier reserves the right to terminate Permitted Users’ access to the Service any time
                Carrier reasonably believes that Permitted User is not acting in good faith or in compliance with the
                terms stipulated herein.
            </LynxTypography>
            <Grid container>
                <Grid item>
                    <LynxTypography variant="h2" className={classes.headingPadding}>
                        7 WARRANTY DISCLAIMER
                    </LynxTypography>
                </Grid>
                {renderParagraph(
                    "7.1",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            Warranty Disclaimer.&nbsp;
                        </LynxTypography>
                        CARRIER MAKES NO WARRANTIES TO OR FOR PERMITTED USERS. ALL SERVICE, SUPPORT, AND PROFESSIONAL
                        SERVICES ARE PROVIDED “AS IS”. NEITHER CARRIER NOR ITS SUPPLIERS MAKES ANY WARRANTIES HEREIN,
                        EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO WARRANTIES OF
                        MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. CARRIER DOES NOT
                        WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, NOR DOES CARRIER WARRANT THAT IT
                        WILL REVIEW THE PERMITTED USER DATA FOR ACCURACY OR THAT IT WILL MAINTAIN THE PERMITTED USER
                        DATA WITHOUT LOSS OR CORRUPTION. CARRIER SHALL NOT BE LIABLE FOR DELAYS, INTERRUPTIONS, SERVICE
                        FAILURES, OR OTHER PROBLEMS INHERENT IN USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS,
                        THIRD-PARTY PLATFORMS, OR OTHER SYSTEMS OUTSIDE THE REASONABLE CONTROL OF CARRIER.
                    </LynxTypography>
                )}
                {renderParagraph(
                    "7.2",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            Exclusions.&nbsp;
                        </LynxTypography>
                        The information provided pursuant to the Service should not be considered medical, legal,
                        insurance, or any other professional advice. In all cases, Customer and/or Permitted User should
                        consult with professional advisors familiar with their particular factual situation before
                        making any decisions. In no event will Carrier in any way whatsoever be liable to Customer,
                        Permitted User, or anyone else, for Permitted User’s use of the information provided pursuant to
                        the Service, or for any decision(s) made or action(s) taken in reliance upon the information
                        provided pursuant to the Service.
                    </LynxTypography>
                )}
            </Grid>
            <Grid container>
                <Grid item>
                    <LynxTypography variant="h2" className={classes.headingPadding}>
                        8 LIMITATION OF LIABILITY AND DAMAGES
                    </LynxTypography>
                </Grid>
                {renderParagraph(
                    "8.1",
                    <LynxTypography variant="body-sbold">
                        Damages Waiver. UNDER NO CIRCUMSTANCES SHALL CARRIER OR ITS SUPPLIERS BE LIABLE FOR ANY
                        INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, INCLUDING LOSS OF REVENUE, LOSS OF USE
                        OF EQUIPMENT OR FACILITIES, LOSS OF DATA, FAILURE OF SECURITY MECHANISM, INTERRUPTION OF
                        BUSINESS, OR ECONOMIC DAMAGES HOWSOEVER ARISING.
                    </LynxTypography>
                )}
                {renderParagraph(
                    "8.2",
                    <LynxTypography variant="body-sbold">
                        Liability Cap. TO THE EXTENT PERMITTED BY LAW, CARRIER’S AGGREGATE LIABILITY TO CUSTOMER AND ITS
                        PERMITTED USERS FOR ANY REASON, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), BREACH OF
                        STATUTORY DUTY, OR OTHERWISE, WILL BE LIMITED TO THE TOTAL AMOUNT PAID AND PAYABLE BY CUSTOMER
                        TO CARRIER FOR THE SERVICE IN THE 12-MONTH PERIOD PRECEDING THE COMMENCEMENT OF THE EVENT OR
                        EVENTS.
                    </LynxTypography>
                )}
            </Grid>
            <Grid container>
                <Grid item>
                    <LynxTypography variant="h2" className={classes.headingPadding}>
                        9 GENERAL PROVISIONS
                    </LynxTypography>
                </Grid>
                {renderParagraph(
                    "9.1",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            Modifications.&nbsp;
                        </LynxTypography>
                        Permitted User acknowledges and agrees that Carrier may modify these Terms at any time, and
                        continued use of the Service after Carrier releases a change to the Terms shall constitute
                        acceptance and agreement to such modified Terms. These Terms apply to any updates to the Service
                        as well as any applications (if any) used to access the Service that Carrier may, in its sole
                        discretion, provide or make available (
                        <LynxTypography variant="body-sbold" component="span">
                            &quot;Update&quot;
                        </LynxTypography>
                        ). From time to time, Carrier may, without additional notification, automatically “push” or
                        deliver an over-the-air Update to Customer’s and/or Permitted Users’ mobile phone, tablet,
                        laptop, desktop, or other computing device via a cellular or internet connection (
                        <LynxTypography variant="body-sbold" component="span">
                            &quot;Automatic Update&quot;
                        </LynxTypography>
                        ). Permitted User acknowledges and agrees that Automatic Updates may occur at Carrier’s sole
                        discretion, that Permitted User’s continued use of the Service may be conditioned upon its
                        acceptance of Automatic Updates, and that Permitted User consents to Automatic Updates by
                        acceptance of these Terms. If Carrier provides additional terms along with an Update, those
                        terms will apply to the Update. If Carrier provides an Update, Carrier may, at its sole
                        discretion, require Permitted User to use the Update version and cease use of earlier versions.
                        Carrier reserves the right to update, modify, or discontinue any product or service made
                        available to Permitted User through use of the Service.
                    </LynxTypography>
                )}
                {renderParagraph(
                    "9.2",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            Governing Law and Forum.&nbsp;
                        </LynxTypography>
                        These Terms shall be interpreted in accordance with the plain English meaning of its terms and
                        the construction thereof shall be governed by the laws in force in the State of New York, USA
                        without regard to conflicts of law principles, except that Sections 5-1401 and 5-1402 of the New
                        York General Obligations law will apply and except that the United Nations Convention on
                        Contracts for the International Sale of Goods dated April 11, 1980, as amended to date, will not
                        apply. The state and federal courts located in New York City, NY shall have exclusive
                        jurisdiction to adjudicate any dispute.
                    </LynxTypography>
                )}
                {renderParagraph(
                    "9.3",
                    <LynxTypography>
                        <LynxTypography variant="body-sbold" component="span">
                            Severability.&nbsp;
                        </LynxTypography>
                        If any provision of these Terms is held by a court of competent jurisdiction to be contrary to
                        law, the provision shall be modified by the court and interpreted so as best to accomplish the
                        objectives of the original provision to the fullest extent permitted by law, and the remaining
                        provisions of these Terms and Conditions shall remain in effect.
                    </LynxTypography>
                )}
            </Grid>
        </Grid>
    );
};
