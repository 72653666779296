import * as eventApiModels from "api/models/events/eventsApi";
import * as stabilityFormApiModels from "api/models/stabilityForms/stabilityFormsApi";
import { jsonParseFormikRange, stringifyFormikRange } from "helpers/rangeHelpers";
import { commonConstants } from "lynxConstants";
import { LowerOperator, UpperOperator } from "models/productAndStabilityForm/productAndStabilityFormModels";
import * as eventModels from "models/thorEvents/eventModels";
import {
    formikModels,
    parseFormikArray,
    parseFormikBoolean,
    parseFormikNumber,
    parseFormikString,
    toFormikArray,
    toFormikBoolean,
    toFormikString,
} from "validation";
import { dateToFormat } from "./../helpers/dateFormattingHelper";

export const getLowerOperator = (lowerLimit: string | number | null, lowerInclusive: boolean): LowerOperator => {
    const parsed = parseFormikNumber(lowerLimit?.toString());

    if (parsed === null) {
        return LowerOperator.INFINITY;
    }

    return lowerInclusive ? LowerOperator.GREATER_THAN_OR_EQUAL : LowerOperator.GREATER_THAN;
};

export const getUpperOperator = (upperLimit: string | number | null, upperInclusive: boolean): UpperOperator => {
    const parsed = parseFormikNumber(upperLimit?.toString());

    if (parsed === null) {
        return UpperOperator.INFINITY;
    }

    return upperInclusive ? UpperOperator.LESS_THAN_OR_EQUAL : UpperOperator.LESS_THAN;
};

export const convertToRangeFormikModel = (value: eventModels.RangeModel): formikModels.RangeFormikModel => ({
    lowerLimit: toFormikString(value.lowerLimit),
    upperLimit: toFormikString(value.upperLimit),
    lowerInclusive: value.lowerInclusive,
    upperInclusive: value.upperInclusive,
});

export const convertToRangeFormikInputModel = (value: eventModels.RangeModel): formikModels.RangeFormikInputModel => {
    const lowerOperator =
        value.lowerLimit === null && value.upperLimit === null
            ? LowerOperator.GREATER_THAN_OR_EQUAL
            : getLowerOperator(value.lowerLimit, value.lowerInclusive);
    const upperOperator =
        value.lowerLimit === null && value.upperLimit === null
            ? UpperOperator.LESS_THAN_OR_EQUAL
            : getUpperOperator(value.upperLimit, value.upperInclusive);

    return {
        lowerLimit: toFormikString(value.lowerLimit),
        upperLimit: toFormikString(value.upperLimit),
        lowerInclusive: value.lowerInclusive,
        upperInclusive: value.upperInclusive,
        lowerOperator: lowerOperator,
        upperOperator: upperOperator,
    };
};

export const convertFromRangeFormikModel = (value: formikModels.RangeFormikModel): eventModels.RangeModel => ({
    lowerLimit: parseFormikNumber(value.lowerLimit),
    upperLimit: parseFormikNumber(value.upperLimit),
    lowerInclusive: value.lowerInclusive,
    upperInclusive: value.upperInclusive,
});

export const convertToRsbAdjustmentFormikModel = (
    value: eventApiModels.RsbAdjustmentForRangeDetails | eventApiModels.RsbAdjustmentModelToUpdate
): formikModels.RsbAdjustmentFormikModel => {
    const obj = {
        rangeStringified: stringifyFormikRange(value),
        rsbAdjustment: toFormikString(Math.abs(value.rsbAdjustment)),
        metadata: {
            rsbAfterAdjustment: value.rsbAfterAdjustment,
            rsbBeforeAdjustment: value.rsbBeforeAdjustment,
            lowerLimit: value.lowerLimit,
            upperLimit: value.upperLimit,
            lowerInclusive: value.lowerInclusive,
            upperInclusive: value.upperInclusive,
        },
    };

    if ("rsbAdjustmentAction" in value) {
        return {
            ...obj,
            rsbAdjustmentAction: value.rsbAdjustmentAction,
        };
    }

    return {
        ...obj,
        rsbAdjustmentAction:
            value.rsbAdjustment > 0 ? eventModels.RsbAdjustmentAction.Add : eventModels.RsbAdjustmentAction.Reduce,
    };
};

export const convertToBatchFormikModel = (value: eventApiModels.BatchDetailsModel): formikModels.BatchFormikModel => ({
    id: value.id,
    batchNumber: value.batchNumber,
    quantity: "",
    stabilityFormId: toFormikString(value.stabilityFormId),
    productName: toFormikString(value.productName),
    doseFormId: toFormikString(value.doseFormId),
    doseFormName: toFormikString(value.doseFormName),
    unitOfMeasureId: toFormikString(value.unitOfMeasureId),
    unitOfMeasureName: toFormikString(value.unitOfMeasureName),
    dosage: toFormikString(value.dosage),
    expirationDate: toFormikString(value.expirationDate),
    rsbAdjustments: [],
    manualExcursionsPerRange: [],
    metadata: {
        stabilityFormFullName: value.stabilityForm?.stabilityFormFullName ?? null,
        eventBatchId: "",
        isEditable: value.isEditable,
        showExclamationIcon: value.showExclamationIcon,
        impactsWithStabilityRangeCount: 0,
        reactKey: crypto.randomUUID(),
        representations: value.stabilityForm?.representations ?? [],
    },
});

export const convertToDeliveryFormikModel = (
    value: eventApiModels.DeliveryModelToUpdate | eventModels.DeliveryInformation | null
): formikModels.DeliveryFormikModel => {
    if (value !== null) {
        if ("origin" in value) {
            return {
                id: toFormikString(value.id),
                deliveryNumber: toFormikString(value.deliveryNumber),
                orderNumbers: toFormikArray(value.orderNumbers),
                laneNumber: toFormikString(value.laneNumber),
                shipperType: toFormikString(value.shipperType),
                transportationModeId: toFormikString(value.transportationModeId),
                transportationServiceProviderName: toFormikString(value.transportationServiceProviderName),
                logisticsServiceProviderName: toFormikString(value.logisticsServiceProviderName),
                ...convertToRangeFormikInputModel(value),
                originId: toFormikString(value.origin?.id),
                destinationId: toFormikString(value.destination?.id),
                metadata: {
                    originCode: toFormikString(value.origin?.code),
                    originName: toFormikString(value.origin?.name),
                    destinationCode: toFormikString(value.destination?.code),
                    destinationName: toFormikString(value.destination?.name),
                },
            };
        }
    }

    const lowerOperator =
        value?.lowerLimit == null && value?.upperLimit == null
            ? LowerOperator.GREATER_THAN_OR_EQUAL
            : getLowerOperator(value?.lowerLimit ?? null, value?.lowerInclusive ?? true);
    const upperOperator =
        value?.lowerLimit == null && value?.upperLimit == null
            ? UpperOperator.LESS_THAN_OR_EQUAL
            : getUpperOperator(value?.upperLimit ?? null, value?.upperInclusive ?? true);

    return {
        id: toFormikString(value?.id),
        deliveryNumber: toFormikString(value?.deliveryNumber),
        orderNumbers: toFormikArray(value?.orderNumbers),
        laneNumber: toFormikString(value?.laneNumber),
        shipperType: toFormikString(value?.shipperType),
        transportationModeId: toFormikString(value?.transportationModeId),
        transportationServiceProviderName: toFormikString(value?.transportationServiceProviderName),
        logisticsServiceProviderName: toFormikString(value?.logisticsServiceProviderName),
        lowerLimit: toFormikString(value?.lowerLimit),
        lowerInclusive: value?.lowerInclusive ?? true,
        upperLimit: toFormikString(value?.upperLimit),
        upperInclusive: value?.upperInclusive ?? true,
        lowerOperator: lowerOperator,
        upperOperator: upperOperator,
        originId: toFormikString(value?.originId),
        destinationId: toFormikString(value?.destinationId),
        metadata: {
            originCode: toFormikString(value?.originCode),
            originName: toFormikString(value?.originName),
            destinationCode: toFormikString(value?.destinationCode),
            destinationName: toFormikString(value?.destinationName),
        },
    };
};

export const getBlankEventFormikModel = (): formikModels.EventFormikModel => ({
    type: eventModels.EventType.Transportation,
    excursionSource: eventModels.ExcursionSource.TemperatureRecordingDevice,
    quarantineDate: "",
    deliveryInformation: {
        id: "",
        deliveryNumber: "",
        orderNumbers: [],
        laneNumber: "",
        shipperType: "",
        transportationModeId: "",
        transportationServiceProviderName: "",
        logisticsServiceProviderName: "",
        lowerLimit: "",
        lowerInclusive: true,
        upperLimit: "",
        upperInclusive: true,
        lowerOperator: LowerOperator.GREATER_THAN_OR_EQUAL,
        upperOperator: UpperOperator.LESS_THAN_OR_EQUAL,
        originId: "",
        destinationId: "",
        metadata: {
            originCode: "",
            originName: "",
            destinationCode: "",
            destinationName: "",
        },
    },
    batches: [],
    site: {
        id: "",
        metadata: {
            code: "",
            name: "",
        },
    },
    devices: [],
    manualExcursion: {
        lowTemperature: "",
        highTemperature: "",
        startDateTime: "",
        highExcursion: "",
        lowExcursion: "",
        numberOfSpikes: "",
        deviceIds: "",
    },
    eventAttachments: [],
    comment: "",
    metadata: {
        id: "",
        displayId: 0,
        processType: eventModels.EventProcessType.Commercial,
        timezone: commonConstants.UTCTimezone,
    },
});

export const convertToEventFormikModel = (value: eventApiModels.EventModelToUpdate): formikModels.EventFormikModel => ({
    type: value.type,
    quarantineDate: toFormikString(value.quarantineDate),
    excursionSource: value.excursionSource,
    deliveryInformation: convertToDeliveryFormikModel(value.deliveryInformation),
    batches: value.batches.map<formikModels.BatchFormikModel>((x) => ({
        id: x.id,
        batchNumber: x.batchNumber,
        stabilityFormId: toFormikString(x.stabilityFormId),
        productName: toFormikString(x.productName),
        doseFormId: toFormikString(x.doseFormId),
        dosage: toFormikString(x.dosage),
        doseFormName: toFormikString(x.doseFormName),
        unitOfMeasureName: toFormikString(x.unitOfMeasureName),
        unitOfMeasureId: toFormikString(x.unitOfMeasureId),
        expirationDate: toFormikString(x.expirationDate),
        eventBatchId: x.eventBatchId,
        quantity: toFormikString(x.quantity),
        rsbAdjustments: x.rsbAdjustments.map<formikModels.RsbAdjustmentFormikModel>((x) =>
            convertToRsbAdjustmentFormikModel(x)
        ),
        manualExcursionsPerRange: x.manualExcursionsPerRange.map<formikModels.ManualExcursionPerRangeFormikModel>(
            (y) => ({
                rangeStringified: stringifyFormikRange(y),
                duration: toFormikString(y.duration),
                metadata: { ...y },
            })
        ),
        metadata: {
            eventBatchId: x.eventBatchId,
            stabilityFormFullName: x.stabilityFormFullName,
            isEditable: x.isEditable,
            showExclamationIcon: !x.stabilityFormId,
            impactsWithStabilityRangeCount: x.impactsWithStabilityRangeCount,
            reactKey: crypto.randomUUID(),
            representations: x.representations,
        },
    })),
    site: {
        id: toFormikString(value.site?.id),
        metadata: {
            code: toFormikString(value.site?.code),
            name: toFormikString(value.site?.name),
        },
    },
    devices: value.devices.map<formikModels.DeviceFormikModel>((x) => ({
        id: x.id,
        metadata: { serialNumber: x.serialNumber },
    })),
    manualExcursion: {
        lowTemperature: toFormikString(value.manualExcursion?.lowTemperature),
        highTemperature: toFormikString(value.manualExcursion?.highTemperature),
        startDateTime: toFormikString(value.manualExcursion?.startDateTime),
        lowExcursion: toFormikString(value.manualExcursion?.lowExcursion),
        highExcursion: toFormikString(value.manualExcursion?.highExcursion),
        deviceIds: toFormikString(value.manualExcursion?.deviceIds),
        numberOfSpikes: toFormikString(value.manualExcursion?.numberOfSpikes),
    },
    eventAttachments: value.eventAttachments.map<eventApiModels.FullNameAttachmentModel>((x) => ({ ...x })),
    comment: "",
    metadata: {
        id: value.id,
        displayId: value.displayId,
        processType: value.processType,
        timezone: value.timezone,
    },
});

export const convertToEventInputModel = (value: formikModels.EventFormikModel): eventApiModels.EventInputModel => ({
    type: value.type,
    excursionSource: value.excursionSource,
    quarantineDate:
        parseFormikString(value.quarantineDate) === null
            ? null
            : dateToFormat(parseFormikString(value.quarantineDate), commonConstants.shortDateFormat),
    batches: value.batches.map((x) => ({
        id: parseFormikString(x.id),
        batchNumber: x.batchNumber,
        stabilityFormId: x.stabilityFormId,
        dosage: parseFormikNumber(x.dosage)!,
        doseFormId: x.doseFormId,
        unitOfMeasureId: x.unitOfMeasureId,
        productName: parseFormikString(x.productName)!,
        quantity: parseFormikNumber(x.quantity)!,
        expirationDate:
            parseFormikString(x.expirationDate) === null
                ? null
                : dateToFormat(parseFormikString(x.expirationDate), commonConstants.shortDateFormat),
        rsbAdjustments: x.rsbAdjustments.map<eventApiModels.RsbAdjustmentInputModel>((y) => ({
            ...convertFromRangeFormikModel(jsonParseFormikRange(y.rangeStringified)),
            rsbAdjustment:
                y.rsbAdjustmentAction === eventModels.RsbAdjustmentAction.Add
                    ? parseFormikNumber(y.rsbAdjustment)!
                    : -parseFormikNumber(y.rsbAdjustment)!,
        })),
        manualExcursionsPerRange:
            value.excursionSource === eventModels.ExcursionSource.Manual
                ? x.manualExcursionsPerRange.map<eventApiModels.ManualExcursionPerRangeInputModel>((x) => ({
                      ...convertFromRangeFormikModel(jsonParseFormikRange(x.rangeStringified)),
                      duration: parseFormikNumber(x.duration)!,
                  }))
                : [],
    })),
    deliveryInformation:
        value.type === eventModels.EventType.Transportation
            ? {
                  deliveryNumber: parseFormikString(value.deliveryInformation.deliveryNumber)!,
                  orderNumbers: value.deliveryInformation.orderNumbers,
                  laneNumber: parseFormikString(value.deliveryInformation.laneNumber),
                  shipperType: parseFormikString(value.deliveryInformation.shipperType),
                  transportationModeId: parseFormikString(value.deliveryInformation.transportationModeId),
                  transportationServiceProvider: parseFormikString(
                      value.deliveryInformation.transportationServiceProviderName
                  ),
                  logisticsServiceProvider: parseFormikString(value.deliveryInformation.logisticsServiceProviderName),
                  lowerLimit: parseFormikNumber(value.deliveryInformation.lowerLimit),
                  lowerInclusive: value.deliveryInformation.lowerInclusive,
                  upperLimit: parseFormikNumber(value.deliveryInformation.upperLimit),
                  upperInclusive: value.deliveryInformation.upperInclusive,
                  originId: value.deliveryInformation.originId,
                  destinationId: value.deliveryInformation.destinationId,
              }
            : null,
    manualExcursion:
        value.excursionSource === eventModels.ExcursionSource.Manual
            ? {
                  lowTemperature: parseFormikNumber(value.manualExcursion.lowTemperature),
                  highTemperature: parseFormikNumber(value.manualExcursion.highTemperature),
                  startDateTime: parseFormikString(value.manualExcursion.startDateTime)!,
                  lowExcursion: parseFormikNumber(value.manualExcursion.lowExcursion)!,
                  highExcursion: parseFormikNumber(value.manualExcursion.highExcursion)!,
                  deviceIds: parseFormikString(value.manualExcursion.deviceIds),
                  numberOfSpikes: parseFormikNumber(value.manualExcursion.numberOfSpikes),
              }
            : null,
    deviceIds:
        value.excursionSource === eventModels.ExcursionSource.TemperatureRecordingDevice
            ? value.devices.map((x) => x.id)
            : [],
    siteId: value.type === eventModels.EventType.Site ? value.site.id : null,
    comment: parseFormikString(value.comment),
    eventAttachments: value.eventAttachments.map((x) => ({ ...x })),
});

// Stability Form
export const getBlankStabilityFormFormikModel = (): formikModels.StabilityFormFormikModel => ({
    number: "",
    additionalInfo: "",
    productBusinessKey: "",
    productName: "",
    genericProductName: "",
    lowerLimit: "",
    lowerInclusive: true,
    lowerOperator: LowerOperator.GREATER_THAN_OR_EQUAL,
    upperLimit: "",
    upperInclusive: true,
    upperOperator: UpperOperator.LESS_THAN_OR_EQUAL,
    SKUs: [],
    storageTypeId: "",
    presentationId: "",
    categoryId: "",
    expiryPeriodInHours: "",
    productSpec: "",
    freezeCycleLimit: "",
    representations: [
        {
            doseFormId: "",
            allDosagesFlag: eventModels.TrueFalseType.False,
            dosage: "",
            unitOfMeasureId: "",
            metadata: {
                id: "",
                key: crypto.randomUUID(),
            },
        },
    ],
    temperatureRanges: [
        {
            lowerLimit: "",
            lowerInclusive: true,
            lowerOperator: LowerOperator.GREATER_THAN_OR_EQUAL,
            upperLimit: "",
            upperInclusive: true,
            upperOperator: UpperOperator.LESS_THAN_OR_EQUAL,
            references: "",
            totalStabilityBudget: "",
            remainingStabilityBudget: "",
            medicalInfo: "",
            withUnlimitedBudget: false,
            rangeRegions: [],
            metadata: {
                id: "",
                key: crypto.randomUUID(),
                isReferenced: false,
            },
        },
    ],
});

export const convertToStabilityFormFormikModel = (
    value: stabilityFormApiModels.StabilityFormApiModelToUpdate
): formikModels.StabilityFormFormikModel => ({
    number: value.number,
    additionalInfo: toFormikString(value.additionalInfo),
    productBusinessKey: value.productBusinessKey,
    productName: value.productName,
    genericProductName: toFormikString(value.genericProductName),
    SKUs: toFormikArray(value.SKUs),
    storageTypeId: value.storageTypeId,
    presentationId: toFormikString(value.presentationId),
    categoryId: toFormikString(value.categoryId),
    expiryPeriodInHours: toFormikString(value.expiryPeriodInHours),
    productSpec: toFormikString(value.productSpec),
    freezeCycleLimit: toFormikString(value.freezeCycleLimit),
    ...convertToRangeFormikInputModel(value),
    representations: value.representations.map<formikModels.ProductRepresentationFormikModel>((x) => ({
        dosage: toFormikString(x.dosage),
        unitOfMeasureId: toFormikString(x.unitOfMeasureId),
        doseFormId: x.doseFormId,
        allDosagesFlag: toFormikBoolean(x.allDosagesFlag),
        metadata: {
            id: x.id,
            key: crypto.randomUUID(),
        },
    })),
    temperatureRanges: value.temperatureRanges.map<formikModels.TemperatureRangeFormikModel>((x) => ({
        ...convertToRangeFormikInputModel(x),
        references: toFormikString(x.references),
        remainingStabilityBudget: toFormikString(x.remainingStabilityBudget),
        totalStabilityBudget: toFormikString(x.totalStabilityBudget),
        medicalInfo: toFormikString(x.medicalInfo),
        withUnlimitedBudget: x.withUnlimitedBudget,
        rangeRegions: x.rangeRegions.map<formikModels.TemperatureRangeRegionFormikModel>((y) => ({
            regionId: y.regionId ?? commonConstants.allCountriesAndRegions,
            medicalInfo: toFormikString(y.medicalInfo),
            flows: y.flows.map<formikModels.FlowFormikModel>((q) => ({
                id: q.id,
                name: toFormikString(q.name),
                remainingStabilityBudget: toFormikString(q.remainingStabilityBudget),
                plannedDeduction: toFormikString(q.plannedDeduction),
                references: toFormikString(q.references),
                steps: toFormikString(q.steps),
                metadata: {
                    id: q.id,
                    isReferenced: q.isReferenced,
                    key: crypto.randomUUID(),
                },
            })),
            metadata: {
                id: y.id,
                key: crypto.randomUUID(),
            },
        })),
        metadata: {
            id: x.id,
            isReferenced: x.isReferenced,
            key: crypto.randomUUID(),
        },
    })),
});

export const convertToStabilityFormInputModel = (
    value: formikModels.StabilityFormFormikModel
): stabilityFormApiModels.StabilityFormApiInputModel => ({
    number: value.number.trim(),
    additionalInfo: parseFormikString(value.additionalInfo),
    productBusinessKey: value.productBusinessKey.trim(),
    productName: value.productName.trim(),
    genericProductName: parseFormikString(value.genericProductName),
    lowerLimit: parseFormikNumber(value.lowerLimit),
    lowerInclusive: value.lowerInclusive,
    upperLimit: parseFormikNumber(value.upperLimit),
    upperInclusive: value.upperInclusive,
    SKUs: parseFormikArray(value.SKUs),
    storageTypeId: value.storageTypeId,
    presentationId: parseFormikString(value.presentationId),
    categoryId: parseFormikString(value.categoryId),
    expiryPeriodInHours: parseFormikNumber(value.expiryPeriodInHours),
    productSpec: parseFormikString(value.productSpec),
    freezeCycleLimit: parseFormikNumber(value.freezeCycleLimit),
    representations: value.representations.map<stabilityFormApiModels.ProductRepresentationApiInputModel>((x) => ({
        id: parseFormikString(x.metadata.id),
        doseFormId: x.doseFormId,
        allDosagesFlag: parseFormikBoolean(x.allDosagesFlag)!,
        dosage: parseFormikNumber(x.dosage),
        unitOfMeasureId: parseFormikString(x.unitOfMeasureId),
    })),
    temperatureRanges: value.temperatureRanges.map<stabilityFormApiModels.TemperatureRangeApiInputModel>((x) => ({
        id: parseFormikString(x.metadata.id),
        lowerLimit: parseFormikNumber(x.lowerLimit),
        lowerInclusive: x.lowerInclusive,
        upperLimit: parseFormikNumber(x.upperLimit),
        upperInclusive: x.upperInclusive,
        medicalInfo: parseFormikNumber(x.medicalInfo),
        totalStabilityBudget: parseFormikNumber(x.totalStabilityBudget),
        remainingStabilityBudget: parseFormikNumber(x.remainingStabilityBudget),
        references: parseFormikString(x.references),
        withUnlimitedBudget: parseFormikBoolean(x.withUnlimitedBudget)!,
        rangeRegions: x.rangeRegions.map<stabilityFormApiModels.TemperatureRangeRegionApiInputModel>((y) => ({
            id: parseFormikString(y.metadata.id),
            regionId: y.regionId === commonConstants.allCountriesAndRegions ? null : parseFormikString(y.regionId),
            medicalInfo: parseFormikNumber(y.medicalInfo),
            flows: y.flows.map<stabilityFormApiModels.FlowApiInputModel>((q) => ({
                id: parseFormikString(q.metadata.id),
                name: parseFormikString(q.name),
                steps: parseFormikString(q.steps),
                references: parseFormikString(q.references),
                plannedDeduction: parseFormikNumber(q.plannedDeduction),
                remainingStabilityBudget: parseFormikNumber(q.remainingStabilityBudget)!,
            })),
        })),
    })),
});
