import { Immutable } from "helpers/typeHelpers";

const promptTooltipMessagesInternal = {
    event: {
        assignToYourself: "First assign the Event to yourself",
        reviewCompleted: "Review completed, no edits can be made",
        decisionCompleted: "Decision completed, no edits can be made",
        productWithCustomer: "First select whether the product is with customer or not",
    },
    stabilityForm: {
        flowCannotBeDeletedBecauseReferenced: "The flow cannot be removed as it is being used in events",
        rangeCannotBeDeletedBecauseReferenced: "The range cannot be removed as it is being used in events",
        assignToYourself: "First assign the Stability Form to yourself",
        newVersionAlreadyCreated: "New version of this form already exists",
        formIsDeactivated: "This form is deactivated. You can create version only from Effective forms",
        flowsNotAllowedForUnlimitedRange: "Flows can't be added for range with unlimited stability budget",
    },
    empty: "",
    accessDenied: "You do not have permission, please contact your administrator",
};

export const promptTooltipMessages: Immutable<typeof promptTooltipMessagesInternal> = promptTooltipMessagesInternal;
