export interface ProductSupportInfo {
    categories: ProductSupportInfoBase[];
    doseForms: ProductSupportInfoBase[];
    storageTypes: ProductStorageType[];
    presentations: ProductSupportInfoBase[];
    unitOfMeasures: ProductSupportInfoBase[];
}
export interface ProductSupportInfoBase {
    id: string;
    name: string;
}

export interface ProductStorageType extends ProductSupportInfoBase {
    lowerLimit: number | null;
    upperLimit: number | null;
}

export interface StabilityFormChangesHistory {
    id: string;
    content: string;
    editorEmail: string;
    editorFirstName: string;
    editorLastName: string;
    editedDateTime: Date;
}
export interface StabilityFormListItem {
    id: string;
    productName: string;
    number: string;
    effectiveDate: Date;
    ownerEmail: string | null;
    ownerFirstName: string | null;
    ownerLastName: string | null;
    approverEmail: string | null;
    approverFirstName: string | null;
    approverLastName: string | null;
    representations: ProductRepresentationListItem[];
    additionalInfo: string | null;
    status: StabilityFormStatus;
    version: number;
}
export interface ProductRepresentationListItem {
    id: string;
    doseFormName: string;
    dosage?: number;
    unitOfMeasureName: string;
    allDosagesFlag: boolean;
}

export enum StabilityFormStatus {
    Draft = 0,
    PendingApproval = 1,
    Effective = 2,
    Deactivated = 3,
}

export interface Region {
    id: string;
    name: string;
}

export enum LowerOperator {
    GREATER_THAN = "GreaterThan",
    GREATER_THAN_OR_EQUAL = "GreaterThanOrEqual",
    INFINITY = "Infinity",
}
export enum UpperOperator {
    LESS_THAN = "LessThan",
    LESS_THAN_OR_EQUAL = "LessThanOrEqual",
    INFINITY = "Infinity",
}
